import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'seniorities';

export const senioritiesApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeniorities: builder.query({
            query: () => BASE_ENDPOINT,
            transformResponse: response => response.seniorities,
        }),
        getSeniorityTitles: builder.query({
            query: () => `seniority-titles`,
            transformResponse: response => response.data,
            providesTags: ['SeniorityTitles', { type: 'SeniorityTitles', id: 'LIST' }],
        }),
        getSeniorityTitleById: builder.query({
            query: (id) => `seniority-titles/${id}`,
            transformResponse: response => response.data,
            providesTags: ['SeniorityTitles'],
        }),
        createSeniorityTitles: builder.mutation({
            query: (body = {}) => ({
                url: `seniority-titles`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['SeniorityTitles'],
        }),
        updateSeniorityTitleById: builder.mutation({
            query: ({id, body = {}}) => ({
                url: `seniority-titles/${id}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['SeniorityTitles'],
        }),
        deleteSeniorityTitleById: builder.mutation({
            query: (id) => ({
                url: `seniority-titles/${id}`,
                method: 'DELETE',
                headers: { 'Accept': 'application/json' },
            }),
            invalidatesTags: [{ type: 'SeniorityTitles', id: 'LIST' }],
        }),
    }),
});

export const {
    useGetSenioritiesQuery,
    useLazyGetSenioritiesQuery,
    useGetSeniorityTitlesQuery,
    useGetSeniorityTitleByIdQuery,
    useCreateSeniorityTitlesMutation,
    useUpdateSeniorityTitleByIdMutation,
    useDeleteSeniorityTitleByIdMutation,
} = senioritiesApi;