import { permissions } from 'constants/permissions';
import store from 'store/index';

const state = store.getState().auth;
const checkPermission = (name) => {    
    const nameOfPermission = permissions[name];
    const storedPermissions = state.permissions ?? {};
    return !!storedPermissions[nameOfPermission];
}

export default checkPermission;