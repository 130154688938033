import React from 'react';
import { useOutletContext } from 'react-router-dom';
import UsersList from 'Components/UsersList/UsersList';

const LegalEntityUsers = () => {
    const { usersProps = {} } = useOutletContext();
    const { users = [], isLoading } = usersProps;
    const componentProps = { users, isLoading, notFoundText: 'No users found for this legal entity.' };

    return (
        <UsersList {...componentProps} />
    );
};

export default LegalEntityUsers;