import { startOfWeek, addDays, } from 'date-fns';
import { isEqual } from "lodash";

// this helper will work only with an array of objects
// it receives a variable named array, which will be the array of objects to iterate,
// a variable named name, wich will be the name to handle in change,
// a variable named labelName, wich will be the object attribute to rename as label,
// and a variable named valueName, which will be the object attribute to rename as value,
// by default labelName is set to name and valueName is set to id
// also receives extra data for data to be necessary for control
const createSelectOptions = (array = [], name = '', labelName = 'name', valueName = 'id', aditionalData = []) => {
    return array.map(element => {
        const { [labelName]: label, [valueName]: value, } = element;
        const data = { label, value, name, };
        aditionalData.forEach(key => data[key] = element[key]);
        return data;
    });
}

// this helper will work only with an array of objects for visibleItems
// every object must have id as attirubute, and selectedItems must be an array of numbers
export const isAllVisibleItemsSelected = (visibleItems = [], selectedItems = []) => {
    return visibleItems.every(({ id }) => selectedItems.includes(id));
}

// this helper will work only with an array of numbers, it will return a bool value
export const isItemSelected = (selectedItems = [], item) => {
    return selectedItems.includes(item);
}

// This helper adds or removes an item (id) from an array of numbers (selectedItems)
export const addOrRemoveItem = (selectedItems = [], item, isSelected) => {
    let newSelectedItems = [...selectedItems];
    if (!isSelected) {
        newSelectedItems.push(item);
    } else {
        newSelectedItems = newSelectedItems.filter(id => id !== item);
    }

    return newSelectedItems;
}

// this helper will work with an array of numbers (selectedItems) and an
// array of objects that must include id as attribute (visibleItems)
export const addOrRemoveVisibleItems = (selectedItems = [], visibleItems = [], checked) => {
    let newSelectedItems = [];

    if (!checked) {
        newSelectedItems = selectedItems.reduce((result, current) => {
            const found = visibleItems.find(({ id }) => id === current);
            if (!found) result.push(current);
            return result;
        }, []);
    } else {
        visibleItems.forEach(({ id }) => {
            if (selectedItems.includes(id)) return;
            newSelectedItems.push(id);
        });
        newSelectedItems.unshift(...selectedItems);
    }

    return newSelectedItems;
}

// this helper will work with an array of numbers (selectedItems) and an
// array of objects
export const addOrRemoveVisibleItemsForArray = (selectedItems = [], visibleItems = [], checked) => {
    let newSelectedItems = [];

    if (!checked) {
        newSelectedItems = selectedItems.filter(selectedItem => !visibleItems.some(item => item.id === selectedItem.id));
    } else {
        visibleItems.forEach(item => {
        if (selectedItems.some(selectedItem => selectedItem.id === item.id)) return;
        newSelectedItems.push(item);
        });
        newSelectedItems = [...newSelectedItems, ...selectedItems];
    }

    return newSelectedItems;
  };

// this helper will work only with an array of objects for visibleItems
// every object will be compared as JSON
export const isAllVisibleItemsSelectedForArray = (visibleItems = [], selectedItems = []) => {
    return visibleItems.every(item => {
        const selectedItem = selectedItems.find(({ id }) => id === item.id);
        return selectedItem && isEqual(item, selectedItem);
    });
}

// this helper will return an object containing monday and friday of the current week

export const getMondayAndFridayOfCurrentWeek = () => {
    const today = new Date();
    const monday = startOfWeek(today, { weekStartsOn: 1 });
    const friday = addDays(monday, 4);
    return { monday, friday };
};

// it is a timer, you need to pass the number in miliseconds and it would be wait until the time finished.
export const timeout = (delay) => new Promise(res => setTimeout(res, delay));

export default createSelectOptions;