import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Button,
    Alert,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import { useCreateSeniorityTitlesMutation } from 'services/seniorities';
import { decodeErrors } from 'helpers';

const CreateSeniorityTitles = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('');
    const [createMutation, { isLoading }] = useCreateSeniorityTitlesMutation();

    const onNameChange = ({ target: { value } }) => setName(value);

    const onSave = async () => {
        const body = { name };
        const { data, error } = await createMutation(body);
        if (error) {
            setErrors(error.data?.errors ?? decodeErrors(error.data?.data ?? []));
            setErrorMessage(error.data?.message ?? error.data?.status?.msg ?? '')
            return;
        }
        if (!data) return;
        navigate('/seniority-titles');
    }

    return (
        <div>
            <PageHeader title='Create Seniority Title' className='pb-3'>
                <Button tag={Link} to='/seniority-titles'>Back to list</Button>
            </PageHeader>
            <div className="container pb-3">
                <Row className="justify-content-center">
                    <Col md='12'>
                        <Alert color="danger" isOpen={!!errorMessage} toggle={() => setErrorMessage('')}>
                            <h4 className="alert-heading">Error</h4>
                            <p className="mb-0">{errorMessage}</p>
                        </Alert>
                        <Card>
                            <CardHeader><h4>General Information</h4></CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input type='text' value={name} name='name' onChange={onNameChange} invalid={!!errors.name} />
                                            {!!errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <div className="text-end">
                            <Button className='mt-4 mb-4' color='primary' onClick={onSave}>
                                {isLoading ? <> <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span> Saving</> : 'Save'}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default CreateSeniorityTitles;