import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, NavLink as RRNavLink } from 'reactstrap';

const NavTab = ({ links, className = '' }) => {
    return (
        <Nav className={`mt-2 ${className}`} tabs>
            {
                links.map(({ name, path, isVisible }) => isVisible && (
                    <NavItem key={name}>
                        <RRNavLink tag={NavLink} end to={path}>
                            {name}
                        </RRNavLink>
                    </NavItem>
                ))
            }
        </Nav>
    );
};

export default NavTab;