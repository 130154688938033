import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Button,
} from 'reactstrap';
import ReimbursementDetailCard from 'Widgets/ReimbursementDetailCard';
import AcceptOrRejectReimbursementModal from './AcceptOrRejectReimbursementModal';
import { useGetReimbursementRequestByIdQuery } from 'services/reimbursements';

const DetailReimbursementRequests = ({ user }) => {
    const { id } = useParams();
    const [reimbursement, setReimbursement] = useState({});
    const [selectedRequestType, setSelectedRequestType] = useState('accept');
    const [isAcceptRejectModalOpen, setIsAcceptOrRejectModal] = useState(false);
    const { data: reimbursementData } = useGetReimbursementRequestByIdQuery(id);

    useEffect(() => {
        if (!reimbursementData) return;
        setReimbursement(reimbursementData);
    }, [reimbursementData]);

    // handlers

    const handleAcceptReject = (e) => {
        const { dataset } = e.target;
        setSelectedRequestType(dataset.type);
    }

    const toggleAcceptRejectModal = () => setIsAcceptOrRejectModal(!isAcceptRejectModalOpen);

    const onAcceptRejectModalClosed = () => setSelectedRequestType('accept');

    return (
        <>
            <div className="header pb-3">
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <h1>Reimbursements</h1>
                    <Button
                        color='secondary'
                        size='sm'
                        tag={Link}
                        to='/reimbursements'
                    >
                        Back to list
                    </Button>
                </div>
            </div>
            <div className="container pb-3">
                <ReimbursementDetailCard
                    user={user}
                    reimbursement={reimbursement}
                    handleAcceptReject={handleAcceptReject}
                    showAcceptRejectButtons
                />
            </div>
            <AcceptOrRejectReimbursementModal
                id={reimbursement?.id || null}
                requestType={selectedRequestType}
                isOpen={isAcceptRejectModalOpen}
                toggle={toggleAcceptRejectModal}
                onClosed={onAcceptRejectModalClosed}
            />
        </>
    );
}

export default DetailReimbursementRequests;