import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, FormGroup, Button, Label, Input, FormFeedback } from 'reactstrap';
import PasswordInput from 'Components/PasswordInput/PasswordInput';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import { useForgotPasswordMutation, useGetResetPasswordEmailByTokenQuery, useResetPasswordMutation } from 'services/auth';
import 'pages/Login/login.css';

const PasswordResset = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [errors, setErrors] = useState({});
    const { data: email, isFetching, isError: invalidToken } = useGetResetPasswordEmailByTokenQuery(token);
    const [resetPasswordMutation, { isLoading: isSendingPassword, isSuccess: passwordSuccess }] = useResetPasswordMutation();

    const resetPassword = async (e) => {
        e.preventDefault();
        const body = {
            email,
            password,
            password_confirmation: passwordConfirmation,
            token,
        };
        const { data, error } = await resetPasswordMutation(body);
        if (error) {
            setErrors(error.data?.errors ?? {});
            return;
        }
        if (!data) return;
    };

    return (
        <div className="fullpage-login-container">
            <div className="image-column d-flex flex-column justify-content-center align-items-center">
                <img
                    src="/z-login-white.svg"
                    height="400"
                    alt="z-Logolg"
                    className="fpi-logo d-none d-sm-none d-md-none d-lg-block d-xl-block"
                />
                <img
                    src="/images/logo-dark.svg"
                    height="100"
                    alt="z-Logomd"
                    className="d-lg-none d-xl-none"
                />
            </div>
            <div className="control-column">
                <div className="text-start alt-logo">
                    <img
                        src="/images/logo.svg"
                        height="80"
                        alt="z-Logosm"
                        className="mb-4"
                    />
                </div>
                <LoadingIndicator isLoading={isFetching} />
                {!isFetching && invalidToken &&
                    <>
                        <h1 className="mb-0">Invalid token</h1>
                        <p className="pt-3">This password reset link is not valid or has expired. Please submit the password reset form again.</p>
                        <div className="text-end">
                            <Link className="btn btn-secondary" to='/'>Go back</Link>
                        </div>
                    </>
                }
                {!isFetching && email && !passwordSuccess &&
                    <>
                        <h1 className="mb-0 text-center">Create a new password</h1>
                        <p className="pt-3">To complete your password reset request, please provide a new password and click the Finish button</p>
                        <div className="text-start">
                            <Form onSubmit={resetPassword}>
                                <FormGroup row>
                                    <Label>Type your new password</Label>
                                    <PasswordInput
                                        value={password}
                                        placeholder='Enter your new password here'
                                        handleChange={e => setPassword(e.target.value)}
                                        hasError={!!errors?.password}
                                        disabled={isSendingPassword}
                                    />
                                    {!!errors?.password && <FormFeedback className='d-block'>{errors?.password}</FormFeedback>}
                                </FormGroup>
                                <FormGroup row>
                                    <Label>Confirm your new password</Label>
                                    <PasswordInput
                                        value={passwordConfirmation}
                                        placeholder='Confirm your new password here'
                                        handleChange={e => setPasswordConfirmation(e.target.value)}
                                        hasError={!!errors?.password_confirmation}
                                        disabled={isSendingPassword}
                                    />
                                    {!!errors?.password_confirmation && <FormFeedback className='d-block'>{errors?.password_confirmation}</FormFeedback>}
                                </FormGroup>
                                <div className="text-end">
                                    <Link className="btn btn-secondary" to='/'>Go back</Link>
                                    <Button type='submit' color='primary' className="ms-2" disabled={isSendingPassword}>
                                        {isSendingPassword ? <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" /> Finish</> : 'Finish'}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </>
                }
                {passwordSuccess &&
                    <>
                        <h1 className="mb-0 text-center">Password reset successfully</h1>
                        <p className="pt-3">Your password was reset and you can now log in using your new password.</p>
                        <div className="text-end">
                            <Link className="btn btn-secondary" to='/'>Go to login</Link>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [forgotPasswordMutation, { isLoading, isSuccess }] = useForgotPasswordMutation();

    const requestPasswordReset = async (e) => {
        e.preventDefault();
        const body = { email };
        const { data, error } = await forgotPasswordMutation(body);
        if (error) {
            setErrors(error.data?.errors ?? {});
            return;
        }
        if (!data) return;
        setErrors({});
        setEmail('');
    }

    return (
        <div className="fullpage-login-container">
            <div className="image-column d-flex flex-column justify-content-center align-items-center">
                <img
                    src="/z-login-white.svg"
                    height="400"
                    alt="z-Logolg"
                    className="fpi-logo d-none d-sm-none d-md-none d-lg-block d-xl-block"
                />
                <img
                    src="/images/logo-dark.svg"
                    height="100"
                    alt="z-Logomd"
                    className="d-lg-none d-xl-none"
                />
            </div>
            <div className="control-column">
                <div className="text-start alt-logo">
                    <img
                        src="/images/logo.svg"
                        height="80"
                        alt="z-Logosm"
                        className="mb-4"
                    />
                </div>
                <h1 className="mb-0 text-center">
                    {isSuccess ?
                        'Almost there...' :
                        'Reset your password'
                    }
                </h1>
                <p className="pt-3 text-justify">
                    {isSuccess ?
                        "We've sent a message with instructions to your e-mail address. Make sure you also check your spam folder." :
                        "To reset your password, please enter your e-mail address and click the Submit button. An e-mail message will be sent with instructions to create a new password."
                    }
                </p>
                {
                    isSuccess ?
                        <div className="text-end">
                            <Link className="btn btn-secondary" to='/'>Go back</Link>
                        </div>
                        :
                        <div className="text-start">
                            <Form onSubmit={requestPasswordReset}>
                                <FormGroup row>
                                    <Label>E-mail address</Label>
                                    <Input
                                        type='email'
                                        placeholder='Enter your e-mail address here'
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        invalid={!!errors?.email}
                                        disabled={isLoading}
                                    />
                                    {!!errors?.email && <FormFeedback className='d-block'>{errors?.email}</FormFeedback>}
                                </FormGroup>
                                <div className="text-end">
                                    <Link className="btn btn-secondary" to='/'>Go back</Link>
                                    <Button type='submit' color='primary' className="ms-2" disabled={isLoading}>
                                        {isLoading ? <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" /> Sending</> : 'Submit'}
                                    </Button>
                                </div>
                            </Form>
                        </div>
                }
            </div>
        </div>
    );
}

export default PasswordResset;