import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'departments';

export const departmentsApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getDeparments: builder.query({
            query: () => BASE_ENDPOINT,
            transformResponse: response => response.data,
            providesTags: ['Departments', {type: 'Departments', id: 'LIST'}],
        }),
        getDeparmentById: builder.query({
            query: (id) => `${BASE_ENDPOINT}/${id}`,
            transformResponse: response => response.data,
            providesTags: ['Departments'],
        }),
        createDepartment: builder.mutation({
            query: ({ body }) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                body,
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['Departments'],
        }),
        updateDepartment: builder.mutation({
            query: ({ departmentId, body }) => ({
                url: `${BASE_ENDPOINT}/${departmentId}`,
                method: 'PUT',
                body,
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['Departments'],
        }),
        deleteDepartment: builder.mutation({
            query: (departmentId) => ({
                url: `${BASE_ENDPOINT}/${departmentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{type: 'Departments', id: 'LIST'}],
        }),
    }),
});

export const {
    useGetDeparmentsQuery,
    useLazyGetDeparmentsQuery,
    useGetDeparmentByIdQuery,
    useLazyGetDeparmentByIdQuery,
    useCreateDepartmentMutation,
    useUpdateDepartmentMutation,
    useDeleteDepartmentMutation,
} = departmentsApi;