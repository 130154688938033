import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import ReimbursementCategoryForm from 'pages/ReimbursementCategories/ReimbursementCategoryForm/ReimbursementCategoryForm';
import { useGetReimbursementCategoryByIdQuery, useUpdateReimbursementCategoryMutation } from 'services/reimbursements';

const EditReimbursementCategory = () => {
    const [category, setCategory] = useState({});
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: dataCategory } = useGetReimbursementCategoryByIdQuery(id);
    const [updateCategoryMutation, { isLoading }] = useUpdateReimbursementCategoryMutation();

    useEffect(() => {
        if (!dataCategory) return;
        setCategory(dataCategory);
    }, [dataCategory]);

    const handleCategoryChange = (name, value) => setCategory(prevCategory => ({ ...prevCategory, [name]: value, }));

    const onInputChange = ({ target: { name, value, } }) => handleCategoryChange(name, value);

    const onSelectChange = (option, { name }) => handleCategoryChange(name, option?.value);

    const onSwitchChange = (checked, e, id) => handleCategoryChange(id, checked);

    const onButtonClick = async () => {
        const { data, error } = await updateCategoryMutation({ categoryId: id, body: category });
        if (error) {
            setErrorMessage(error.data?.message || '');
            setErrors(error.data?.errors ?? {});
            return;
        }
        if (!data) return;
        navigate(`/reimbursement-categories/${id}`);
    }

    return (
        <>
            <PageHeader title='Edit Reimburesement Category' className='pb-3'>
                <Button
                    color='secondary'
                    size='sm'
                    tag={Link}
                    to={`/reimbursement-categories/${id}`}
                >
                    Return to detail
                </Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <ReimbursementCategoryForm
                    category={category}
                    errors={errors}
                    errorMessage={errorMessage}
                    headerText='Edit'
                    buttonText='Update'
                    loadingButtonText='Updating...'
                    handleInput={onInputChange}
                    handleSelect={onSelectChange}
                    handleSwitch={onSwitchChange}
                    handleButtonClick={onButtonClick}
                    isLoading={isLoading}
                />
            </div>
        </>
    );
}

export default EditReimbursementCategory;