import {
  BsCash,
  BsShieldFillExclamation,
  BsFillCalendar2RangeFill,
  BsGridFill,
  BsPieChart,
  BsReceipt,
  BsBuilding,
  BsGear,
  BsGrid,
  BsCalendarPlus,
  BsListTask,
  BsFilePerson,
} from "react-icons/bs";
import { MdSubtitles } from "react-icons/md";
import { BiMoney } from "react-icons/bi";
import { FiCalendar, FiUsers, FiChevronRight, FiList, FiCornerUpRight, FiPackage, FiActivity, FiDollarSign, FiLayers, FiTarget } from "react-icons/fi";
import { FaMoneyBill, FaPlane, FaUser, FaTag, FaMoneyBillWave, FaMoneyCheckAlt, FaCalendarAlt, FaClipboardList, FaClock, FaUmbrellaBeach } from "react-icons/fa";
import { GiSkills } from 'react-icons/gi';
import { RiLogoutBoxLine, RiTeamLine } from "react-icons/ri";
import { FcComboChart } from "react-icons/fc";
import { IoIosApps } from "react-icons/io";
import { TbBeachOff } from 'react-icons/tb';
import {
  canApproveTimelogs,
  canCreateBills,
  canCreateDepartments,
  canCreateExpenseCategories,
  canCreateExpenses,
  canCreateLegalEntities,
  canCreateSaleLevels,
  canListSaleLevels,
  canCreateProjects,
  canCreateRateCards,
  canCreateRoles,
  canCreateSeniorityTitles,
  canCreateSystemRoles,
  canCreateTags,
  canCreateTeams,
  canCreateUsers,
  canListAllExpenses,
  canListAllocations,
  canListUtilizationChartReport,
  canListExpensesByCategoryReports,
  canListReimbursementsReports,
  canListExpensesPackagingReport,
  canListUtilizationReport,
  canListUnpaidHoursReport,
  canListMilestonesReport,
  canListRevenueReport,
  canListInvoicingReport,
  canListPaymentsReport,
  canListTimeOffReport,
  canListBenchReport,
  canListMissingTimelogsReport,
  canListProjectStatusReport,
  canListBills,
  canListGeneralSettings,
  canListJobDiva,
  canListReimbursementCategories,
  canListReimbursementRequests,
  canOpenTeamTimelogs,
  canUpdateDepartments,
  canUpdateExpenseCategories,
  canUpdateLegalEntities,
  canUpdateProjects,
  canUpdateRateCards,
  canUpdateRoles,
  canUpdateSeniorityTitles,
  canUpdateSystemRoles,
  canUpdateTags,
  canUpdateTeams,
  canUpdateUsers,
  canCreateHolidays,
  canUpdateHolidays,
  canCreateTimeOffRules,
  canUpdateTimeOffRules,
} from "./common";

const showReportsButton =
  canListExpensesByCategoryReports ||
  canListReimbursementsReports ||
  canListExpensesPackagingReport ||
  canListUtilizationReport ||
  canListUnpaidHoursReport ||
  canListMilestonesReport ||
  canListRevenueReport ||
  canListInvoicingReport ||
  canListPaymentsReport ||
  canListTimeOffReport ||
  canListBenchReport ||
  canListMissingTimelogsReport ||
  canListUtilizationChartReport ||
  canListProjectStatusReport;

const showManagementButton =
  canCreateUsers ||
  canUpdateUsers ||
  canCreateRoles ||
  canUpdateRoles ||
  canCreateRateCards ||
  canUpdateRateCards ||
  canCreateTags ||
  canUpdateTags ||
  canCreateDepartments ||
  canUpdateDepartments ||
  canCreateSaleLevels ||
  canListSaleLevels ||
  canCreateLegalEntities ||
  canUpdateLegalEntities ||
  canListReimbursementRequests ||
  canListReimbursementCategories ||
  canCreateExpenseCategories ||
  canUpdateExpenseCategories ||
  canListJobDiva ||
  canCreateHolidays ||
  canUpdateHolidays;

export const SPECIAL_BUTTONS = [
  "CREATE_USERS",
  "UPDATE_USERS",
  "CREATE_ROLES",
  "UPDATE_ROLES",
  "CREATE_SYSTEMROLES",
  "UPDATE_SYSTEMROLES",
  "CREATE_LEGALENTITIES",
  "UPDATE_LEGALENTITIES",
  "CREATE_SALESLEVELS",
  "UPDATE_SALELEVELS",
  "DELETE_SALESLEVELS",
  "LIST_SALESLEVELS",
  "CREATE_RATECARDS",
  "UPDATE_RATECARDS",
  "CREATE_TAGS",
  "UPDATE_TAGS",
  "CREATE_DEPARTMENTS",
  "UPDATE_DEPARTMENTS",
  "INTEGRATION_JOBDIVA",
  "LIST_SETTINGS",
  "LIST_ALL_REIMBURSEMENTCATEGORIES",
  "CREATE_EXPENSESCATEGORIES",
  "UPDATE_EXPENSESCATEGORIES",
];

export const NAV_ITEMS = [
  {
    name: "Dashboard",
    isVisible: true,
    icon: BsGrid,
  },
  {
    name: "Time",
    isVisible: true,
    icon: BsCalendarPlus,
    subMenu: [
      {
        name: "Timesheet",
        isVisible: true,
        path: "/time",
      },
      {
        name: "Team",
        isVisible: canOpenTeamTimelogs,
        path: "/time/team",
      },
      {
        name: "Approvals",
        isVisible: canApproveTimelogs,
        path: "/time/approvals",
      },
    ],
  },
  {
    name: "Allocation",
    isVisible: canListAllocations,
    icon: BsFillCalendar2RangeFill,
    subMenu: [
      {
        name: "Team",
        isVisible: true,
        path: "/allocation",
      },
      {
        name: "Roles",
        isVisible: true,
        path: "/allocation/roles",
      },
    ],
  },
  {
    name: "Expenses",
    isVisible: canListAllExpenses,
    icon: BsCash,
    subMenu: [
      {
        name: "Expenses list",
        isVisible: canListAllExpenses,
        path: "/expenses",
      },
      {
        name: "Add expense",
        isVisible: canCreateExpenses,
        path: "/expenses/create",
      },
    ],
  },
  {
    name: "Projects",
    isVisible: canCreateProjects || canUpdateProjects,
    icon: BsGridFill,
    subMenu: [
      {
        name: "Projects list",
        isVisible: true,
        path: "/projects",
      },
      {
        name: "Add project",
        isVisible: canCreateProjects,
        path: "/projects/create",
      },
    ],
  },
  {
    name: "Teams",
    isVisible: canCreateTeams || canUpdateTeams,
    icon: RiTeamLine,
    subMenu: [
      {
        name: "Teams list",
        isVisible: true,
        path: "/teams",
      },
      {
        name: "Add team",
        isVisible: canCreateTeams,
        path: "/teams/create",
      },
    ],
  },
  {
    name: "Reports",
    isVisible: showReportsButton,
    icon: BsPieChart,
    subMenu: [
      {
        name: "Expenses By Category",
        isVisible: canListExpensesByCategoryReports,
        description: "Displays the list of expenses grouped by category.",
        path: "/reports/expenses-by-category",
        icon: FiList,
        hideIcon: true,
      },
      {
        name: "Reimbursements",
        isVisible: canListReimbursementsReports,
        description: "Displays the list of reimbursements grouped by user",
        path: "/reports/reimbursements",
        icon: FiCornerUpRight,
        hideIcon: true,
      },
      {
        name: "Expense Package",
        isVisible: canListExpensesPackagingReport,
        description: "Generates a package of vouchers and a summary of expenses per period",
        path: "/reports/expense-packages",
        icon: FiPackage,
        hideIcon: true,
      },
      {
        name: "Team Utilization",
        isVisible: canListUtilizationReport,
        description: "Displays utilization percentages for team members",
        path: "/reports/team-utilization",
        icon: FiActivity,
        hideIcon: true,
      },
      {
        name: "Unpaid Hours",
        isVisible: canListUnpaidHoursReport,
        description: "Displays unpaid hours grouped by team members",
        path: "/reports/unpaid-hours",
        icon: FiDollarSign,
        hideIcon: true,
      },
      {
        name: "Milestones",
        isVisible: canListMilestonesReport,
        description: "Displays milestone and invoice information",
        path: "/reports/milestones",
        icon: FiLayers,
        hideIcon: true,
      },
      {
        name: "Revenue",
        isVisible: canListRevenueReport,
        description: "Estimate the expected revenue for a period.",
        path: "/reports/revenue",
        icon: FiTarget,
        hideIcon: true,
      },
      {
        name: "Invoicing",
        isVisible: canListInvoicingReport,
        description: "Display invoices due for a given period of time.",
        path: "/reports/invoicing",
        icon: FaMoneyBillWave,
        hideIcon: true,
      },
      {
        name: "Payments",
        isVisible: canListPaymentsReport,
        description: "Display payments due for a given period of time.",
        path: "/reports/payments",
        icon: FaMoneyCheckAlt,
        hideIcon: true,
      },
      {
        name: "Time Off",
        isVisible: canListTimeOffReport,
        description: "Displays time off requests due for a given period of time.",
        path: "/reports/time-off",
        icon: FaCalendarAlt,
        hideIcon: true,
      },
      {
        name: "Bench Availability",
        isVisible: canListBenchReport,
        description: "Displays bench availability according to users allocation.",
        path: "/reports/bench-availability-report",
        icon: FaClipboardList,
        hideIcon: true,
      },
      {
        name: "Missing Timelogs",
        isVisible: canListMissingTimelogsReport,
        description: "Displays missing timelogs according to the hours assigned per user.",
        path: "/reports/missing-timelogs",
        icon: FaClock,
        hideIcon: true,
      },
      {
        name: "Utilization Chart",
        isVisible: canListUtilizationChartReport,
        description: "Displays chart utilization percentages for team members",
        path: "/reports/team-utilization-reports",
        icon: FcComboChart,
        hideIcon: true,
      },
      {
        name: "Project Status",
        isVisible: canListProjectStatusReport,
        description: "Displays projects status for team members",
        path: "/reports/project-status",
        icon: BsGridFill,
        hideIcon: true,
      },
    ],
  },
  {
    name: "Billing",
    isVisible: canListBills,
    icon: BsReceipt,
    subMenu: [
      {
        name: "Bill List",
        isVisible: true,
        path: "/billing",
      },
      {
        name: "Add Bill",
        isVisible: canCreateBills,
        path: "/billing/create",
      },
    ],
  },
  {
    name: "Management",
    path: "/management",
    isVisible: showManagementButton,
    icon: BsBuilding,
    subMenu: [
      {
        name: "Users",
        isVisible: canCreateUsers || canUpdateUsers,
        path: "/users",
      },
      {
        name: "Roles",
        isVisible: canCreateRoles || canUpdateRoles,
        path: "/roles",
      },
      {
        name: "Rate Cards",
        isVisible: canCreateRateCards || canUpdateRateCards,
        path: "/roles-rate-cards",
      },
      {
        name: "Tags",
        isVisible: canCreateTags || canUpdateTags,
        path: "/tags",
      },
      {
        name: "Departments",
        isVisible: canCreateDepartments || canUpdateDepartments,
        path: "/departments",
      },
      {
        name: "Seniority Titles",
        isVisible: canCreateSeniorityTitles || canUpdateSeniorityTitles,
        path: "/seniority-titles",
      },
      {
        name: "Sale Levels",
        isVisible: canCreateSaleLevels || canListSaleLevels,
        path: "/sale-levels",
      },
      {
        name: "Legal Entities",
        isVisible: canCreateLegalEntities || canUpdateLegalEntities,
        path: "/legal-entities",
      },
      {
        name: "Reimbursements",
        isVisible: canListReimbursementRequests,
        path: "/reimbursements",
      },
      {
        name: "Expense Categories",
        isVisible: canCreateExpenseCategories || canUpdateExpenseCategories,
        path: "/expenses-categories",
      },
      {
        name: "Reimbursement Requests Categories",
        isVisible: canListReimbursementCategories,
        path: "/reimbursement-categories",
      },
      {
        name: "Holidays",
        isVisible: canCreateHolidays || canUpdateHolidays,
        path: "/holidays",
      },
      {
        name: "Time Off Rules",
        isVisible: canCreateTimeOffRules || canUpdateTimeOffRules,
        path: "/time-off-rules",
      },
      {
        name: "JobDiva",
        isVisible: canListJobDiva,
        path: "/jobdiva",
      },
    ],
  },
  {
    name: "Settings",
    isVisible:
      canCreateSystemRoles || canUpdateSystemRoles || canListGeneralSettings,
    icon: BsGear,
    subMenu: [
      {
        name: "General",
        isVisible: canListGeneralSettings,
        path: "/settings",
      },
      {
        name: "System Roles",
        isVisible: canCreateSystemRoles || canUpdateSystemRoles,
        path: "/system-roles",
      },
      {
        name: "System Versions",
        isVisible: canListGeneralSettings,
        path: "/settings/system-versions",
      },
    ],
  },
];

export const DROPDOWN_ITEMS = [
  {
    name: "My Profile",
    path: "/profile",
    icon: FaUser,
    isVisible: true,
  },
  {
    name: "My Time Offs",
    path: "/profile/my-time-off",
    icon: FaPlane,
    isVisible: true,
  },
  {
    name: "My Reimbursements",
    path: "/profile/reimbursements",
    icon: FaMoneyBill,
    isVisible: true,
  },
  {
    name: "My Skills",
    path: "/profile/skills",
    icon: GiSkills,
    isVisible: true,
  },
  {
    name: "Account Settings",
    path: "/profile/apps-and-services",
    icon: IoIosApps,
    isVisible: true,
  },
  {
    name: "Sign Out",
    path: "/logout",
    icon: RiLogoutBoxLine,
    isVisible: true,
  },
];

export const FOOTER_NAV_ITEMS = [
  {
    name: "Any Problem with Z?",
    path: "any-problem",
    icon: BsShieldFillExclamation,
    isVisible: true,
  },
  {
    name: "Sign Out",
    path: "/logout",
    icon: RiLogoutBoxLine,
    isVisible: true,
  },
];

export const MANAGMENT_IETMS = [
  {
    name: "Users",
    isVisible: canCreateUsers || canUpdateUsers,
    path: "/users",
    icon: <FiUsers className="my-2"></FiUsers>,
    descriptions: "Users managment",
  },
  {
    name: "Roles",
    isVisible: canCreateRoles || canUpdateRoles,
    path: "/roles",
    icon: <FiCalendar className="my-2"></FiCalendar>,
    descriptions: "Roles managment",
  },
  {
    name: "Rate Cards",
    isVisible: canCreateRateCards || canUpdateRateCards,
    path: "/roles-rate-cards",
    icon: <FiCalendar className="my-2"></FiCalendar>,
    descriptions: "Rates cards managment",
  },
  {
    name: "Tags",
    isVisible: canCreateTags || canUpdateTags,
    path: "/tags",
    icon: <FaTag className="my-2"></FaTag>,
    descriptions: "Tags managment",
  },
  {
    name: "Departments",
    isVisible: canCreateDepartments || canUpdateDepartments,
    path: "/departments",
    icon: <FiUsers className="my-2"></FiUsers>,
    descriptions: "Departments managment",
  },
  {
    name: "Seniority Titles",
    isVisible: canCreateSeniorityTitles || canUpdateSeniorityTitles,
    path: "/seniority-titles",
    icon: <BsListTask className="my-2"></BsListTask>,
    descriptions: "Seniority titles levels managment",
  },
  {
    name: "Sale Levels",
    isVisible: canCreateSaleLevels || canListSaleLevels,
    path: "/sale-levels",
    icon: <BsFilePerson className="my-2"></BsFilePerson>,
    descriptions: "Sale levels managment",
  },
  {
    name: "Legal Entities",
    isVisible: canCreateLegalEntities || canUpdateLegalEntities,
    path: "/legal-entities",
    icon: <MdSubtitles className="my-2"></MdSubtitles>,
    descriptions: "Legal entities managment",
  },
  {
    name: "Reimbursements",
    isVisible: canListReimbursementRequests,
    path: "/reimbursements",
    icon: <BiMoney className="my-2"></BiMoney>,
    descriptions: "Reimbursements  managment",
  },
  {
    name: "Expense Categories",
    isVisible: canCreateExpenseCategories || canUpdateExpenseCategories,
    path: "/expenses-categories",
    icon: <BiMoney className="my-2"></BiMoney>,
    descriptions: "Expenses categories managment",
  },
  {
    name: "Reimbursement Requests Categories",
    isVisible: canListReimbursementCategories,
    path: "/reimbursement-categories",
    icon: <FiChevronRight className="my-2"></FiChevronRight>,
    descriptions: "Reimbursement categories managment",
  },
  {
    name: "Holidays",
    isVisible: canCreateHolidays || canUpdateHolidays,
    path: "/holidays",
    icon: <FaUmbrellaBeach className="my-2"/>,
    descriptions: "Holidays management",
  },
  {
    name: "Time Off Rules",
    isVisible: canCreateTimeOffRules || canUpdateTimeOffRules,
    path: "/time-off-rules",
    icon: <TbBeachOff className="my-2"/>,
    descriptions: "Time Off Rules management",
  },
  {
    name: "JobDiva",
    isVisible: canListJobDiva,
    path: "/jobdiva",
    icon: <FiCalendar className="my-2"></FiCalendar>,
    descriptions: "JobDiva managment",
  },
];
