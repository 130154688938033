import { formatToAbbreviation } from 'helpers';
import React from 'react';

const DetailRow = ({detail = {}, colSpan = 7}) => {
    return(
        <>
        <thead>
            <tr>
                <th
                    className='text-capitalize'
                    colSpan={ colSpan }
                >
                    { detail.name } - { detail.type } - { detail.concept }
                </th>
            </tr>
            </thead>
            <tbody>
                {
                detail.data?.allocation?.map((allocation, idx) => {
                    return(
                        <tr
                            key={`allocation-${idx}`}
                        >
                            <td className='align-middle'>
                                { detail.id }
                            </td>
                            <td className='align-middle'>
                                Allocation: { allocation.type } <br />
                                Rate card: { allocation.rate_card?.type }
                            </td>
                            <td className='align-middle'>
                                Allocation: { allocation.concept ?? '-' } <br />
                                Rate card: { allocation.rate_card?.concept ?? '-' }
                            </td>
                            <td className='align-middle'>
                                { allocation.jobdiva_company ?? '' }
                            </td>
                            <td className='align-middle'>
                                {formatToAbbreviation(allocation.jobdiva_start_date)} - {formatToAbbreviation(allocation.jobdiva_end_date)}
                            </td>
                            <td className='align-middle'>
                                { allocation.rate_card.jobdiva_pay_rate ?? '-' }
                            </td>
                            <td className='align-middle'>
                                { allocation.rate_card.jobdiva_bill_rate ?? '-' }
                            </td>
                        </tr>
                    );
                })
                }
            </tbody>
        </>
    );
}

export default DetailRow;