import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'system-versions';

export const versionsApi = zigattaApi.injectEndpoints({
    endpoints: builder => ({
        getSystemVersions: builder.query({
            query: () => BASE_ENDPOINT,
            transformResponse: response => response.data,
            providesTags: ['SystemVersions', { type: 'SystemVersions', id: 'LIST' }],
        }),
        getSystemVersionById: builder.query({
            query: (versionId) => `${BASE_ENDPOINT}/${versionId}`,
            transformResponse: response => response.data,
            providesTags: ['SystemVersions'],
        }),
        getLastSystemVersion: builder.query({
            query: () => `${BASE_ENDPOINT}/last`,
            transformResponse: response => response.data,
            providesTags: ['SystemVersions'],
        }),
        createSystemVersion: builder.mutation({
            query: (body = {}) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['SystemVersions'],
        }),
        updateSystemVersion: builder.mutation({
            query: ({ body = {}, versionId }) => ({
                url: `${BASE_ENDPOINT}/${versionId}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['SystemVersions'],
        }),
        deleteSystemVersion: builder.mutation({
            query: (versionId) => ({
                url: `${BASE_ENDPOINT}/${versionId}`,
                method: 'DELETE',
                headers: { 'Accept': 'application/json' },
            }),
            invalidatesTags: [{ type: 'SystemVersions', id: 'LIST' }],
        }),
    }),
});

export const {
    useGetSystemVersionsQuery,
    useGetSystemVersionByIdQuery,
    useGetLastSystemVersionQuery,
    useCreateSystemVersionMutation,
    useUpdateSystemVersionMutation,
    useDeleteSystemVersionMutation,
} = versionsApi;