import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { createToast } from 'helpers';
import { useCancelJobdivaSyncMutation } from 'services/jobdiva';

const textConfirmation = 'CANCEL SYNC';

const CancelSyncModal = ({
    isOpen,
    toggle,
    handleSyncCancelled,

}) => {
    const [inputTextConfirmation, setTextConfirmation] = useState('');
    const { id } = useParams();
    const [cancelSync, { isLoading: isPetitionLoading }] = useCancelJobdivaSyncMutation();
    
    // handlers

    const onClosed = () => setTextConfirmation('');

    const handleChange = ({ target: { value } }) => setTextConfirmation(value.toUpperCase());

    const handleCancel = async () => {
        const { data, error, } = await cancelSync(id);
        if(error) {
            createToast({
                type: 'error',
                text: `An error has ocurred, please contact the administrator.\nError code: ${error.status}`,
            });
            return;
        }
        if(!data) return;
        handleSyncCancelled();
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onClosed}
            centered
        >
            <ModalHeader toggle={toggle} className='bg-danger text-white'>
                Cancel sync
            </ModalHeader>
            <ModalBody>
                <p className="m-0 pb-3">
                    This action can't be undone. Do you want to cancel this sync?
                </p>
                <Row>
                    <Col
                        md={12}
                    >
                        <FormGroup>
                            <Label>
                                To activate, type <span className='font-italic text-uppercase'>{textConfirmation}</span> in the text input field.
                            </Label>
                            <Input
                                className='text-uppercase'
                                type='text'
                                name='inputTextConfirmation'
                                value={inputTextConfirmation}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter
                className='d-flex align-items-center justify-content-end'
            >
                <div>
                    <Button
                        className='me-2'
                        color='secondary'
                        size='sm'
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='danger'
                        size='sm'
                        onClick={handleCancel}
                        disabled={isPetitionLoading || inputTextConfirmation !== textConfirmation}
                    >
                        {isPetitionLoading
                            ?
                            <>
                                <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                Canceling...
                            </>
                            :
                            'Cancel'
                        }
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );

}

export default CancelSyncModal;