import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Alert, Card,
    CardBody,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
    Input,
    Tooltip,
    CardHeader,
    Button,
    FormFeedback,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import { useCreateLegalEntityMutation } from 'services/legalEntities';

const initialState = {
    name: '',
    tax_id: '',
    administrative_load: 0,
    isTooltipOpen: false,
    errors: {},
    errorMessage: '',
}

const CreateLegalEntity = () => {
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const [createLegalEntity, { isLoading }] = useCreateLegalEntityMutation();
    const { name, tax_id, administrative_load, isTooltipOpen, errors, errorMessage } = state;

    const onChange = ({ target: { name, value } }) => setState(prevState => ({ ...prevState, [name]: value }));

    const handleTooltip = () => setState(prevState => ({ ...prevState, isTooltipOpen: !prevState.isTooltipOpen }));

    const onSave = async (e) => {
        e.preventDefault();
        const body = {
            name,
            tax_id,
            administrative_load: parseInt(administrative_load),
        };

        const { data, error } = await createLegalEntity(body);
        if (error) {
            setState(prevState => ({
                ...prevState,
                errorMessage: error.data?.message || '',
                errors: error.data?.errors ?? {},
            }));
            return;
        }

        if (!data) return;
        navigate('/legal-entities');
    }


    return (
        <div>
            <PageHeader title='Create a legal entity' containerClass='container' className='pb-3'>
                <Button tag={Link} to='/legal-entities'>Back to entities list</Button>
            </PageHeader>
            <div className="container pb-3">
                <Form onSubmit={onSave}>
                    <Row className="justify-content-center">
                        <Col md='8'>
                            <Alert color="danger" isOpen={!!errorMessage}>
                                <h4 className="alert-heading">Error</h4>
                                <p className="mb-0">{errorMessage}</p>
                            </Alert>
                            <Card>
                                <CardHeader><h4>General Information</h4></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <Input type='text' value={name} name='name' onChange={onChange} invalid={!!errors.name} />
                                                {!!errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Tax Id</Label>
                                                <Input type='text' value={tax_id} name='tax_id' onChange={onChange} invalid={!!errors.tax_id} />
                                                {!!errors.tax_id && <FormFeedback>{errors.tax_id}</FormFeedback>}
                                            </FormGroup>
                                            <FormGroup>
                                                <Label>Administrative Load</Label>
                                                <Input type='range' step='1' min='0' max='100' value={administrative_load} name='administrative_load' id='administrative_load' onChange={onChange} invalid={!!errors.administrative_load} />
                                                {!!errors.administrative_load && <FormFeedback>{errors.administrative_load}</FormFeedback>}
                                                <Tooltip isOpen={isTooltipOpen} target='administrative_load' toggle={handleTooltip}>{administrative_load}%</Tooltip>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <div className="text-end">
                                <Button className='mt-4 mb-4' color='primary' type='submit'>
                                    {isLoading ? <> <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span> Saving</> : 'Save'}
                                </Button>
                            </div>
                        </Col>
                    </Row>

                </Form>
            </div>
        </div>
    );
}

export default CreateLegalEntity;