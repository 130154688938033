import Decimal from 'decimal.js';

const billsSelectedTotalAmount = (details = []) => {
    const total = details.reduce((prevValue, detail) => {
        const { cost, isChecked } = detail;
        let amount = 0;
        amount = isChecked ? new Decimal(cost) : new Decimal(0);
        return new Decimal(amount).plus(prevValue).toFixed(2);
    }, 0);

    return total;
}

export const recalculateBalance = (reimbursements, amountP) => {
    const totalReimbursements = new Decimal(reimbursements.reduce((sum, reimbursement) => {
        const amount = !isNaN(reimbursement?.amount) ? new Decimal(reimbursement.amount) : new Decimal(0);
        return new Decimal(sum).plus(amount).toFixed(2);
    }, 0));

    const amount =  !isNaN(amountP) ? new Decimal(amountP) : new Decimal(0);
    return  amount.minus(totalReimbursements).toFixed(2);
}

export default billsSelectedTotalAmount;
