const { zigattaApi } = require("./zigattaApi");

export const usersApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: (funcParams = {}) => {
                return {
                    url: 'users',
                    params: { ...funcParams },
                }
            },
            providesTags: ['Users'],
            transformResponse: response => JSON.stringify(response.meta) === '{}' ? response.data : response
        }),
        getUsersBriefList: builder.query({
            query: () => `users/brief-list`,
            transformResponse: response => response.data,
            providesTags: ['Users'],
        }),
        getProductOwners: builder.query({
            query: (funcParams = {}) => {
                return {
                    url: 'users/product-owners',
                    params: { ...funcParams },
                }
            },
            providesTags: ['Users'],
            transformResponse: response => JSON.stringify(response.meta) === '{}' ? response.data : response
        }),

        getUserById: builder.query({
            query: (userId) => `users/${userId}`,
            transformResponse: response => response.data,
            providesTags: ['Users'],
        }),
        createUser: builder.mutation({
            query: (body) => ({
                url: `users`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.user,
            invalidatesTags: ['Users', 'Skills'],
        }),
        updateUser: builder.mutation({
            query: ({ userId, body = {} }) => ({
                url: `users/${userId}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.user,
            invalidatesTags: ['Users'],
        }),
        updateUserSkill: builder.mutation({
            query: ({ userId, body = {} }) => ({
                url: `users/${userId}/update-skills`,
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    ContentType: "application/x-www-form-urlencoded",
                },
                body,
            }),
            transformResponse: response => response.user,
            invalidatesTags: ['Users', 'Skills'],
        }),
        updateUserNotificationsById: builder.mutation({
            query: ({ id, body }) => ({
                url: `users/${id}/update-notifications`,
                method: 'PUT',
                body,
            }),
        }),
        getUserPayRates: builder.query({
            query: (userId) => `users/${userId}/payRates`,
            transformResponse: response => response.pay_rates,
            providesTags: ['UserPayRates'],
        }),
        getUserConnections: builder.query({
            query: (id) => `users/${id}/profile-connections`,
        }),
        grantUserSlackConnection: builder.mutation({
            query: ({ userId }) => ({
                url: `users/${userId}/profile-add-slack`,
                method: 'PUT',
            }),
        }),
        removeUserSlackConnection: builder.mutation({
            query: ({ userId }) => ({
                url: `users/${userId}/profile-remove-slack`,
                method: 'PUT',
            }),
        }),
        linkJobdivaId: builder.mutation({
            query: ({ userId, body }) => ({
                url: `users/${userId}/profile-add-jobdiva`,
                method: 'PUT',
                body,
            }),
            transformResponse: response => response.user,
        }),
        unlinkJobdivaId: builder.mutation({
            query: (userId) => ({
                url: `users/${userId}/profile-remove-jobdiva`,
                method: 'PUT',
                body: {},
            }),
            transformResponse: response => response.user,
        }),
        enableUser: builder.mutation({
            query: (userId) => ({
                url: `users/${userId}/enable`,
                method: 'PUT',
            }),
            transformErrorResponse: response => response.user,
            invalidatesTags: ['Users'],
        }),
        disableUser: builder.mutation({
            query: (userId) => ({
                url: `users/${userId}`,
                method: 'DELETE',
            }),
            transformErrorResponse: response => response.user,
            invalidatesTags: ['Users'],
        }),
        resendUserInvitation: builder.mutation({
            query: (body = {}) => ({
                url: `users/resend-invitation`,
                method: 'POST',
                body,
            }),
            transformResponse: response => response.user,
        }),
        createUserPayRate: builder.mutation({
            query: ({ userId, body = {} }) => ({
                url: `users/${userId}/payRates`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.pay_rate,
            invalidatesTags: ['UserPayRates'],
        }),
        deleteUserPayRate: builder.mutation({
            query: ({ userId, payRateId }) => ({
                url: `users/${userId}/payRates/${payRateId}`,
                method: 'DELETE',
            }),
            transformResponse: response => response.salaries,
            invalidatesTags: ['UserPayRates'],
        }),
        getUserResumes: builder.query({
            query: () => `users/resumes`,
            transformResponse: response => response.documents,
            providesTags: ['Users'],
        }),
        updateProfile: builder.mutation({
            query: ({ body = {}, id }) => ({
                url: `users/${id}/update-profile`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.user,
            invalidatesTags: ['Users'],
        }),
        updateUserPassword: builder.mutation({
            query: ({ id, body = {} }) => ({
                url: `users/${id}/passwords`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
        }),
        updateProfilePicture: builder.mutation({
            query: ({ body, id }) => ({
                url: `users/${id}/update-profile-picture`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.user,
            invalidatesTags: ['Users'],
        }),
        getUsersSkills: builder.query({
            query: (funcParams = {}) => {
                return {
                    url: "skills",
                    params: { ...funcParams },
                };
            },
            providesTags: ["Skills"],
            transformResponse: (response) => response.data,
        }),
        getUserTimeOffSummaryById: builder.query({
            query: (id) => `users/${id}/timeoffs-summary`,
            transformResponse: response => response.data,
            providesTags: ['TimeOff'],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useLazyGetUsersQuery,
    useGetUsersBriefListQuery,
    useLazyGetUsersBriefListQuery,
    useGetUsersSkillsQuery,
    useLazyGetProductOwnersQuery,
    useGetProductOwnersQuery,
    useGetUserByIdQuery,
    useUpdateUserNotificationsByIdMutation,
    useGetUserConnectionsQuery,
    useLazyGetUserConnectionsQuery,
    useRemoveUserSlackConnectionMutation,
    useGrantUserSlackConnectionMutation,
    useCreateUserMutation,
    useUpdateUserMutation,
    useUpdateUserSkillMutation,
    useLinkJobdivaIdMutation,
    useUnlinkJobdivaIdMutation,
    useGetUserPayRatesQuery,
    useEnableUserMutation,
    useDisableUserMutation,
    useResendUserInvitationMutation,
    useCreateUserPayRateMutation,
    useDeleteUserPayRateMutation,
    useGetUserResumesQuery,
    useUpdateProfileMutation,
    useUpdateUserPasswordMutation,
    useUpdateProfilePictureMutation,
    useLazyGetUserTimeOffSummaryByIdQuery,
} = usersApi;
