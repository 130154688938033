const { zigattaApi } = require("./zigattaApi");

const BASE_ENDPOINT = 'time-off';

export const timeOffApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getTimeOffs: builder.query({
            query: (params = {}) => ({
                url: BASE_ENDPOINT,
                params,
            }),
            providesTags: ['TimeOff'],
        }),
        getTimeOffRequests: builder.query({
            query: (params = {}) => ({
                url: `${BASE_ENDPOINT}/underlings`,
                params,
            }),
            providesTags: ['TimeOff'],
        }),
        getTimeOffById: builder.query({
            query: (timeOffId) => `${BASE_ENDPOINT}/${timeOffId}`,
            transformResponse: response => response.data,
            providesTags: ['TimeOff'],
        }),
        createTimeOff: builder.mutation({
            query: (body = {}) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['TimeOff'],
        }),
        updateTimeOffStatus: builder.mutation({
            query: ({ timeOffId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${timeOffId}/update-status`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['TimeOff'],
        }),
        cancelTimeOffStatus: builder.mutation({
            query: ({ timeOffId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${timeOffId}/cancel`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body: { ...body, status: 'cancelled', },
            }),
            invalidatesTags: ['TimeOff'],
        }),
        getTimeOffTypes: builder.query({
            query: () => `${BASE_ENDPOINT}/types`,
            transformResponse: response => response.data,
        }),
        getTimeOffRules: builder.query({
            query: (params = {}) => ({
                url: `${BASE_ENDPOINT}-rules`,
                method: 'GET',
                headers: { 'Accept': 'application/json' },
                params,
            }),
            transformResponse: response => response.data,
            providesTags: ['TimeOffRules', { type: 'TimeOffRules', id: 'LIST' }],
        }),
        getTimeOffRuleById: builder.query({
            query: (id) => `${BASE_ENDPOINT}-rules/${id}`,
            transformResponse: response => response.data,
            providesTags: ['TimeOffRules'],
        }),
        createTimeOffRule: builder.mutation({
            query: (body = {}) => ({
                url: `${BASE_ENDPOINT}-rules`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['TimeOffRules'],
        }),
        updateTimeOffRule: builder.mutation({
            query: ({ id, body = {} }) => ({
                url: `${BASE_ENDPOINT}-rules/${id}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['TimeOffRules'],
        }),
        deleteTimeOffRule: builder.mutation({
            query: (id) => ({
                url: `${BASE_ENDPOINT}-rules/${id}`,
                method: 'DELETE',
                headers: { 'Accept': 'application/json' },
            }),
            invalidatesTags: [{ type: 'TimeOffRules', id: 'LIST' }],
        }),
    })
});

export const {
    useGetTimeOffsQuery,
    useLazyGetTimeOffsQuery,
    useGetTimeOffRequestsQuery,
    useGetTimeOffByIdQuery,
    useCreateTimeOffMutation,
    useUpdateTimeOffStatusMutation,
    useCancelTimeOffStatusMutation,
    useGetTimeOffTypesQuery,
    useGetTimeOffRulesQuery,
    useGetTimeOffRuleByIdQuery,
    useCreateTimeOffRuleMutation,
    useUpdateTimeOffRuleMutation,
    useDeleteTimeOffRuleMutation,
} = timeOffApi;