import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
    Alert,
    Card,
    CardBody,
    Row,
    Col,
    Label,
    Form,
    FormGroup,
    Input,
    Tooltip,
    CardHeader,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormFeedback,
} from 'reactstrap';
import checkPermission from 'helpers/checkPermission';
import { useDeleteLegalEntityMutation, useLazyGetLegalEntityByIdQuery, useUpdateLegalEntityMutation } from 'services/legalEntities';
import PageHeader from 'Components/PageHeader/PageHeader';

const canDeleteLegalEntities = checkPermission('DELETE_LEGALENTITIES');

const initialState = {
    name: '',
    tax_id: '',
    administrative_load: 0,
    errors: {},
    errorMessage: '',
    isDeleteModalOpen: false,
    isTooltipOpen: false,
};

const EditLegalEntity = () => {
    const [state, setState] = useState(initialState);
    const { id } = useParams();
    const navigate = useNavigate();
    const [getLegalEntity] = useLazyGetLegalEntityByIdQuery();
    const [updateLegalEntity, { isLoading }] = useUpdateLegalEntityMutation();
    const [deleteLegalEntity] = useDeleteLegalEntityMutation();
    const { name, tax_id, administrative_load, errors, errorMessage, isDeleteModalOpen, isTooltipOpen } = state;

    useEffect(() => {
        const getData = async () => {
            const { data } = await getLegalEntity(id);
            if (!data) return;
            setState(prevState => ({
                ...prevState,
                name: data.name,
                tax_id: data.tax_id,
                administrative_load: data.administrative_load ? parseInt(data.administrative_load) : 0
            }));
        }
        if (id) getData();
    }, [id]);

    const onChange = ({ target: { name, value } }) => setState(prevState => ({ ...prevState, [name]: value, }));

    const toggleTooltip = () => setState(prevState => ({ ...prevState, isTooltipOpen: !prevState.isTooltipOpen }));

    const toggleDeleteModal = () => setState(prevState => ({ ...prevState, isDeleteModalOpen: !prevState.isDeleteModalOpen }));

    const onUpdate = async (e) => {
        e.preventDefault();
        const body = {
            name,
            tax_id,
            administrative_load: parseInt(administrative_load),
        };
        const { data, error } = await updateLegalEntity({ id, body });
        if (error) {
            setState(prevState => ({
                ...prevState,
                errorMessage: error.data?.message || '',
                errors: error.data?.errors ?? {},
            }));
            return;
        }
        if (!data) return;
        navigate(`/legal-entities/${id}`);
    }

    const onDelete = async (e) => {
        e.preventDefault();
        const { data, error } = await deleteLegalEntity(id);
        if (error) {
            setState(prevState => ({
                ...prevState,
                isDeleteModalOpen: false,
                errorMessage: error.data?.message || '',
            }));
            return;
        }
        if (!data) return;
        navigate('/legal-entities');
    }


    return (
        <div>

            <Modal isOpen={isDeleteModalOpen} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Delete Legal Entity</ModalHeader>
                <ModalBody>
                    If you delete this legal entity, it will be permanently removed.
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={onDelete}>Yes, delete legal entity</Button>
                    <Button color="secondary" onClick={toggleDeleteModal}>No, return without deleting</Button>
                </ModalFooter>
            </Modal>

            <PageHeader title='Edit Entity' containerClass='container' className='pb-3'>
                <div>
                    <Button className="me-2" color='danger' onClick={toggleDeleteModal} disabled={!canDeleteLegalEntities}>Delete</Button>
                    <Button tag={Link} to={`/legal-entities/${id}`}>Back to detail</Button>
                </div>
            </PageHeader>
            <div className="container pb-3">
                <Form onSubmit={onUpdate}>
                    <Row className="justify-content-center">
                        <Col md='8'>
                            <Alert color="danger" isOpen={!!errorMessage}>
                                <h4 className="alert-heading">Error</h4>
                                <p className="mb-0">{errorMessage}</p>
                            </Alert>
                            <Card>
                                <CardHeader><h4>General Information</h4></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <Input type='text' value={name} name='name' onChange={onChange} invalid={!!errors.name} />
                                                {!!errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Tax Id</Label>
                                                <Input type='text' value={tax_id} name='tax_id' onChange={onChange} invalid={!!errors.tax_id} />
                                                {!!errors.tax_id && <FormFeedback>{errors.tax_id}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label>Administrative Load</Label>
                                                <Input type='range' min='0' max='100' step='1' value={administrative_load} name='administrative_load' id='administrative_load' onChange={onChange} invalid={!!errors.administrative_load} />
                                                {errors.administrative_load && <FormFeedback>{errors.administrative_load}</FormFeedback>}
                                                <Tooltip isOpen={isTooltipOpen} toggle={toggleTooltip} target='administrative_load'>{administrative_load}%</Tooltip>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <div className="text-end">
                                <Button className='mt-4 mb-4' color='primary' type='submit' disabled={isLoading}>
                                    {isLoading ? <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span> Saving</> : 'Save'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );

}

export default EditLegalEntity;