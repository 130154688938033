import { zigattaApi } from "./zigattaApi";

export const authApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'users/login',
                method: 'POST',
                body: credentials
            })
        }),
        maintenanceMode: builder.query({
            query: () => '/settings/maintenance-mode'
        }),
        getVersion: builder.query({
            query: () => '/system-versions/last'
        }),
        getInviteByToken: builder.query({
            query: (token) => `invites/${token}`,
            transformResponse: response => response.user,
        }),
        completeProfile: builder.mutation({
            query: ({ token, body }) => ({
                url: `invites/${token}/accept`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
        }),
        getResetPasswordEmailByToken: builder.query({
            query: (token) => `password/find/${token}`,
            transformResponse: response => response.email,
        }),
        resetPassword: builder.mutation({
            query: (body = {}) => ({
                url: `password/reset`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
        }),
        forgotPassword: builder.mutation({
            query: (body = {}) => ({
                url: `password/create`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
        }),
        initMaintenanceModeChannel: builder.query({
            query: () => `channel/maintenance-mode`,
        }),
    }),
});

export const {
    useLoginMutation,
    useLazyMaintenanceModeQuery,
    useMaintenanceModeQuery,
    useGetVersionQuery,
    useGetInviteByTokenQuery,
    useCompleteProfileMutation,
    useGetResetPasswordEmailByTokenQuery,
    useResetPasswordMutation,
    useForgotPasswordMutation,
    useInitMaintenanceModeChannelQuery,
    useLazyInitMaintenanceModeChannelQuery,
} = authApi;

