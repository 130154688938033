import { createSlice } from "@reduxjs/toolkit";
import { saveState, loadState } from "localStorage";

const initialState = {
    maintenance_mode: {
        maintenance_mode: 0,
        is_super_admin: false,
    },
    darkModeOn: loadState('darkMode'),
    isSidebarExpanded: loadState('sidebar') ?? true,
    sidebarCollapsed: loadState('sidebarCollapsed') || null,
    isPageReloadModalOpen: false,
    globalAlert: {
        isOpen: false,
        message: '',
        status: '',
        color: 'danger',
    },
};

// Config slice
export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        updateMaintenance: (state, action) => {
            const { maintenance_mode } = action.payload;
            state.maintenance_mode = maintenance_mode;
        },
        activateDarkMode: (state, action) => {
            state.darkModeOn = action.payload;
            saveState(action.payload, 'darkMode');
        },
        openSearchModal: (state, action) => {
            state.isOpenSearchModal = action.payload;
        },
        expandSidebar: (state, action) => {
            state.isSidebarExpanded = action.payload;
            saveState(action.payload, 'sidebar');
        },
        setSidebarCollapsed: (state, action) => {
            let collapsed = action.payload !== state.sidebarCollapsed ? action.payload : null;
            state.sidebarCollapsed = collapsed;
            saveState(collapsed, 'sidebarCollapsed');
        },
        openPageReloadModal: (state, action) => {
            state.isPageReloadModalOpen = action.payload;
        },
        openGlobalAlert: (state, action) => {
            state.globalAlert = {
                ...state.globalAlert,
                ...action.payload,
                isOpen: true,
            };
        },
        closeGlobalAlert: (state, action) => {
            state.globalAlert = {
                isOpen: false,
                message: '',
                status: '',
                color: 'danger',
            };
        },
    }
});

// Export actions
export const {
    updateMaintenance,
    activateDarkMode,
    openSearchModal,
    expandSidebar,
    openPageReloadModal,
    setSidebarCollapsed,
    openGlobalAlert,
    closeGlobalAlert,
} = userSlice.actions;

// Select state user from slice
export const selectMaintenanceMode = state => state.app.maintenance_mode;

export const selectDarkMode = state => state.app.darkModeOn;

export const selectOpenSearchModal = state => state.app.isOpenSearchModal;

export const selectSidebarExpanded = state => state.app.isSidebarExpanded;

export const selectSidebarCollapsed = state => state.app.sidebarCollapsed;

export const selectPageReloadModal = state => state.app.isPageReloadModalOpen;

export const selectGlobalAlert = state => state.app.globalAlert;

// Export reducer
export default userSlice.reducer;
