/**
 * Method to append to the DOM an a tag element in order to simulate a click
 * to download a file from server
 * @param {*} blob file response from server
 * @param {*} string string indicating the filename and extension
 */
export const generateDirectDownload = ({response, fileName = ''}) => {
    if(!response) return;
    var hiddenElement = document.createElement('a');
    var url = window.URL || window.webkitURL;
    var blobFile = url.createObjectURL(response);
    hiddenElement.href = blobFile;
    hiddenElement.target = '_blank';
    hiddenElement.download = `${fileName}`;
    hiddenElement.click();
}

export default generateDirectDownload;