import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Alert,
} from 'reactstrap';
import {
    isAllSectionNotificationsChecked,
    isNotificationChecked,
    addOrRemoveSectionNotifications,
    addOrRemoveNotification,
} from 'helpers';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';

const Notifications = (props) => {
    const { availableNotifications, selectedNotifications, isEditNotificationsOpen, isDataLoading, setNotifications } = props;

    const handleCheckAllSection = ({ target: { checked } }, notifications) => {
        const newSelectedNotifications = addOrRemoveSectionNotifications(notifications, selectedNotifications, checked);
        setNotifications(newSelectedNotifications);
    }

    const handleCheckNotification = ({ target: { checked, dataset } }) => {
        const parsedId = parseInt(dataset.id);
        const newSelectedNotifications = addOrRemoveNotification(parsedId, selectedNotifications, checked);
        setNotifications(newSelectedNotifications);
    }

    return (
        <>
            <Row>
                <Col md={12} > <LoadingIndicator isLoading={isDataLoading} /> </Col>
                {
                    !availableNotifications.length && !isDataLoading &&
                    <Col md={12}>
                        <Alert className='mb-0' color='info'>
                            <p className="m-0">No available notifications</p>
                        </Alert>
                    </Col>
                }
                {
                    !isDataLoading && availableNotifications.map(section => (
                        <Col key={`section-${section.section}`} className='pb-3' md={4}>
                            <Card className='h-100'>
                                <CardHeader className='d-flex align-items-center justify-content-between' >
                                    <b>{section.section}</b>
                                    <div>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                                checked={isAllSectionNotificationsChecked(section.notifications, selectedNotifications)}
                                                onChange={(e) => handleCheckAllSection(e, section.notifications)}
                                                disabled={!isEditNotificationsOpen}
                                            />
                                        </FormGroup>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {
                                        section.notifications.map(notification => (
                                            <div key={`notification-${notification.id}`} className='d-flex align-items-center justify-content-between'>
                                                <p className="m-0">{notification.title}</p>
                                                <div>
                                                    <FormGroup check>
                                                        <Input
                                                            type='checkbox'
                                                            checked={isNotificationChecked(notification.id, selectedNotifications)}
                                                            data-id={notification.id}
                                                            onChange={handleCheckNotification}
                                                            disabled={!isEditNotificationsOpen}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
        </>
    );
}

Notifications.defaultProps = {
    availableNotifications: [],
    selectedNotifications: [], // Array of numbers (ids)
    isEditNotificationsOpen: false,
    isDataLoading: false,
};

Notifications.propTypes = {
    availableNotifications: PropTypes.array,
    selectedNotifications: PropTypes.array,
    isEditNotificationsOpen: PropTypes.bool,
    isDataLoading: PropTypes.bool,
    setNotifications: PropTypes.func,
};

export default Notifications;