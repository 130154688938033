import React from 'react';
import { Media } from 'reactstrap';
import forbiddenImage from './403-forbidden.svg';
import './Forbidden.css';

function Forbidden() {
    return (
        <>
            <div id='forbidden-container'>
                <div className='box'>
                    <div className='text-container'>
                        <h1 className='mb-0'>
                            403
                        </h1>
                        <p>
                            Your user account does not have enough permissions to perform this action.
                        </p>
                    </div>
                    <div className='image-container'>
                        <Media src={forbiddenImage} fluid='fluid' />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Forbidden;
