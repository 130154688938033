import React from 'react';
import GlobalAlert from 'Components/GlobalAlert/GlobalAlert';

const PageHeader = ({
    containerClass = 'container-fluid',
    title = '',
    className = '',
    body,
    children,
}) => {
    return (
        <div>
            <div className={`page-title ${className}`}>
                <div className={`${containerClass} d-flex align-items-center justify-content-between`}>
                    <h1>{title}</h1>
                    {body ?? children}
                </div>
            </div>
            <GlobalAlert />
        </div>
    );
};

export default PageHeader;