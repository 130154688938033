import React from 'react';
import { useOutletContext } from 'react-router-dom';
import UsersList from 'Components/UsersList/UsersList';

const SeniorityTitleUsers = () => {
    const { usersProps } = useOutletContext();

    return (
        <UsersList
            users={usersProps?.users}
            notFoundText='No users found for this seniority title.'
        />
    );
}

export default SeniorityTitleUsers;