const minToTime = (mins) => {
  var result = '';
  var remaining = mins;
  if (remaining >= 60) {
    result += Math.floor(remaining / 60);
    result += 'h';
    remaining -= Math.floor(remaining / 60) * 60;
  }

  if (remaining > 0) {
    result += ' ' + remaining + 'm';
  }

  return result;
}

export default minToTime;