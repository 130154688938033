import React, { Fragment, useEffect, useRef } from 'react';
import { useTable, useSortBy, useFilters, useExpanded, usePagination, useGlobalFilter, } from 'react-table';
import { Table, Alert } from 'reactstrap';
import { usePromiseTracker } from "react-promise-tracker";
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import TablePagination from 'Components/TablePagination/TablePagination';
import classes from './TableWithCustomPagination.module.css';

const dummyClick = () => { };
const TableWithCustomPagination = ({
    columns,
    data,
    notFoundText,
    searchWord = '',
    rowClick = dummyClick,
    handlePageChange,
    handlePageSizeChange,
    queryPageSize = 10,
    queryPageIndex = 0,
    totalCount = 10,
    isLoading,
    className = '',
    borderless,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        visibleColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: queryPageIndex, pageSize: queryPageSize },
            manualPagination: true,
            pageCount: Math.ceil(totalCount / queryPageSize),
            autoResetGlobalFilter: false,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination
    );
    const { promiseInProgress } = usePromiseTracker();
    const firstRender = useRef(true);
    const firstSecondRender = useRef(true);

    const generateSortingIndicator = (column) => column.isSorted ? (column.isSortedDesc ? <FiChevronDown /> : <FiChevronUp />) : '';

    const onChangeInSelect = ({ target: { value } }) => setPageSize(Number(value));

    const onChangeInInput = ({ target: { value } }) => {
        const page = value ? Number(value) - 1 : 0;
        gotoPage(page);
    };

    useEffect(() => {
        setGlobalFilter(searchWord);
    }, [searchWord, setGlobalFilter]);

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        if (handlePageChange) handlePageChange(pageIndex);
    }, [pageIndex]);

    useEffect(() => {
        if (firstSecondRender.current) {
            firstSecondRender.current = false;
            return;
        }

        if (handlePageSizeChange) handlePageSizeChange(pageSize);
    }, [pageSize]);

    return (
        <Fragment>
            <Table className={`${classes['bottomless-table']} ${className}`} borderless={borderless} {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} className='align-middle'>
                                    <div {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                        {generateSortingIndicator(column)}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {promiseInProgress || isLoading ? <td colSpan={visibleColumns.length}><LoadingIndicator isLoading={isLoading} /> </td> :
                        page.length > 0 ? page.map((row) => {
                            prepareRow(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr>
                                        {row.cells.map((cell) => {
                                            return (
                                                <td {...cell.getCellProps()} onClick={e => rowClick({ e, row, cell })}>{cell.render('Cell')}</td>
                                            );
                                        })}
                                    </tr>
                                </Fragment>
                            );
                        }) : (
                            <td colSpan={visibleColumns.length}>
                                <Alert color='info' >
                                    <p className="m-0 text-center">
                                        {notFoundText}
                                    </p>
                                </Alert>
                            </td>
                        )
                    }
                </tbody>
            </Table>
            <TablePagination
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                handleNextPage={nextPage}
                handlePreviousPage={previousPage}
                handleFirstPage={() => gotoPage(0)}
                handleLastPage={() => gotoPage(pageCount - 1)}
                handleChangeInSelect={onChangeInSelect}
                handleChangeInput={onChangeInInput}
                pageOptions={pageOptions}
                pageIndex={pageIndex}
                pageSize={pageSize}
            />
        </Fragment>
    );
};

export default TableWithCustomPagination;