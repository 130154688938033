import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import HolidaysForm from 'pages/Holidays/HolidaysForm/HolidaysForm';
import { useGetHolidayByIdQuery, useUpdateHolidayMutation } from 'services/holidays';
import { formatStringToDate, decodeErrors } from 'helpers';

const EditHoliday = () => {
    const { id } = useParams();
    const [holiday, setHoliday] = useState({});
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { data: dataHoliday, isFetching } = useGetHolidayByIdQuery(id);
    const [updateMutation, { isLoading }] = useUpdateHolidayMutation();

    useEffect(() => {
        if (!dataHoliday) return;
        let date = formatStringToDate(dataHoliday?.date);
        setHoliday({ ...dataHoliday, date });
    }, [dataHoliday]);

    const onHolidayFormChange = ({ name, value }) => setHoliday(prevData => ({ ...prevData, [name]: value, }));

    const onUpdateHoliday = async () => {
        const body = { ...holiday };
        if (body.date) body.date = format(body.date, 'yyyy-MM-dd');
        const { data, error } = await updateMutation({ id, body });
        if (error) {
            setErrors(error?.data?.errors ?? decodeErrors(error?.data?.data ?? []));
            return;
        }
        if (!data) return;
        navigate(`/holidays/${id}`);
    };

    return (
        <div className="py-3">
            <PageHeader className='pb-3' title='Edit Holiday'>
                <Button
                    color='primary'
                    size='sm'
                    tag={Link}
                    to={`/holidays/${id}`}
                >
                    Back to detail
                </Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardBody>
                        <LoadingIndicator isLoading={isFetching} />
                        <HolidaysForm
                            data={holiday}
                            errors={errors}
                            handleChange={onHolidayFormChange}
                        />
                        <div className="text-end">
                            <Button color='secondary' onClick={onUpdateHoliday} disabled={isLoading}>
                                {isLoading ?
                                    <>
                                        <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                        Saving...
                                    </>
                                    :
                                    'Save'
                                }
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default EditHoliday;