import { zigattaApi } from './zigattaApi';

const BASE_ENDPOINT = 'holidays';

export const holidaysApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getHolidays: builder.query({
            query: (params = {}) => ({
                url: BASE_ENDPOINT,
                method: 'GET',
                params,
            }),
            transformResponse: response => response.data,
            providesTags: ['Holidays', {type: 'Holidays', id: 'LIST'}],
        }),
        getHolidayById: builder.query({
            query: (id) => `${BASE_ENDPOINT}/${id}`,
            transformResponse: response => response.data,
            providesTags: ['Holidays'],
        }),
        createHoliday: builder.mutation({
            query: (body = {}) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body
            }),
            invalidatesTags: ['Holidays'],
        }),
        updateHoliday: builder.mutation({
            query: ({ id, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${id}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['Holidays'],
        }),
        deleteHolday: builder.mutation({
            query: (id) => ({
                url: `${BASE_ENDPOINT}/${id}`,
                method: 'DELETE',
                headers: { 'Accept': 'application/json' },
            }),
            invalidatesTags: [{type: 'Holidays', id: 'LIST'}],
        }),
    }),
});

export const {
    useGetHolidaysQuery,
    useGetHolidayByIdQuery,
    useCreateHolidayMutation,
    useUpdateHolidayMutation,
    useDeleteHoldayMutation,
} = holidaysApi;