import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Alert,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  Button,
  Badge,
} from 'reactstrap';
import { FiSearch } from 'react-icons/fi';
import { FaUserTag } from 'react-icons/fa';
import { MdNotifications } from 'react-icons/md';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import PageHeader from 'Components/PageHeader/PageHeader';
import { checkPermission } from 'helpers';
import { useGetDeparmentsQuery } from 'services/departments';

const canCreateDepartments = checkPermission('CREATE_DEPARTMENTS');

const ListDepartments = () => {
  const [filter, setFilter] = useState('');
  const { data: departments = [], isLoading } = useGetDeparmentsQuery();

  const filterDepartment = department => {
    if (filter) {
      if (!department.name.toLowerCase().includes(filter.toLowerCase())) {
        return false;
      }
    }
    return true;
  }

  const clearFilters = () => setFilter('');
  const handleNameFilterChange = e => setFilter(e.target.value);

  return (
    <div>
      <PageHeader title='Departments' className='pb-3' containerClass='container'>
        <Button tag={Link} to='create' color='primary' disabled={!canCreateDepartments}>Add new</Button>
      </PageHeader>
      <div className="container pb-3">
        <Row>
          <Col md="8">
            <LoadingIndicator isLoading={isLoading} />
            {
              departments.length > 0 ?
                <ListGroup>
                  {departments.filter(filterDepartment).map(department =>
                    <ListGroupItem key={`department-${department.id}`} tag={Link} to={`/departments/${department.id}`}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6 className="mb-0 link-primary">{department.name}</h6>
                        </div>
                        <div>
                          <Badge
                            className='me-2'
                            color='primary'
                            pill
                          >
                            <FaUserTag /> {department.users_count}
                          </Badge>
                          <Badge
                            color='info'
                            pill
                          >
                            <MdNotifications /> {department.notifications_count}
                          </Badge>
                        </div>
                      </div>
                    </ListGroupItem>
                  )}
                </ListGroup>
                :
                <Alert color="warning" className="mb-0">
                  <small>No departments have been created.</small>
                </Alert>
            }
          </Col>
          <Col md="4">
            <Card>
              <CardHeader className='d-flex justify-content-between align-items-center'>
                <h4><FiSearch className='me-2' /> Filter</h4>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>Name</Label>
                  <Input type='text' onChange={handleNameFilterChange} value={filter} />
                </FormGroup>
                <Button block color='secondary' type='button' disabled={!filter} onClick={clearFilters}>Reset</Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ListDepartments;