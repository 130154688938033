import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'legal-entities';

export const legalEntitiesApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getLegalEntities: builder.query({
            query: () => BASE_ENDPOINT,
            transformResponse: response => response.data,
            providesTags: ['LegalEntities', { type: 'LegalEntities', id: 'LIST' }],
        }),
        getLegalEntityById: builder.query({
            query: (id) => `${BASE_ENDPOINT}/${id}`,
            transformResponse: response => response.data,
            providesTags: ['LegalEntities'],
        }),
        createLegalEntity: builder.mutation({
            query: (body) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['LegalEntities'],
        }),
        updateLegalEntity: builder.mutation({
            query: ({ id, body }) => ({
                url: `${BASE_ENDPOINT}/${id}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['LegalEntities'],
        }),
        deleteLegalEntity: builder.mutation({
            query: (id) => ({
                url: `${BASE_ENDPOINT}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'LegalEntities', id: 'LIST' }],
        }),
    }),
});

export const {
    useGetLegalEntitiesQuery,
    useLazyGetLegalEntitiesQuery,
    useGetLegalEntityByIdQuery,
    useLazyGetLegalEntityByIdQuery,
    useCreateLegalEntityMutation,
    useUpdateLegalEntityMutation,
    useDeleteLegalEntityMutation,
} = legalEntitiesApi;