import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Container, Card, CardBody, FormGroup, Label, Row, Col, Input, Button, FormFeedback, Alert, Media, } from 'reactstrap';
import DatePicker from "react-datepicker";
import { selectLoggedIn, } from "store/slices/authSlice";
import { useGetInviteByTokenQuery, useCompleteProfileMutation } from 'services/auth';
import { getFormData, decodeErrors } from 'helpers';
import './Onboard.css';

const Onboard = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [user, setUser] = useState({
        display_name: '',
        date_of_birth: '',
        avatar: null,
        avatar_url: null,
        require_password: 0,
        password: '',
        password_confirm: '',
    });
    const [errors, setErrors] = useState({});
    const [inviteNotFound, setInviteNotFound] = useState(false);
    const isLoggedIn = useSelector(selectLoggedIn);
    if (isLoggedIn) navigate('/');
    const { data, error, isFetching } = useGetInviteByTokenQuery(token);
    const [completeProfile, { isLoading: isSavingData }] = useCompleteProfileMutation();

    useEffect(() => {
        if (!data || error) {
            setInviteNotFound(true);
            return;
        }
        setInviteNotFound(false);
        const display_name = data.name?.split(' ')[0];
        setUser({
            ...data,
            date_of_birth: '',
            display_name,
            avatar_url: data?.avatar_url ?? null,
            avatar: null,
        });
    }, [data, error]);

    const handleDisplayNameChange = ({ target: { value } }) => setUser(prevData => ({ ...prevData, display_name: value, }));
    const handleDateOfBirthChange = (date = '') => setUser(prevData => ({ ...prevData, date_of_birth: date, }));
    const handleAvatarChange = ({ target: { files } }) => setUser(prevData => ({ ...prevData, avatar: files[0], }));
    const handlePasswordChange = ({ target: { value, name } }) => setUser(prevData => ({ ...prevData, [name]: value, }));

    const onCompleteAccount = async (e) => {
        e.preventDefault();
        const { avatar, date_of_birth, display_name, password, password_confirm } = user;
        let body = { avatar, date_of_birth, display_name };
        if (!!user.require_password) body = { ...body, password, password_confirm };
        const formData = getFormData(body);
        const { data, error } = await completeProfile({ token, body: formData });
        if (error) {
            setErrors(error.data?.errors ?? decodeErrors(error.data?.data ?? []));
            return;
        }
        if (!data) return;
        navigate('/');
    }

    return (
        <div className='onboard'>
            <Container className='h-100'>
                <Row className="justify-content-center align-items-center h-100">
                    <Col xl={5} lg={6} md={8}>
                        <Card className='my-3'>
                            <div className='p-2 text-center'>
                                <img src={'/z-100x100.svg'} width={100} alt='z-logo' />
                            </div>
                            <CardBody>
                                {!isFetching && inviteNotFound ?
                                    <>
                                        <h1 className='text-center'>Oops...</h1>
                                        <p className="m-0">This invite is no longer valid, please contact our system administrator to request a new invite.</p>
                                        <div className='d-flex justify-content-center'><Button className='mt-2' tag={Link} to='/login'>Back to login</Button></div>
                                    </>
                                    :
                                    <>
                                        <h1 className='text-center'>Welcome!</h1>
                                        <p className='m-0 pb-2'>Please fill the following information to complete your account.</p>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Full Name</Label>
                                                    <p className="m-0">{user.name ?? '-'}</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Department</Label>
                                                    <p className="m-0">{user.department_name ?? '-'}</p>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Display Name</Label>
                                                    <Input
                                                        type='text'
                                                        onChange={handleDisplayNameChange}
                                                        value={user.display_name}
                                                        invalid={!!errors.display_name}
                                                        disabled={isSavingData}
                                                    />
                                                    {!!errors.display_name && <FormFeedback>{errors.display_name}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Date of birth</Label>
                                                    <DatePicker
                                                        customInput={<Input type='text' invalid={!!errors.date_of_birth} />}
                                                        selected={user.date_of_birth}
                                                        onChange={handleDateOfBirthChange}
                                                        dateFormat="MMMM dd, yyyy"
                                                        dropdownMode='select'
                                                        disabled={isSavingData}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                    />
                                                    {!!errors.date_of_birth && <FormFeedback className="d-block">{errors.date_of_birth}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {!!user.require_password &&
                                            <>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Password</Label>
                                                            <Input
                                                                type='password'
                                                                name='password'
                                                                value={user.password}
                                                                onChange={handlePasswordChange}
                                                                invalid={!!errors.password}
                                                            />
                                                            {!!errors.password && <FormFeedback className="d-block">{errors.password}</FormFeedback>}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>Confirm Password</Label>
                                                            <Input
                                                                type='password'
                                                                name='password_confirm'
                                                                value={user.password_confirm}
                                                                onChange={handlePasswordChange}
                                                                invalid={!!errors.password_confirm}
                                                            />
                                                            {!!errors.password_confirm && <FormFeedback className="d-block">{errors.password_confirm}</FormFeedback>}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {
                                            !!user.avatar_url &&
                                            <Row className='d-flex align-items-center'>
                                                <Col md={6}>
                                                    <FormGroup className='d-flex justify-content-center'>
                                                        <Media
                                                            className='rounded-circle w-50'
                                                            src={user.avatar_url}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <Alert color='info'>
                                                        <p className='m-0 text-justify'>
                                                            <b>This is your current avatar, updloading a new image will replace it.</b>
                                                        </p>
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>Avatar</Label>
                                                    <Input
                                                        type='file'
                                                        onChange={handleAvatarChange}
                                                        accept='image/png, image/gif, image/jpeg'
                                                        invalid={!!errors.avatar}
                                                        disabled={isSavingData}
                                                    />
                                                    {!!errors.avatar && <FormFeedback className="d-block">{errors.avatar}</FormFeedback>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Alert color='info'>
                                                    <p className='m-0 text-justify'>
                                                        <b>
                                                            {!!user.require_password ?
                                                                `Remember, to sign in you must use the password auth method with the following email ${user.email}.`
                                                                : 'Remember, to sign in you must use the google authentication method with your zigatta account.'
                                                            }
                                                        </b>
                                                    </p>
                                                </Alert>
                                            </Col>
                                        </Row>
                                        <Button
                                            color='primary'
                                            type="submit"
                                            onClick={onCompleteAccount}
                                            disabled={isSavingData}
                                            block
                                        >
                                            {isSavingData && <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />}
                                            Complete my account
                                        </Button>
                                    </>
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Onboard;