import React from 'react';
import { Input, Button } from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import { statusColor } from 'pages/Jobdiva/helpers';
import { formatToAbbreviation } from 'helpers';

const PendingDetailRow = ({
    syncStatus = '',
    colSpan = 7,
    detail = {},
    index,
    handleCheck,
    handleEditUserClick,
    handleAllocationEditClick,
}) => {
    const onChecked = (e, allocationIndex) => {
        handleCheck(e, index, allocationIndex);
    }

    const onEditUserClick = (e) => {
        e.preventDefault();
        handleEditUserClick(index);
    }

    const onAllocationEditClick = (allocationIndex) => {
        handleAllocationEditClick(index, allocationIndex);
    }

    return (
        <>
            <thead>
                <tr>
                    <th
                        className='text-capitalize'
                        colSpan={colSpan}
                    >
                        {detail.name} - {detail.type} - {detail.concept}
                    </th>
                    <th
                        colSpan={2}
                    >
                        {
                            detail.type === 'new' &&
                            <Button
                                color='zigatta'
                                size='sm'
                                onClick={onEditUserClick}
                            >
                                <FiEdit />
                            </Button>
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    detail.data?.allocation?.map((allocation, idx) => {
                        const allocationStatusColor = statusColor(allocation.type?.toLowerCase());
                        const rateCardStatusColor = statusColor(allocation.rate_card?.type?.toLowerCase())
                        return (
                            <tr
                                key={`detail-${detail.id}-${idx}`}
                            >
                                <td className='align-middle'>
                                    {detail.id}
                                </td>
                                <td className='align-middle'>
                                    Allocation: {allocation.type} <br />
                                    Rate card: {allocation.rate_card?.type}
                                </td>
                                <td className='align-middle'>
                                    <p className={`m-0 ${allocationStatusColor}`}>
                                        Allocation: {allocation.concept ?? '-'}
                                    </p>
                                    <p className={`m-0 ${rateCardStatusColor}`}>
                                        Rate card: {allocation.rate_card?.concept ?? '-'}
                                    </p>
                                </td>
                                <td className='align-middle'>
                                    {allocation.jobdiva_company ?? ''}
                                </td>
                                <td className='align-middle'>
                                    {
                                        allocation.type?.toLowerCase() === 'update'
                                        &&
                                        <p className="m-0">Incoming:</p>
                                    }
                                    <p className={`m-0 ${allocationStatusColor}`}>
                                        { formatToAbbreviation(allocation.jobdiva_start_date) } - { formatToAbbreviation(allocation.jobdiva_end_date) }
                                    </p>
                                    {
                                        allocation.type?.toLowerCase() === 'update'
                                        &&
                                        <>
                                            <p className="m-0">Current:</p>
                                            <p className='m-0 text-muted'>
                                            { formatToAbbreviation(allocation.start_date) } - { formatToAbbreviation(allocation.end_date) }
                                            </p>
                                        </>
                                    }
                                </td>
                                <td className='align-middle'>
                                    {
                                        allocation.rate_card?.type?.toLowerCase() === 'update'
                                        &&
                                        <p className="m-0">
                                            Incoming:
                                        </p>
                                    }
                                    <p className={`m-0 ${rateCardStatusColor}`}>
                                        {allocation.rate_card.jobdiva_pay_rate ?? '-'}
                                    </p>
                                    {
                                        allocation.rate_card?.type?.toLowerCase() === 'update'
                                        &&
                                        <>
                                            <p className="m-0">Current:</p>
                                            <p className="m-0 text-muted">
                                                {allocation.rate_card?.pay_rate ?? '-'}
                                            </p>
                                        </>
                                    }
                                </td>
                                <td className='align-middle'>
                                    {
                                        allocation.rate_card?.type?.toLowerCase() === 'update'
                                        &&
                                        <p className="m-0">
                                            Incoming:
                                        </p>
                                    }
                                    <p className={`m-0 ${rateCardStatusColor}`}>
                                        {allocation.rate_card.jobdiva_bill_rate ?? '-'}
                                    </p>
                                    {
                                        allocation.rate_card?.type?.toLowerCase() === 'update'
                                        &&
                                        <>
                                            <p className="m-0">Current:</p>
                                            <p className="m-0 text-muted">
                                                {allocation.rate_card?.bill_rate ?? '-'}
                                            </p>
                                        </>
                                    }
                                </td>
                                <td className='align-middle'>
                                    <Button
                                        className=''
                                        color='primary'
                                        size='sm'
                                        onClick={e => onAllocationEditClick(idx)}
                                        disabled={
                                            ((allocation.type?.toLowerCase() !== 'new'
                                                && allocation.type?.toLowerCase() !== 'update')
                                                &&
                                                (allocation.rate_card.type?.toLowerCase() !== 'new'
                                                    && allocation.rate_card.type?.toLowerCase() !== 'update'))
                                        }
                                    >
                                        <FiEdit />
                                    </Button>
                                </td>
                                <td className='align-middle'>
                                    <Input
                                        type='checkbox'
                                        checked={allocation.isChecked}
                                        onChange={(e) => onChecked(e, idx)}
                                    />
                                </td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </>
    );
}

export default PendingDetailRow;