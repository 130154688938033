import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import LoadingIndicatorText from 'Components/LoadingIndicatorText/LoadingIndicatorText';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import TimeOffRulesForm from 'pages/TimeOffRules/TimeOffRulesForm/TimeOffRulesForm';
import { useGetTimeOffRuleByIdQuery, useUpdateTimeOffRuleMutation } from 'services/timeOff';
import { decodeErrors } from 'helpers';

const EditTimeOffRule = () => {
    const { id } = useParams();
    const [rule, setRule] = useState({});
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { data: dataRule, isFetching } = useGetTimeOffRuleByIdQuery(id);
    const [updateMutation, { isLoading }] = useUpdateTimeOffRuleMutation();

    useEffect(() => {
        if (!dataRule) return;
        setRule({ ...dataRule });
    }, [dataRule]);

    const onRuleChange = ({ name, value }) => setRule(prevData => ({ ...prevData, [name]: value, }));

    const onUpdateRule = async () => {
        const body = { ...rule };
        const { data, error } = await updateMutation({ id, body });
        if (error) {
            setErrors(error?.data?.errors ?? decodeErrors(error?.data?.data ?? []));
            return;
        }
        if (!data) return;
        navigate(`/time-off-rules/${id}`);
    };

    return (
        <div className="py-3">
            <PageHeader className='pb-3' title='Edit Time Off Rule'>
                <Button
                    color='primary'
                    size='sm'
                    tag={Link}
                    to={`/time-off-rules/${id}`}
                >
                    Back to detail
                </Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardBody>
                        <LoadingIndicator isLoading={isFetching} />
                        <TimeOffRulesForm
                            data={rule}
                            errors={errors}
                            handleChange={onRuleChange}
                        />
                        <div className="text-end">
                            <Button color='secondary' onClick={onUpdateRule} disabled={isLoading}>
                                <LoadingIndicatorText
                                    isLoading={isLoading}
                                    loadingPlaceholder='Saving...'
                                    notLoadingPlaceholder='Save'
                                />
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default EditTimeOffRule;