import React from 'react';
import Switch from 'react-switch';
import { useDispatch, useSelector } from 'react-redux';
import { useDarkreader } from 'react-darkreader';
import { BsMoonFill, BsSunFill } from 'react-icons/bs';
import classNames from 'classnames';
import { activateDarkMode, selectDarkMode, selectSidebarExpanded } from 'store/slices/userSlice';
import './DarkModeToggle.css';
import { logEvents } from "constants/firebase";



const DarkModeToggle = () => {
    const isSidebarExpanded = useSelector(selectSidebarExpanded);
    const darkModeOn = useSelector(selectDarkMode);
    const [isDark, { toggle }] = useDarkreader(!!darkModeOn);
    const dispatch = useDispatch();

    const handleOnChange = () => {
        toggle();
        logEvents("darkmode_toogle");
        dispatch(dispatch(activateDarkMode(!isDark)));
    };

    return isSidebarExpanded ? (
        <div className='px-3 mt-3 ps-4'>
            <div className='mt-auto d-flex flex-column'>
                <span className='mb-2'>Choose mode</span>
                <Switch
                    onChange={handleOnChange}
                    checked={!!darkModeOn}
                    handleDiameter={28}
                    offColor="#E9EDFC"
                    onColor="#F7F7F7"
                    offHandleColor='#6049DA'
                    height={40}
                    width={90}
                    borderRadius={25}
                    uncheckedIcon={
                        <div className='toggle-icon unchecked-icon'>
                            <BsMoonFill />
                        </div>
                    }
                    checkedIcon={
                        <div className="toggle-icon checked-icon">
                            <BsSunFill />
                        </div>
                    }
                    uncheckedHandleIcon={
                        <div className={classNames('toggle-icon', { 'toggle-active-light': !isDark })}>
                            <BsSunFill />
                        </div>
                    }
                    checkedHandleIcon={
                        <div className={classNames('toggle-icon', { 'toggle-active': isDark })}>
                            <BsMoonFill />
                        </div>
                    }
                />
            </div>
        </div>
    ) : (
        <div className="mt-2 mb-2 d-flex toogle-dark-mode-collpased" onClick={handleOnChange}>
            <div className='toggle-icon'>
                {
                    isDark ?
                        <div className={classNames('toggle-icon', { 'toggle-active': !isDark })} data-bs-toggle="tooltip" title="Turn light mode">
                            <BsSunFill />
                        </div> :
                        <div className={classNames('toggle-icon', { 'toggle-active': !isDark })} data-bs-toggle="tooltip" title="Turn dark mode">
                            <BsMoonFill />
                        </div>
                }
            </div>
        </div>
    )
}

export default DarkModeToggle;