import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Row,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Card,
    CardHeader,
    CardBody,
    Button,
    Alert,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { createToast } from 'helpers';
import { useDownloadRateCardsTemplateMutation } from 'services/downloads';
import { useImportRolesRateCardsMutation } from 'services/rolesRateCards';
import { format } from 'date-fns';

const acceptsFileTypes = {
    'text/csv': [],
};

const initialState = {
    valid_from: null,
    valid_to: null,
    file: null,
    errors: {},
    errorMessage: '',
}

const ImportRateCards = () => {
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const [downloadTemplate, { isLoading: isDownloadingTemplate }] = useDownloadRateCardsTemplateMutation();
    const [uploadRolesRateCards, { isLoading: isImportingCsv }] = useImportRolesRateCardsMutation();

    // handlers

    const handleDateChange = (date, name) => setState(prevState => ({ ...prevState, [name]: date, }));

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length) {
            return createToast({ type: 'error', text: 'You can only import files with CSV format.' });
        }

        if (acceptedFiles.length && acceptedFiles.length > 1) {
            return createToast({ type: 'error', text: 'You can only import one file.' });
        }

        setState(prevState => ({
            ...prevState,
            file: acceptedFiles[0],
        }));
    }

    const handleImportClick = async (e) => {
        const formData = new FormData();

        if (state.valid_from) formData.append('valid_from', format(state.valid_from, 'yyyy-MM-dd'));
        if (state.valid_to) formData.append('valid_to', format(state.valid_to, 'yyyy-MM-dd'));
        if (state.file !== null) formData.append('csv_file', state.file);


        const { data, error } = await uploadRolesRateCards(formData);

        if (error) {
            setState(prevState => ({
                ...prevState,
                errors: error.data?.errors ?? {},
                errorMessage: error.data?.message || '',
            }));
            return;
        }

        if (!data) return;
        return navigate('/roles-rate-cards');
    }


    return (
        <div>
            <div className="header pb-3">
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <h1>Rate cards</h1>
                    <div>
                        <Button
                            className='me-2'
                            color='secondary'
                            size='sm'
                            tag={Link}
                            to='/roles-rate-cards'
                        >
                            Return to rate card history
                        </Button>
                        <Button
                            color='info-secondary'
                            size='sm'
                            onClick={downloadTemplate}
                            disabled={isDownloadingTemplate}
                        >
                            {isDownloadingTemplate ? <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" /> Downloading template</> : 'Download template'}
                        </Button>
                    </div>
                </div>
            </div>

            <div className="container-fluid pb-3">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader>
                                New rate card
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        <Alert isOpen={!!state.errorMessage} color='danger' style={{ maxHeight: '300px', overflow: 'auto' }}>
                                            <p className="m-0">
                                                {state.errorMessage}
                                            </p>
                                            {Object.keys(state.errors).length > 0 &&
                                                !state.errors.hasOwnProperty('valid_from') &&
                                                !state.errors.hasOwnProperty('csv_file') && !this.state.errors.hasOwnProperty('valid_to') &&
                                                <ul>
                                                    {Object.values(state.errors).map((error, idx) => {
                                                        return (
                                                            <li key={`error-csv-${idx}`}>
                                                                {error}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            }
                                        </Alert>
                                    </Col>
                                </Row>
                                <Form>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Valid from</Label>
                                                <DatePicker
                                                    customInput={<Input invalid={!!state.errors.valid_from} />}
                                                    selected={state.valid_from ?? null}
                                                    onChange={(date) => handleDateChange(date, 'valid_from')}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                                {!!state.errors.valid_from && <FormFeedback className='d-block'>{state.errors.valid_from}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Valid to (optional)</Label>
                                                <DatePicker
                                                    customInput={<Input invalid={!!state.errors.valid_to} />}
                                                    selected={state.valid_to ?? null}
                                                    onChange={(date) => handleDateChange(date, 'valid_to')}
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                                {!!state.errors.valid_to && <FormFeedback className='d-block'>{state.errors.valid_to}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>File</Label>
                                                <Dropzone
                                                    accept={acceptsFileTypes}
                                                    onDrop={onDrop}
                                                >
                                                    {({ getRootProps, getInputProps, isDragActive, acceptedFiles, }) => {
                                                        return (
                                                            <div
                                                                {...getRootProps({
                                                                    style: {
                                                                        borderColor: state.errors.csv_file ? 'red' : 'none',
                                                                    }
                                                                })}
                                                                className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                {
                                                                    state.file ?
                                                                        <p>{state.file.name} - {state.file.size} bytes</p> :
                                                                        isDragActive ?
                                                                            <p>Drop the rate card file...</p> :
                                                                            <p>Drag and drop, or click to select a file in CSV format</p>
                                                                }
                                                            </div>
                                                        );
                                                    }}
                                                </Dropzone>
                                                {!!state.errors.csv_file && <FormFeedback className='d-block'>{state.errors.csv_file}</FormFeedback>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='pt-3'>
                    <Col className='d-flex justify-content-end' md={12}>
                        <Button
                            color='primary'
                            size='sm'
                            onClick={handleImportClick}
                            disabled={isImportingCsv}
                        >
                            {isImportingCsv ? <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" /> Importing...</> : 'Import rate cards'}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ImportRateCards;