import React from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Button,
    Alert,
} from 'reactstrap';
import SwitchToggle from 'react-switch';
import SelectWithQuery from 'Components/SelectWithQuery/SelectWithQuery';
import { useGetExpenseCategoriesQuery } from 'services/expenses';
import { selectErrorStyles } from 'constants/common';

const ReimbursementCategoryForm = ({
    category = {},
    errors = {},
    errorMessage = '',
    headerText = '',
    buttonText = '',
    loadingButtonText = '',
    handleInput,
    handleSelect,
    handleSwitch,
    handleButtonClick,
    isLoading,
}) => {
    return(
        <>
        <Card>
            <CardHeader>{headerText} reimbursement request category</CardHeader>
            <CardBody>
                <Alert className='pb-3' isOpen={!!errorMessage} color='danger'>{errorMessage}</Alert>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Expense category</Label>
                            <SelectWithQuery
                                query={useGetExpenseCategoriesQuery}
                                selected={category?.expense_category_id}
                                placeholder='Select expense category'
                                name='expense_category_id'
                                styles={!!errors?.expense_category_id ? selectErrorStyles : {}}
                                handleChange={handleSelect}
                            />
                            { !!errors?.name && <FormFeedback className='d-block'>{errors?.expense_category_id}</FormFeedback> }
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input
                                type='text'
                                value={category?.name}
                                name='name'
                                placeholder='e.g. Holidays'
                                onChange={handleInput}
                                invalid={!!errors?.name}
                            />
                            { !!errors?.name && <FormFeedback className='d-block'>{errors?.name}</FormFeedback> }
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Status</Label>
                            <div className='d-flex align-items-center justify-content-start pt-2'>
                              <span className='me-2'>Inactive</span>
                              <SwitchToggle
                                onChange={ handleSwitch }
                                checked={ category?.display }
                                id='display'
                                offColor="#ff0000"
                              />
                              <span className='ms-2'>Active</span>
                            </div>
                            { !!errors?.display && <FormFeedback className='d-block'>{errors?.display}</FormFeedback> }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className='d-flex justify-content-end' md={12}>
                        <Button
                            color='primary'
                            size='sm'
                            onClick={handleButtonClick}
                            disabled={isLoading}
                        >
                            { isLoading ?
                            <>
                            <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"/>
                            {loadingButtonText}
                            </>
                            :
                            buttonText
                            }
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
        </>
    );
}

export default ReimbursementCategoryForm;