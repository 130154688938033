import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Button,
    Alert,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import DeleteSeniorityTitleModal from 'pages/SeniorityTitles/DeleteSeniorityTitleModal/DeleteSeniorityTitleModal';
import { useGetSeniorityTitleByIdQuery, useUpdateSeniorityTitleByIdMutation } from 'services/seniorities';
import { decodeErrors } from 'helpers';
import { canDeleteSeniorityTitles } from 'constants/common';

const UpdateSeniorityTitle = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [seniorityTitle, setSeniorityTitle] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [isDeleteSeniorityModalOpen, setIsDeleteSeniorityModalOpen] = useState(false);
    const { data: dataSeniority, isFetching } = useGetSeniorityTitleByIdQuery(id);
    const [updateMutation, { isLoading: isUpdating }] = useUpdateSeniorityTitleByIdMutation();

    useEffect(() => {
        if (!dataSeniority) return;
        setSeniorityTitle(dataSeniority);
    }, [dataSeniority]);

    const onNameChange = ({ target: { value } }) => setSeniorityTitle(prevData => ({ ...prevData, name: value, }));

    const toggleDeleteSeniority = () => setIsDeleteSeniorityModalOpen(!isDeleteSeniorityModalOpen);

    const onUpdate = async () => {
        const body = { ...seniorityTitle };
        const { data, error } = await updateMutation({ id, body });
        if (error) {
            setErrors(error.data?.errors ?? decodeErrors(error.data?.data ?? []));
            setErrorMessage(error.data?.message ?? error.data?.status?.msg ?? '')
            return;
        }
        if (!data) return;
        navigate(`/seniority-titles/${id}`);
    }

    return (
        <div>
            <PageHeader title='Edit Seniority Title' className='pb-3'>
                <div>
                    <Button className='me-2' color='danger' onClick={toggleDeleteSeniority} disabled={!canDeleteSeniorityTitles}>
                        Delete
                    </Button>
                    <Button tag={Link} to={`/seniority-titles/${id}`}>Back to detail</Button>
                </div>
            </PageHeader>
            <Row className='pb-3'>
                <LoadingIndicator isLoading={isFetching} />
                <Col md={12}>
                    <Alert color="danger" isOpen={!!errorMessage} toggle={() => setErrorMessage('')}>
                        <h4 className="alert-heading">Error</h4>
                        <p className="mb-0">{errorMessage}</p>
                    </Alert>
                    <Card>
                        <CardHeader>General Information</CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <Input type='text' value={seniorityTitle?.name} name='name' onChange={onNameChange} invalid={!!errors.name} />
                                        {!!errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <div className="text-end">
                        <Button className='mt-4 mb-4' color='primary' onClick={onUpdate}>
                            {isUpdating ? <> <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span> Updating</> : 'Update'}
                        </Button>
                    </div>
                </Col>
            </Row>
            <DeleteSeniorityTitleModal isOpen={isDeleteSeniorityModalOpen} toggle={toggleDeleteSeniority} />
        </div>
    );
}

export default UpdateSeniorityTitle;