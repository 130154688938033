import { zigattaApi } from './zigattaApi';

export const saleApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getSaleLevels: builder.query({
            query: () => `sale-levels`,
            transformResponse: response => response.data,
            providesTags: ['SaleLevels'],
        }),
    }),
});

export const {
    useGetSaleLevelsQuery,
} = saleApi;