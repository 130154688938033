import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import DarkModeToggle from "Components/DarkModeToggle/DarkModeToggle";
import SidebarNavItems from "Components/SidebarNavItems/SidebarNavItems";
import ChangelogButton from "Components/ChangelogButton/ChangelogButton";
import {
  selectDarkMode,
  selectSidebarExpanded,
  expandSidebar,
} from "store/slices/userSlice";
import useEventListener from "Hooks/useEventListener";
import { NAV_ITEMS, FOOTER_NAV_ITEMS } from "constants/headerPermissions";
import "./SideBar.css";

const SideBar = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectSidebarExpanded);
  const isDarkModeOn = useSelector(selectDarkMode);
  const size = isOpen ? 60 : 25;

  const keyDownHandler = async (e) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'b') dispatch(expandSidebar(!isOpen));
  }

  useEventListener('keydown', keyDownHandler);

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header d-flex align-items-center ">
        <img alt="logo" src="/images/logo.svg" width={size} />
        {isOpen && (
          <h2
            className={classNames("ms-4 mt-3", {
              "imagotype-light": !isDarkModeOn,
            })}
          >
            Zetta
          </h2>
        )}
        <div
          className={classNames("collapse-button", { "is-open": isOpen })}
          onClick={() => dispatch(expandSidebar(!isOpen))}
        >
          {isOpen ? <HiOutlineChevronLeft /> : <HiOutlineChevronRight />}
        </div>
      </div>

      <div
        className={classNames("side-menu", { "align-items-center": !isOpen })}
      >
        <div className={classNames("main-nav", { "is-open": isOpen })}>
          <SidebarNavItems
            navItems={NAV_ITEMS}
            className="pb-2  main-nav-items"
            vertical
            sidebBarOpen={isOpen}
          />
        </div>
        <div>
          <ChangelogButton />
          <SidebarNavItems
            navItems={FOOTER_NAV_ITEMS}
            vertical
            className="ps-2 footer-nav"
            sidebBarOpen={isOpen}
          />
          <div className={classNames("pb-2 darkToggler", { open: isOpen })}>
            <DarkModeToggle />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
