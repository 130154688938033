import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import DeleteHolidayModal from 'pages/Holidays/ShowHoliday/DeleteHolidayModal';
import { useGetHolidayByIdQuery } from 'services/holidays';
import { canDeleteHolidays } from 'constants/common';

const COLUMNS = [
    {
        colSpan: 6,
        label: 'Name',
        attribute: 'name',
    },
    {
        colSpan: 6,
        label: 'Legal Entity',
        attribute: 'legal_entity_name',
    },
    {
        colSpan: 6,
        label: 'Date',
        attribute: 'formatted_date',
    },
];

const ShowHoliday = () => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { id } = useParams();
    const { data: holiday = {}, isFetching } = useGetHolidayByIdQuery(id);

    const toggleDeleteHoliday = () => setIsDeleteModalOpen(!isDeleteModalOpen);

    return (
        <div className="py-3">
            <PageHeader className='pb-3' title='Holiday Detail'>
                <div>
                    <Button
                        className='me-2'
                        color='danger'
                        size='sm'
                        onClick={toggleDeleteHoliday}
                        disabled={!canDeleteHolidays}
                    >
                        Delete
                    </Button>
                    <Button
                        className='me-2'
                        color='warning'
                        size='sm'
                        tag={Link}
                        to='edit'
                    >
                        Edit
                    </Button>
                    <Button color='primary' size='sm' tag={Link} to='/holidays'>Back to list</Button>
                </div>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardBody>
                        <LoadingIndicator isLoading={isFetching} />
                        <Row>
                            {COLUMNS.map(({ colSpan, label, attribute }, idx) => {
                                return (
                                    <Col key={`col-${idx}`} md={colSpan ?? 12}>
                                        <FormGroup>
                                            <Label>{label ?? '-'}</Label>
                                            <p className="m-0">{holiday[attribute] ?? '-'}</p>
                                        </FormGroup>
                                    </Col>
                                );
                            })}
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <DeleteHolidayModal
                isOpen={isDeleteModalOpen}
                handleToggle={toggleDeleteHoliday}
            />
        </div>
    );
}

export default ShowHoliday;