import React from "react";
import notFoundLight from "./404error-light.svg";

function NotFound() {
  return (
    <div className="not-found-container">
      <div className="not-found">
        <div className="pageNotFound">
          <h1>404</h1>
          <img src={notFoundLight} alt="Not Found" />
        </div>
        <h2>Oops! Page not found</h2>
        <p>
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </p>
        <a href="/">Home</a>
      </div>
    </div>
  );
}

export default NotFound;
