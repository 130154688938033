import { trackPromise } from 'react-promise-tracker';
import Utils from 'Utils';

export const loginApi = async (data) => {
    return await Utils.api(`users/login`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export const getRoles = async () => {
    try {
        const { roles } = await trackPromise(Utils.api('roles'));
        return roles
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const getUsers = async () => {
    try {
        const { users } = await trackPromise(Utils.api('users'));
        return users;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const getCurrencies = async () => {
    try {
        const { currencies } = await trackPromise(Utils.api(`currencies`));
        return currencies;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getProjects = async () => {
    try {
        const { projects } = await trackPromise(Utils.api(`projects?current_projects=${true}`));
        return projects;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const getProjectById = async (projectId) => {
    try {
        const { project = {}, effort = {} } = await trackPromise(Utils.api(`projects/${projectId}`));
        return { project, effort };
    } catch (error) {
        console.error('Error:', error);
        return { project: {}, effort: {} };
    }
}

export const getAllocations = async (startDate) => {
    try {
        const { allocations } = await trackPromise(Utils.api(`allocations?start_date=${startDate.format('YYYY-MM-DD')}`));
        return allocations;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const search = async (search) => {
    try {
        const { data } = await trackPromise(Utils.api('browser', { params: { search } }));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return {};
    }
};

export const getTeams = async () => {
    try {
        const { data } = await trackPromise(Utils.api('teams'));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const getTeamById = async (id) => {
    try {
        const { data } = await trackPromise(Utils.api(`teams/${id}`));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
};

export const getMilestone = async (projectId, milestoneId) => {
    try {
        const { milestone } = await trackPromise(Utils.api(`projects/${projectId}/milestones/${milestoneId}`));
        return milestone;
    } catch (error) {
        console.error('Error: ', error);
        return {};
    }
}

export const getMilestoneDocument = async (milestoneId) => {
    try {
        const response = await trackPromise(Utils.api(`completion-documents/${milestoneId}`));
        return response;
    } catch (error) {
        console.error('Error: ', error);
        return {};
    }
}

export const getMilestoneEffortSummary = async (milestoneId) => {
    try {
        const { effort } = await trackPromise(Utils.api(`milestones/${milestoneId}/effort-summary`));
        return effort;
    } catch (error) {
        console.error('Error: ', error);
        return [];
    }
}

export const saveMilestone = async (projectId, data) => {
    try {
        const { milestone } = await trackPromise(Utils.api(`projects/${projectId}/milestones`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return milestone;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const updateMilestoneDocumentStatus = async (milestoneId, data) => {
    try {
        const { milestone } = await trackPromise(Utils.api(`milestones/${milestoneId}/update-completion-document-status`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return milestone;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const updateMilestone = async (projectId, milestoneId, data) => {
    try {
        const { milestone } = await trackPromise(Utils.api(`projects/${projectId}/milestones/${milestoneId}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
        }));
        return milestone;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const updateMilestoneInvoiceStatus = async (milestoneId, data) => {
    try {
        const { milestone } = await trackPromise(Utils.api(`milestones/${milestoneId}/update-invoice-status`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return milestone;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const deleteMilestone = async (projectId, milestoneId) => {
    try {
        const { milestone } = await trackPromise(Utils.api(`projects/${projectId}/milestones/${milestoneId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        }));
        return milestone;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const getQuickbooksCustomers = async () => {
    try {
        const { customers = [] } = await trackPromise(Utils.api(`quickbooks/get-customers`));
        return customers;
    } catch (error) {
        console.error('Error: ', error);
        return [];
    }
}

export const getProjectRateCards = async (projectId) => {
    try {
        const { rate_cards = [] } = await trackPromise(Utils.api(`projects/${projectId}/rateCards`));
        return rate_cards;
    } catch (error) {
        console.error('Error: ', error);
        return [];
    }
}

export const updateProjectRateCards = async (projectId, data) => {
    try {
        const { rate_cards = [] } = await trackPromise(Utils.api(`projects/${projectId}/rateCards`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return rate_cards;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}
export const getExpenseById = async (expenseId) => {
    try {
        const { expense = {} } = await trackPromise(Utils.api(`expenses/${expenseId}`));
        return expense;
    } catch (error) {
        console.error('Error:', error);
        return {};
    }
}

export const createExpense = async (data) => {
    try {
        const { expense } = await trackPromise(Utils.api(`expenses`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
        }));
        return expense;
    } catch (error) {
        console.error('Error:', error);
        return error;
    }
}

export const updateExpense = async (expenseId, data) => {
    try {
        const { expense } = await trackPromise(Utils.api(`expenses/${expenseId}`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
        }));
        return expense;
    } catch (error) {
        console.error('Error:', error);
        return error;
    }
}

export const deleteExpense = async (expenseId) => {
    try {
        const { expense } = await trackPromise(Utils.api(`expenses/${expenseId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        }));
        return expense;
    } catch (error) {
        console.error('Error:', error);
        return error;
    }
}

export const getPaymentMethods = async () => {
    try {
        const { paymentMethods = [] } = await trackPromise(Utils.api(`paymentMethods`));
        return paymentMethods;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getExpenseCategories = async () => {
    try {
        const { data = [] } = await trackPromise(Utils.api(`expenseCategories`));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getExpenseCategoryById = async (categoryId) => {
    try {
        const { data } = await trackPromise(Utils.api(`expenseCategories/${categoryId}`));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return {};
    }
}

export const createExpenseCategory = async (dataBody) => {
    try {
        const { data } = await trackPromise(Utils.api(`expenseCategories`, {
            method: 'POST',
            body: JSON.stringify(dataBody),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const updateExpenseCategory = async (categoryId, dataBody) => {
    try {
        const { data } = await trackPromise(Utils.api(`expenseCategories/${categoryId}`, {
            method: 'PUT',
            body: JSON.stringify(dataBody),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const deleteExpenseCategory = async (categoryId) => {
    try {
        const { delete: deleted } = await trackPromise(Utils.api(`expenseCategories/${categoryId}`, {
            method: 'DELETE',
        }));
        return deleted;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const getExpenseTypes = async () => {
    try {
        const { types } = await trackPromise(Utils.api(`expenses/types`));
        return types;
    } catch (error) {
        console.error('Error: ', error);
        return [];
    }
}

export const getReimbursementCategories = async () => {
    try {
        const { data } = await trackPromise(Utils.api(`reimbursement-requests-categories`));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return [];
    }
}

export const getActiveReimbursementCategories = async () => {
    try {
        const { data } = await trackPromise(Utils.api(`reimbursement-requests-categories/active`));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return [];
    }
}

export const getReimbursementCategoryById = async (categoryId) => {
    try {
        const { data } = await trackPromise(Utils.api(`reimbursement-requests-categories/${categoryId}`));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return {};
    }
}

export const createReimbursementCategory = async (dataBody) => {
    try {
        const { data } = await trackPromise(Utils.api(`reimbursement-requests-categories`, {
            method: 'POST',
            body: JSON.stringify(dataBody),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const updateReimbursementCategory = async (categoryId, dataBody) => {
    try {
        const { data } = await trackPromise(Utils.api(`reimbursement-requests-categories/${categoryId}`, {
            method: 'PUT',
            body: JSON.stringify(dataBody),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const deleteReimbursementCategory = async (categoryId) => {
    try {
        const { delete: deleted } = await trackPromise(Utils.api(`reimbursement-requests-categories/${categoryId}`, {
            method: 'DELETE',
        }));
        return deleted;
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}

export const getAllReimbursementRequests = async () => {
    try {
        const { data } = await trackPromise(Utils.api(`reimbursement-requests`));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return [];
    }
}

export const getReimbursementRequestById = async (requestId) => {
    try {
        const { data } = await trackPromise(Utils.api(`reimbursement-requests/${requestId}`));
        return data;
    } catch (error) {
        console.error('Error: ', error);
        return {};
    }
}

export const acceptOrRejectReimbursementRequest = async (requestId, type, bodyData) => {
    try {
        const { data, status } = await trackPromise(Utils.api(`reimbursement-requests/${requestId}/${type}`, {
            method: 'PUT',
            body: JSON.stringify(bodyData),
            headers: {
                'Content-Type': 'application/json',
            },
        }));
        return { data, status };
    } catch (error) {
        console.error('Error: ', error);
        return error;
    }
}
export const getSystemRoles = async () => {
    try {
        const { roles = [] } = await trackPromise(Utils.api(`system-roles`));
        return roles;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getSeniorities = async () => {
    try {
        const { seniorities = [] } = await trackPromise(Utils.api(`seniorities`));
        return seniorities;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getLegalEntities = async () => {
    try {
        const { data = [] } = await trackPromise(Utils.api(`legal-entities`));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getDepartments = async () => {
    try {
        const { data = [] } = await trackPromise(Utils.api(`departments`));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getProjectJobdivaJobs = async () => {
    try {
        const { project_jobdiva_jobs = [] } = await trackPromise(Utils.api(`project-jobdiva-jobs`));
        return project_jobdiva_jobs;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getJobDivaStarts = async (bodyData) => {
    try {
        const { data = [] } = await trackPromise(Utils.api(`jobdiva/starts`, {
            method: 'POST',
            body: JSON.stringify(bodyData),
            headers: {
                'Content-Type': 'application/json'
            }
        }));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getUsersResumes = async () => {
    try {
        const { documents = [] } = await trackPromise(Utils.api(`users/resumes`));
        return documents;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getSystemSettings = async () => {
    try {
        const { settings = [] } = await trackPromise(Utils.api(`settings`));
        return settings;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}

export const getReportTimeOffs = async (dataBody) => {
    try {
        const { data = [] } = await trackPromise(
            Utils.api(`reports/time-off`, {
                method: "POST",
                body: JSON.stringify(dataBody),
                headers: {
                  "Content-Type": "application/json",
                }}));
        return data;
    } catch (error) {
        console.error('Error:', error);
        return [];
    }
}


export const putMaintenanceMode = async (id, active) => {
    try {
        const { settings = [] } = await trackPromise(Utils.api(`settings/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ active }),
            headers: {
                'Content-Type': 'application/json'
            },
        }));
        return settings;
    } catch (error) {
        console.error('Error:', error);
        return error;
    }
}

