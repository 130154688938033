import React, { createContext, useState, useContext } from "react";
import "../Components/ErrorHandler/error-handling.css";
import { Media } from "reactstrap";

const ApiErrorContext = createContext();

const ApiErrorProvider = ({ children }) => {
  const [apiError, setApiError] = useState("");

  const handleApiError = (error) => {
    setApiError(error.message);
  };

  return (
    <ApiErrorContext.Provider value={{ apiError, handleApiError }}>
      {children}
    </ApiErrorContext.Provider>
  );
};

const ApiErrorComponent = () => {
  const { apiError } = useContext(ApiErrorContext);
  return (
    <>
      {apiError && (
        <div id="error-handler-container">
          <div className="box">
            <div className="image-container">
              <Media
                className="m-0"
                src={`../../500error-${
                  this?.props?.darkModeActive ? "dark" : "light"
                }.svg`}
                fluid
              />
            </div>
            <div className="text-container">
              <div className="error-details">500</div>
              <h1 className="mb-0">Oops! Server Error</h1>
              <p className="m-0">
                An error occurred while executing the action. The zetta team
                will be notified and will work to address the problem as soon as
                possible, come back soon!
              </p>
            </div>
            {this?.props?.user?.roles?.some((role) => {
              return role.name === "super-admin";
            }) && (
              <details className="details-wrapper">
                <summary>Error Details</summary>
                {apiError && <p>{apiError}</p>}
                <br />
                {this.state.errorInfo.componentStack}
              </details>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { ApiErrorContext, ApiErrorProvider, ApiErrorComponent };
