import { zigattaApi } from "./zigattaApi";

export const reimbursementsApi = zigattaApi.injectEndpoints({
  endpoints: (builder) => ({
    getReimbursementsRequests: builder.query({
      query: () => `reimbursement-requests`,
      transformResponse: (response) => response.data,
      providesTags: ["ReimbursementRequest"],
    }),
    getUserReimbursementRequests: builder.query({
      query: () => `reimbursement-requests/my-requests`,
      transformResponse: (response) => response.data,
      providesTags: ["ReimbursementRequest"],
    }),
    getReimbursementRequestById: builder.query({
      query: (reimbursementId) => `reimbursement-requests/${reimbursementId}`,
      transformResponse: (response) => response.data,
      providesTags: ["ReimbursementRequest"],
    }),

    getReimbursementRequestURLPDFById: builder.query({
      query: (args) =>
        args?.reimbursement
          ? `reimbursement-requests/${args?.reimbursement?.id}/display?type=${args.fileType}`
          : "/",
      method: "GET",
      transformResponse: (response) => response.url,
      providesTags: ["ReimbursementRequest"],
    }),
    getReimbursementRequestURLIMGById: builder.query({
      query: (args) =>
        args?.reimbursement
          ? `reimbursement-requests/${args?.reimbursement?.id}/display?type=${args.fileType}`
          : "/",
      method: "GET",
      transformResponse: (response) => response.url,
      providesTags: ["ReimbursementRequest"],
    }),

    createReimbursementRequest: builder.mutation({
      query: (body) => ({
        url: `reimbursement-requests`,
        method: "POST",
        headers: { Accept: "application/json" },
        body,
      }),
      invalidatesTags: ["ReimbursementRequest"],
    }),
    updateReimbursementRequest: builder.mutation({
      query: ({ requestId, body }) => ({
        url: `reimbursement-requests/${requestId}`,
        method: "POST",
        headers: { Accept: "application/json" },
        body,
      }),
      invalidatesTags: ["ReimbursementRequest"],
    }),
    acceptRejectOrCancelReimbursementRequest: builder.mutation({
      query: ({ requestId, type = "accept", body = {} }) => ({
        url: `reimbursement-requests/${requestId}/${type}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ReimbursementRequest"],
    }),
    getReimbursementCategories: builder.query({
      query: () => `reimbursement-requests-categories`,
      transformResponse: (response) => response.data,
      providesTags: ["ReimbursementCategories"],
    }),
    getActiveReimbursementCategories: builder.query({
      query: () => `reimbursement-requests-categories/active`,
      transformResponse: (response) => response.data,
      providesTags: ["ReimbursementCategories"],
    }),
    getReimbursementCategoryById: builder.query({
      query: (categoryId) => `reimbursement-requests-categories/${categoryId}`,
      transformResponse: (response) => response.data,
      providesTags: ["ReimbursementCategories"],
    }),
    createReimbursementCategory: builder.mutation({
      query: (body = {}) => ({
        url: `reimbursement-requests-categories`,
        method: "POST",
        headers: { Accept: "application/json" },
        body,
      }),
      invalidatesTags: ["ReimbursementCategories"],
    }),
    updateReimbursementCategory: builder.mutation({
      query: ({ categoryId, body = {} }) => ({
        url: `reimbursement-requests-categories/${categoryId}`,
        method: "PUT",
        headers: { Accept: "application/json" },
        body,
      }),
      invalidatesTags: ["ReimbursementCategories"],
    }),
    deleteReimbursementCategory: builder.mutation({
      query: (categoryId) => ({
        url: `reimbursement-requests-categories/${categoryId}`,
        method: "DELETE",
        headers: { Accept: "application/json" },
      }),
      invalidatesTags: ["ReimbursementCategories"],
    }),
  }),
});

export const {
  useGetReimbursementsRequestsQuery,
  useGetUserReimbursementRequestsQuery,
  useGetReimbursementRequestByIdQuery,
  useGetReimbursementRequestURLPDFByIdQuery,
  useGetReimbursementRequestURLIMGByIdQuery,
  useCreateReimbursementRequestMutation,
  useUpdateReimbursementRequestMutation,
  useAcceptRejectOrCancelReimbursementRequestMutation,
  useGetReimbursementCategoriesQuery,
  useGetActiveReimbursementCategoriesQuery,
  useGetReimbursementCategoryByIdQuery,
  useCreateReimbursementCategoryMutation,
  useUpdateReimbursementCategoryMutation,
  useDeleteReimbursementCategoryMutation,
} = reimbursementsApi;
