import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";
import { digestObjectToSerialize } from "helpers/encodeArrayToRequest";
import { setCredentials, logout } from "store/slices/authSlice";
import { openGlobalAlert, closeGlobalAlert } from 'store/slices/userSlice';

const apiBaseURL = process.env.REACT_APP_API_URL;
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
    baseUrl: apiBaseURL,
    prepareHeaders: (headers, { getState }) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = getState().auth.token;
        if (token) headers.set('Authorization', `Bearer ${token}`)
        return headers
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    const appState = api.getState();
    await mutex.waitForUnlock();
    if (args?.params) args.params = digestObjectToSerialize(args?.params);
    let result = await baseQuery(args, api, extraOptions);
    let isMutation = ['POST', 'PUT', 'DELETE'].includes(args?.method);
    if (result.error && isMutation) {
        api.dispatch(openGlobalAlert({
            status: result.error.status,
            message: result.error.data?.message,
        }));
    } else if (appState?.app?.globalAlert?.isOpen && isMutation) {
        api.dispatch(closeGlobalAlert());
    }
    if (result.error && result.error.status === 401) {
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();
            try {
                const refreshResult = await baseQuery(
                    {
                        url: 'users/refresh',
                        method: 'POST',
                        headers: {
                            'Authorization': appState?.auth?.refreshToken,
                        },
                    },
                    api,
                    extraOptions,
                );

                if (refreshResult.data) {
                    api.dispatch(setCredentials(refreshResult.data));

                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(logout());
                }
            } finally {
                release();
            }
        } else {
            await mutex.waitForUnlock()
            result = await baseQuery(args, api, extraOptions)
        }
    }
    return result;
};

export const zigattaApi = createApi({
    reducerPath: "zigattaApi",
    baseQuery: baseQueryWithReauth,
    /**
     * Tag types must be defined in the original API definition
     * for any tags that would be provided by injected endpoints
     * To refetch endpoint data, add providesTags property to each query to be refetched
     * and invalidatesTags to each mutation that will trigger refetch
     */
    tagTypes: [
        'Auth',
        'Departments',
        'Roles',
        'Role',
        'Allocation',
        'Users',
        'Dashboard',
        'SystemRoles',
        'Tags',
        'RolesRateCards',
        'Seniorities',
        'LegalEntities',
        'TimeLogsReminder',
        'Currencies',
        'Jobdiva',
        'JobdivaProjects',
        'JobdivaSyncs',
        'ProjectRateCards',
        'Projects',
        'Expenses',
        'TimeOff',
        'TimeOffRules',
        'ExpenseCategories',
        'ReimbursementRequest',
        'ReimbursementCategories',
        'SystemVersions',
        'UserPayRates',
        'Teams',
        'MembersTimelogs',
        'Timelogs',
        'SeniorityTitles',
        'SaleLevels',
        'ProjectStatus',
        'Holidays',
        'MyProfile',
        'MyConnections',
        'Skills',
    ],
    endpoints: () => ({})
});
