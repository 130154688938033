import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'sale-levels';

export const salesApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getSales: builder.query({
            query: () => BASE_ENDPOINT,
            transformResponse: response => response.data,
            providesTags: ['Sales', { type: 'Sales', id: 'LIST' }],
        }),
        getSalesById: builder.query({
            query: (id) => `${BASE_ENDPOINT}/${id}`,
            transformResponse: response => response.data,
            providesTags: ['Sales'],
        }),
        createSales: builder.mutation({
            query: ({ body }) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                body,
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['Sales'],
        }),
        updateSales: builder.mutation({
            query: ({ saleId, body }) => ({
                url: `${BASE_ENDPOINT}/${saleId}`,
                method: 'PUT',
                body,
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['Sales'],
        }),
        deleteSales: builder.mutation({
            query: (saleId) => ({
                url: `${BASE_ENDPOINT}/${saleId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Sales', id: 'LIST' }],
        }),
    }),
});

export const {
    useGetSalesQuery,
    useLazyGetSalesQuery,
    useGetSalesByIdQuery,
    useLazyGetSalesByIdQuery,
    useCreateSalesMutation,
    useUpdateSalesMutation,
    useDeleteSalesMutation,
} = salesApi;