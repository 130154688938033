import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Media, UncontrolledDropdown, DropdownToggle, DropdownMenu, Alert } from 'reactstrap';
import { BsSearch } from 'react-icons/bs';
import SidebarNavItems from 'Components/SidebarNavItems/SidebarNavItems';
import { useGetRolesQuery } from 'services/roles';
import { selectMaintenanceMode, openSearchModal } from 'store/slices/userSlice';
import { selectUser } from "store/slices/authSlice";
import { DROPDOWN_ITEMS } from 'constants/headerPermissions';
import { canOpenSearch } from 'constants/common';
import './header.css';

const Header = () => {
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { data: roles = [] } = useGetRolesQuery();

  const userRole = roles?.find(role => role.id === user?.role_id);

  return (
    <div className='banner'>
      <div className='d-flex header-items'>
        <div className='zigatta-nav' />
        <div className='right-side d-flex align-items-center '>
          <div className='d-flex header-icons'>
            {canOpenSearch && <BsSearch className="me-3" onClick={() => dispatch(openSearchModal(true))} />}
          </div>
          <UncontrolledDropdown className='me-4'>
            <DropdownToggle nav>
              <Media className='rounded-circle' height={40} width={40} src={user?.avatar_url ?? '/default-avatar.png'} />
            </DropdownToggle>
            <DropdownMenu end>
              <div className='user-dropdown d-flex flex-column ms-3 mt-2 divider'>
                <label className='user-dropdown-name'>{user?.name ?? user?.display_name ?? '-'}</label>
                <label className='user-dropdown-role'>{userRole?.name ?? '-'}</label>
              </div>
              <SidebarNavItems navItems={DROPDOWN_ITEMS.slice(0, -1)} className='user-dropdown-menu menu-fit flex-column' itemClass='user-dropdown-menu-item ms-0 me-0 my-1' />
              <SidebarNavItems navItems={DROPDOWN_ITEMS.slice(-1)} className='user-dropdown-menu menu-fit flex-column' itemClass='user-dropdown-menu-item ms-0 me-0 my-1' />
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      {
        Boolean(maintenanceMode?.maintenance_mode) && maintenanceMode?.is_super_admin &&
        (
          <Alert className="mb-0" color='warning'>
            <b>The maintenance mode is active</b>, some system functionalities may present errors.
          </Alert>
        )
      }
    </div >
  );
}

export default Header;