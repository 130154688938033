import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { createToast } from 'helpers';
import { useConfirmJobdivaSyncMutation } from 'services/jobdiva';

const textConfirmation = 'CONFIRM SYNC';

const ConfirmSyncModal = ({
    syncProp = {},
    isOpen,
    toggle,
    handleSuccess,
}) => {
    const [sync, setSync] = useState({});
    const [inputTextConfirmation, setInputText] = useState('');
    const { id } = useParams();
    const [confirmSync, { isLoading: isPetitionLoading }] = useConfirmJobdivaSyncMutation();

    // handlers

    const onClosed = () => setInputText('');

    const onOpened = () => setSync(syncProp);

    const handleChange = ({ target: { value, } }) => setInputText(value.toUpperCase());

    const handleConfirm = async (e) => {
        let copyOfSync = Object.assign({}, sync);
        let itemsToSend = [];
        copyOfSync.details?.forEach((detail, idx) => {
            if (detail.data.allocation.filter(a => a.isChecked).length) itemsToSend.push(detail.id);
        });
        copyOfSync = {
            ...copyOfSync,
            details: copyOfSync.details.filter(detail => itemsToSend.includes(detail.id)),
        };
        const body = { data: copyOfSync };
        const { data, error } = await confirmSync({ syncId: id, body });
        if (error) {
            createToast({
                type: 'error',
                text: `An error has ocurred, please contact the administrator.\nError code: ${error.status}`,
            });
            return;
        }
        if(!data) return;
        handleSuccess();
        return;
    }


    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            onClosed={onClosed}
            onOpened={onOpened}
            centered
        >
            <ModalHeader toggle={toggle} className='bg-success text-white'>
                Confirm sync
            </ModalHeader>
            <ModalBody>
                <p className="m-0 pb-3">
                    Do you want to confirm all the selected syncs?
                </p>
                <Row>
                    <Col
                        md={12}
                    >
                        <FormGroup>
                            <Label>
                                To activate, type <span className='font-italic text-uppercase'>{textConfirmation}</span> in the text input field.
                            </Label>
                            <Input
                                className='text-uppercase'
                                type='text'
                                name='inputTextConfirmation'
                                value={inputTextConfirmation}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter className='d-flex align-items-center justify-content-end'>
                <div>
                    <Button
                        className='me-2'
                        color='secondary'
                        size='sm'
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                    <Button
                        className=''
                        color='success'
                        size='sm'
                        onClick={handleConfirm}
                        disabled={isPetitionLoading || inputTextConfirmation !== textConfirmation}
                    >
                        {isPetitionLoading
                            ?
                            <>
                                <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                Confirming...
                            </>
                            :
                            'Confirm'
                        }
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default ConfirmSyncModal;