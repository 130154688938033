import React from "react";
import PropTypes from "prop-types";
import { Badge, Card, CardBody, Media } from "reactstrap";

import classes from "./CustomAvatar.module.css";

const CustomAvatar = ({
  size = 30,
  user = {
    name: "",
    avatar_url: "",
  },
  cardClassName = '',
  tag = Card,
  to,
  showBadges,
  showText,
  children,
}) => {
  return (
    <>    
      <Card className={cardClassName} tag={tag} to={to}>
        <CardBody>
          <div className="d-flex justify-content-center">
            <Media
              className="rounded-circle"
              height={size}
              width={size}
              src={user.avatar_url ?? "/default-avatar.png"}
            />
          </div>
          {!showText && (
            <div className="pt-2 text-center">
              <p className="my-1 text-center">
                <b>{user.name ?? '-'}</b>
              </p>
              {showBadges && (user.department || user.department_name) && (
                <p className="my-1 text-center">
                  <Badge className={classes["badge-department"]} pill>
                    {user.department?.name ?? user.department_name ?? ''}
                  </Badge>
                </p>
              )}
              {showBadges && (user.role || user.role_name) && (
                <p className="my-1 text-center">
                  <Badge className={classes["badge-role"]} pill>
                    {user.role?.name ?? user.role_name ?? ""}
                  </Badge>
                </p>
              )}
              {showBadges && user.seniority_label && (
                <p className="my-1 text-center">
                  <Badge className={classes["badge-seniority"]} pill>
                    {user.seniority_label ?? ""}
                  </Badge>
                </p>
              )}
              {showBadges && (user.seniority_title || user.seniority_title_name) && (
                <p className="my-1 text-center">
                  <Badge className={classes["badge-seniority-title"]} pill>
                    {user.seniority_title?.name ?? user.seniority_title_name ?? ""}
                  </Badge>
                </p>
              )}
              {showBadges && (user.sale_level || user.sale_level_name) && (
                <p className="my-1 text-center">
                  <Badge className={classes["badge-sales-level"]} pill>
                    {user.sale_level?.name ?? user.sale_level_name ?? ""}
                  </Badge>
                </p>
              )}
            </div>
          )}
          {children}
        </CardBody>
      </Card>
    </>
  );
};

CustomAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.number,
  cardClassName: PropTypes.string,
  tag: PropTypes.elementType,
  to: PropTypes.string,
  showBadges: PropTypes.bool,
};

export default CustomAvatar;
