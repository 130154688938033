import React from 'react';
import {
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { format, parseISO } from 'date-fns';
import { useGetProjectsQuery } from 'services/projects';
import { useGetRolesQuery } from 'services/roles';
import { getSelectOptions } from 'helpers';

const dataProp = {
    project_id: '',
    daily_hours: '8.0',
    jobdiva_end_date: '',
    jobdiva_start_date: '',
    role_id: '',
    rate_card: {
        jobdiva_bill_rate: '',
        jobdiva_pay_rate: '',
        project_id: '',
    },
};

const EditAllocationModal = ({
    data = dataProp,
    isOpen,
    onClosed,
    toggle,
    handleAllocationChange,
    handleRateCardChange,
}) => {
    const { data: dataProjects = [] } = useGetProjectsQuery();
    const { data: dataRoles = [] } = useGetRolesQuery();
    const projects = getSelectOptions(dataProjects);
    const roles = getSelectOptions(dataRoles);

    const onProjectChange = (option, { name }) => {
        handleAllocationChange(name, option.value);
        handleRateCardChange(name, option.value);
    }

    const onRateCardChange = ({ target: { name, value } }) => handleRateCardChange(name, value);

    const onChange = ({ target: { name, value } }) => handleAllocationChange(name, value);

    const onDateChange = (name, date = '') => handleAllocationChange(name, format(date, 'yyyy-MM-dd'));

    const onSelectChange = (option, { name }) => handleAllocationChange(name, option.value);

    return (
        <Modal
            size='lg'
            isOpen={isOpen}
            onClosed={onClosed}
            toggle={toggle}
            centered
        >
            <ModalHeader
                toggle={toggle}
            >
                Edit allocation
            </ModalHeader>
            <ModalBody>
                <Row className='py-3'>
                    <Col
                        md={6}
                    >
                        <FormGroup>
                            <Label><b>Project</b></Label>
                            <Select
                                value={projects.find(({ value }) => value === data.project_id)}
                                onChange={onProjectChange}
                                name='project_id'
                                placeholder="Select project"
                                options={projects}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={6}
                    >
                        <FormGroup>
                            <Label><b>Role</b></Label>
                            <Select
                                value={roles.find(({ value }) => value === data.role_id)}
                                onChange={onSelectChange}
                                name='role_id'
                                placeholder="Select role"
                                options={roles}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={6}
                    >
                        <FormGroup>
                            <Label><b>Daily Hours</b></Label>
                            <Input
                                type='text'
                                value={data.daily_hours}
                                name='daily_hours'
                                onChange={onChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={6}
                    >
                        <FormGroup>
                            <Label><b>Start Date</b></Label>
                            <DatePicker
                                customInput={<Input type='text' name="jobdiva_start_date" />}
                                selected={data.jobdiva_start_date ? parseISO(data.jobdiva_start_date) : ''}
                                name='hired_at'
                                onChange={date => onDateChange('jobdiva_start_date', date)}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={6}
                    >
                        <FormGroup>
                            <Label><b>End Date</b></Label>
                            <DatePicker
                                customInput={<Input type='text' name="jobdiva_end_date" />}
                                selected={data.jobdiva_end_date ? parseISO(data.jobdiva_end_date) : ''}
                                name='jobdiva_end_date'
                                onChange={date => onDateChange('jobdiva_end_date', date)}
                                dateFormat="dd-MM-yyyy"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className='py-3'>
                    <Col
                        md={6}
                    >
                        <FormGroup>
                            <Label><b>Pay Rate</b></Label>
                            <Input
                                type='number'
                                value={data.rate_card?.jobdiva_pay_rate}
                                name='jobdiva_pay_rate'
                                placeholder='0.00'
                                step='0.1'
                                onChange={onRateCardChange}
                            />
                        </FormGroup>
                    </Col>
                    <Col
                        md={6}
                    >
                        <FormGroup>
                            <Label><b>Bill Rate</b></Label>
                            <Input
                                type='number'
                                value={data.rate_card?.jobdiva_bill_rate}
                                name='jobdiva_bill_rate'
                                placeholder='0.00'
                                step='0.1'
                                onChange={onRateCardChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
}

// EditAllocationModal.defatulProps = {
//     isOpen: false,
//     data: {
//         project_id: '',
//         daily_hours: '8.0',
//         jobdiva_end_date: '',
//         jobdiva_start_date: '',
//         role_id: '',
//         rate_card: {
//             jobdiva_bill_rate: '',
//             jobdiva_pay_rate: '',
//             project_id: '',
//         },
//     },
// };

export default EditAllocationModal;