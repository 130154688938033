import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Nav, NavItem, NavLink, } from 'reactstrap';
import { BsCodeSlash } from 'react-icons/bs';
import classNames from "classnames";
import { selectSidebarExpanded } from 'store/slices/userSlice';
import { useGetLastSystemVersionQuery } from 'services/versions';

const ChangelogButton = () => {
    const { pathname } = useLocation();
    const sideBarOpen = useSelector(selectSidebarExpanded);
    const { data } = useGetLastSystemVersionQuery();
    const tooltip = !sideBarOpen ? { 'data-bs-toggle': "tooltip", title: 'Changelog' } : {};

    return (
        <Nav className='ps-2'>
            <NavItem {...tooltip}>
                <div className='d-flex align-items-center justify-content-between' >
                    <NavLink tag={Link} to={'versions'} className={classNames('sidebar-nav-item', { active: pathname === '/versions' })}>
                        <BsCodeSlash className="me-2 sidebar-icon" />{sideBarOpen && `What's new? v${data?.version || ''}`}
                    </NavLink>
                </div>
            </NavItem>
        </Nav>
    );
};

export default ChangelogButton;