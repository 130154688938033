export const processProjects = (projects = [], productOwners = []) => {
    const processProductOwners = (productOwner = {}) => {
        if(!productOwners.some(pO => pO.id === productOwner.id)) productOwners.push(productOwner);
    };

    let processedProjects = projects.map(project => {
        let involvedUsers = [];
        let involvedUsersLength = 0;
        involvedUsers.push({...project.product_owner, isProductOwner: true});
        if(project.sales_person) {
            involvedUsers.push({...project.sales_person, isSalesperson: true});
        }
        if(project.allocated_users.length > 0) {
            project.allocated_users.forEach(user => {
                involvedUsers.push({...user, isTeamMember: true});
            });
        }
        involvedUsersLength = involvedUsers.length;
        if(involvedUsersLength > 4){
            involvedUsers = involvedUsers.splice(0,4);
        }
        processProductOwners(project.product_owner);
        return {...project, involvedUsers, involvedUsersLength};
    });
    return { processedProjects, productOwners };
}

export default processProjects;