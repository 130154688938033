import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Button,
} from 'reactstrap';
import { createToast } from 'helpers';
import CustomModal from 'Components/Modal/Modal';
import { useAcceptRejectOrCancelReimbursementRequestMutation } from 'services/reimbursements';

const petitionTypes = {
    accept: {
        color: 'success'
    },
    reject: {
        color: 'danger'
    },
};

const AcceptOrRejectReimbursementModal = ({
    id = null,
    requestType = 'accept',
    isOpen,
    toggle,
    handleClosed,
}) => {
    const [comments, setComments] = useState('');
    const [acceptOrRejectMutation, { isLoading }] = useAcceptRejectOrCancelReimbursementRequestMutation();

    const onClosed = () => {
        setComments('');
        handleClosed();
    }

    const onInputChange = ({ target: { name, value } }) => {
        setComments(value);
    }

    const onRequest = async () => {
        const body = { comments };
        const { data, error } = await acceptOrRejectMutation({ requestId: id, type: requestType, body });
        if(error || !data) return;
        createToast({
            type: 'success',
            text: data?.status?.msg,
        });
        toggle();
    }

    const modalFooter = (
        <>
            <Button
                className='me-2'
                color='secondary'
                onClick={toggle}
            >
                Cancel
            </Button>
            <Button
                color={petitionTypes[requestType]?.color ?? 'primary'}
                onClick={onRequest}
                disabled={isLoading}
            >
                {isLoading &&
                    <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                }
                Yes, {requestType}
            </Button>
        </>
    );

    return (
        <>
            <CustomModal
                header={`${requestType} reimbursement request`}
                headerClassName={`text-capitalize bg-${petitionTypes[requestType]?.color || 'light'}`}
                handleToggle={toggle}
                isOpen={isOpen}
                footer={modalFooter}
                onClosed={onClosed}
                footerJustify='end'
                size='lg'
                centered
            >
                {`Do you want to ${requestType} this reimbursement?`}
                <br />
                <br />
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Comments (optional)</Label>
                            <Input
                                type='text'
                                value={comments}
                                name='comments'
                                onChange={onInputChange}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </CustomModal>
        </>
    );
};

AcceptOrRejectReimbursementModal.protoTypes = {
    id: PropTypes.any,
    requestType: PropTypes.string,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    handleClosed: PropTypes.func,
};

export default AcceptOrRejectReimbursementModal;