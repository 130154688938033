import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { checkPermission } from "helpers";
import Forbidden from "Components/Forbidden/Forbidden";
import MaintenanceMode from "Components/maintenancemode/MaintenanceMode";
import { logout, selectUser, selectLoggedIn } from "store/slices/authSlice";
import { selectMaintenanceMode } from "store/slices/userSlice";
import ErrorBoundary from "Components/ErrorHandler/error-handling";
import "App.css";

const { REACT_APP_FORCE_GOOGLE_LOGIN } = process.env;

const PrivateRoute = ({ children, permissions }) => {
  const loggedIn = useSelector(selectLoggedIn);
  const user = useSelector(selectUser);
  const maintenanceMode = useSelector(selectMaintenanceMode);
  const dispatch = useDispatch();

  useEffect(() => {
    // Logs out  the user if it has not used the google authentication method and login with password is now allowed
    if (
      loggedIn &&
      user?.is_googlez_auth === 0 &&
      REACT_APP_FORCE_GOOGLE_LOGIN === "true"
    )
      dispatch(logout(user));
  }, [dispatch, loggedIn, user]);

  const isForbidden = permissions
    ? permissions?.some((permission) => checkPermission(permission))
    : true;

  if (!loggedIn) return <Navigate to="/login" />;
  if (
    Boolean(maintenanceMode?.maintenance_mode) &&
    !maintenanceMode?.is_super_admin
  )
    return <MaintenanceMode />;
  if (!isForbidden) return <Forbidden />;
  return (
    children ?? (
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    )
  );
};

export default PrivateRoute;
