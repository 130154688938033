import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import HolidaysForm from 'pages/Holidays/HolidaysForm/HolidaysForm';
import { useCreateHolidayMutation } from 'services/holidays';
import { decodeErrors } from 'helpers';
import { format } from 'date-fns';

const initialState = {
    name: '',
    legal_entity_id: '',
    date: null,
};

const CreateHoliday = () => {
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [createMutation, { isLoading }] = useCreateHolidayMutation();

    const onHolidayFormChange = ({ name, value }) => setState(prevState => ({ ...prevState, [name]: value, }));

    const onCreateHoliday = async (e) => {
        e.preventDefault();
        const body = { ...state };
        if (body.date) body.date = format(body.date, 'yyyy-MM-dd');
        const { data, error } = await createMutation(body);
        if (error) {
            setErrors(error?.data?.errors ?? decodeErrors(error?.data?.data ?? []));
            return;
        }
        if (!data) return;
        navigate('/holidays');
    }

    return (
        <div className="py-3">
            <PageHeader title='Create Holiday' className='pb-3'>
                <Button color='primary' size='sm' tag={Link} to='/holidays'>Back to list</Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardBody>
                        <HolidaysForm
                            data={state}
                            errors={errors}
                            handleChange={onHolidayFormChange}
                        />
                        <div className="text-end">
                            <Button color='success' onClick={onCreateHoliday} disabled={isLoading}>
                                {isLoading ?
                                    <>
                                        <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                        Saving...
                                    </>
                                    :
                                    'Save'
                                }
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default CreateHoliday;