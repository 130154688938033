import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams, } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import NavTab from 'Components/NavTab/NavTab';
import { useGetDeparmentByIdQuery } from 'services/departments';
import { checkPermission } from 'helpers';

const canUpdateDepartments = checkPermission('UPDATE_DEPARTMENTS');
const initialState = {
  department: {
    users: [],
    notifications: [],
  },
  selectedNotifications: [],
};
const ShowDepartment = () => {
  const { id } = useParams();
  const [state, setState] = useState(initialState);
  const { data: dataDepartment, isFetching } = useGetDeparmentByIdQuery(id);

  useEffect(() => {
    if (!!!dataDepartment) return;
    let selectedNotifications = dataDepartment?.notifications?.map(({ id }) => id);
    setState(prevState => ({
      ...prevState,
      department: dataDepartment,
      selectedNotifications,
    }));
  }, [dataDepartment]);

  const setNotificationsArray = (selectedNotifications = []) =>
    setState(prevState => ({
      ...prevState,
      selectedNotifications,
    }));

  const notificationsProps = {
    selectedNotifications: state.selectedNotifications,
    departmentName: state.department.name,
    setNotificationsArray
  };

  const usersProps = {
    users: state?.department?.users,
    isLoading: isFetching,
  };

  const outletContext = {
    notificationsProps,
    usersProps,
    department: state?.department,
  };

  const navLinks = [
    {
      name: 'General Information',
      path: `/departments/${id}`,
      isVisible: true,
    },
    {
      name: 'Notifications',
      path: `/departments/${id}/notifications`,
      isVisible: true,
    },
    {
      name: 'Users',
      path: `/departments/${id}/users`,
      isVisible: true,
    },
  ];

  return (
    <div>
      <PageHeader title={`Department: ${state?.department?.name ?? '-'}`} containerClass='container' className='pb-3'>
        <div>
          <Button className='me-2' color='warning' tag={Link} to={`edit`} disabled={!canUpdateDepartments}>Edit</Button>
          <Button tag={Link} to='/departments'>Back to departments list</Button>
        </div>
      </PageHeader>
      <div className="container pb-2">
        <NavTab links={navLinks} />
        <Outlet context={outletContext} />
      </div>
    </div >
  )
};

export default ShowDepartment;