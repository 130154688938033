import React from 'react';
import { useOutletContext } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
} from 'reactstrap';

const SeniorityTitleGeneralInformation = () => {
    const { generalInformationProps } = useOutletContext();
    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <p className="m-0">
                                        {generalInformationProps?.name ?? '-'}
                                    </p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default SeniorityTitleGeneralInformation;