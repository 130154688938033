import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import Modal from 'Components/Modal/Modal';
import { useDeleteSeniorityTitleByIdMutation } from 'services/seniorities';
import { createToast } from 'helpers';

const DeleteSeniorityTitleModal = ({
    isOpen,
    toggle,
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deleteMutation, { isLoading }] = useDeleteSeniorityTitleByIdMutation();

    const onDelete = async () => {
        const { data, error } = await deleteMutation(id);
        if (error) {
            createToast({ type: 'error', text: 'An error has ocurred.' });
            return;
        }
        if (!data) return;
        navigate('/seniority-titles');
    }

    const modalFooter = (
        <>
            <Button className='me-2' onClick={toggle}>Cancel</Button>
            <Button color='primary' onClick={onDelete}>
                {isLoading ? <> <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span> Deleting</> : 'Delete'}
            </Button>
        </>
    );
    return (
        <Modal
            isOpen={isOpen}
            handleToggle={toggle}
            header='Delete seniority title'
            footer={modalFooter}
            centered
        >
            <p className="m-0">Are you sure you want to delete this seniority title?</p>
        </Modal>
    );
};

export default DeleteSeniorityTitleModal;