import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'roles-rate-cards';

export const rolesRateCardsApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getRolesRateCards: builder.query({
            query: (params) => ({
                url: BASE_ENDPOINT,
                params,
            }),
            providesTags: ['RolesRateCards'],
        }),
        importRolesRateCards: builder.mutation({
            query: (body) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                },
                body,
            }),
            invalidatesTags: ['RolesRateCards'],
        }),
    }),
});

export const {
    useGetRolesRateCardsQuery,
    useLazyGetRolesRateCardsQuery,
    useImportRolesRateCardsMutation,
} = rolesRateCardsApi;