import { useState, useEffect } from 'react';
import Pusher from 'pusher-js';

const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_CLUSTER } = process.env;

const useChannel = ({
    channelName = '',
    eventName = '',
    apiCall,
}) => {
    const [response, setResponse] = useState(null);
    const handleEventFromServer = (data) => setResponse(data);

    useEffect(() => {
        if(!channelName || !eventName) return;
        const pusher = new Pusher(REACT_APP_PUSHER_APP_KEY, {
            cluster: REACT_APP_PUSHER_CLUSTER,
        });
        const channelSubscription = pusher.subscribe(channelName);
        channelSubscription.bind("pusher:subscription_succeeded", async () => {
            if(!apiCall) return;
            await apiCall();
        });
        channelSubscription.bind(eventName, handleEventFromServer);

        return (() => {
            channelSubscription.unbind(eventName, handleEventFromServer);
            pusher.unsubscribe(channelName);
        });
    }, [channelName, eventName]);

    return { response };
}

export default useChannel;