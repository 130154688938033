import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SideBar from 'containers/SideBar/SideBar';
import SearchShortcut from 'Components/SearchShortcut/SearchShortcut';
import PageReloadModal from 'Components/Modals/PageReloadModal/PageReloadModal';
import Header from 'containers/Header/Header';
import useMaintenanceModeChannel from 'Hooks/useMaintenanceModeChannel';
import "react-datepicker/dist/react-datepicker.css";

const Layout = () => {
    useMaintenanceModeChannel();
    return (
        <div className='d-flex h-100'>
            <ToastContainer />
            <SearchShortcut />
            <PageReloadModal />
            <SideBar />
            <div className="w-100">
                <Header />
                <div className='container-fluid outlet fit-to-height'>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Layout;