import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';

const CustomModal = ({
    isOpen,
    header,
    handleToggle,
    onClosed,
    onOpened,
    footer,
    body,
    children,
    className = '',
    headerClassName = '',
    footerJustify = 'end',
    size = '',
    centered,
    footerClassName = '',
    hideHeader,
    hideFooter,
}) => {
    return (
        <Modal isOpen={isOpen} toggle={handleToggle} onClosed={onClosed} onOpened={onOpened} className={className} centered={centered} size={size} >
            {!hideHeader &&
                <ModalHeader className={headerClassName} toggle={handleToggle}>
                    {header}
                </ModalHeader>
            }
            <ModalBody>
                {body ?? children}
            </ModalBody>
            {!hideFooter &&
                <ModalFooter className={`d-flex justify-content-${footerJustify} ${footerClassName}`}>
                    {footer}
                </ModalFooter>
            }
        </Modal>
    );
};

export default CustomModal;