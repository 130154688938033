import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    FormFeedback,
    Alert,
    Button,
} from 'reactstrap';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import PageHeader from 'Components/PageHeader/PageHeader';
import { createToast } from 'helpers';
import { useCreateJobdivaSyncMutation } from 'services/jobdiva';

const acceptsFileTypes = {
    'text/csv': [],
};

const initialState = {
    file: null,
    statusMessage: '',
    entryId: null,
    errors: {},
    errorMessage: '',
};

const NewSync = () => {
    const [state, setState] = useState(initialState);
    const [createSync, { isLoading: isPetitionLoading }] = useCreateJobdivaSyncMutation();

    // Handlers

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length) {
            return createToast({ type: 'error', text: 'You can only import files with CSV format.' });
        }

        if (acceptedFiles.length && acceptedFiles.length > 1) {
            return createToast({ type: 'error', text: 'You can only import one file.' });
        }

        setState(prevState => ({
            ...prevState,
            file: acceptedFiles[0],
        }));
    }

    const handleImport = async (e) => {
        e.preventDefault();
        const body = new FormData();
        if (state.file) body.append('csv_file', state.file);
        const { data, error } = await createSync(body);
        if (error) {
            setState(prevState => ({
                ...prevState,
                errors: error.data?.errors ?? {},
                errorMessage: error.data?.message || '',
            }));
            return;
        }
        if (!data) return;
        setState(prevState => ({
            ...prevState,
            statusMessage: data.process,
            entryId: data.id,
            errors: {},
            errorMessage: '',
        }));
    }


    return (
        <div>
            <PageHeader title='JobDiva Syncs' className='pb-3'>
                <Button
                    color='secondary'
                    size='sm'
                    tag={Link}
                    to='/jobdiva/syncs'
                >
                    Back to list
                </Button>
            </PageHeader>
            <div className="container-fluid">
                <Row
                    className="py-3"
                >
                    <Col
                        md={12}
                    >
                        <Card>
                            <CardHeader>
                                <h5>Import file</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col
                                        md={12}
                                    >
                                        <Alert
                                            color='danger'
                                            isOpen={!!state.errorMessage}
                                        >
                                            {state.errorMessage}
                                        </Alert>
                                        <Alert
                                            color='info'
                                            isOpen={!!state.statusMessage}
                                        >
                                            <p className='m-0'>
                                                File successfully uploaded. The file process is {state.statusMessage}.
                                            </p>
                                            <Link
                                                to='/jobdiva/syncs'
                                            >
                                                Go to syncs list
                                            </Link>
                                            &nbsp;or&nbsp;
                                            <Link
                                                to={`/jobdiva/syncs/pending/${state.entryId}`}
                                            >
                                                see file detail.
                                            </Link>
                                        </Alert>
                                    </Col>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Dropzone
                                                accept={acceptsFileTypes}
                                                onDrop={onDrop}
                                            >
                                                {({ getRootProps, getInputProps, isDragActive, acceptedFiles, }) => {
                                                    return (
                                                        <div
                                                            {...getRootProps({
                                                                style: {
                                                                    borderColor: state.errors.file_csv ? 'red' : 'none',
                                                                }
                                                            })}
                                                            className={classNames('dropzone', { 'dropzone--isActive': isDragActive })}
                                                        >
                                                            <input {...getInputProps()} />
                                                            {
                                                                state.file ?
                                                                    <p>{state.file.name} - {state.file.size} bytes</p> :
                                                                    isDragActive ?
                                                                        <p>Drop JobDiva file...</p> :
                                                                        <p>Drag and drop, or click to select a file in CSV format</p>
                                                            }
                                                        </div>
                                                    );
                                                }}
                                            </Dropzone>
                                            <FormFeedback
                                                className={state.errors.file_csv ? 'd-block' : 'd-none'}
                                            >
                                                {state.errors.file_csv ?? ''}
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter
                                className='d-flex align-items-center justify-content-end'
                            >
                                <Button
                                    color='primary'
                                    size='sm'
                                    onClick={handleImport}
                                    disabled={isPetitionLoading}
                                >
                                    {isPetitionLoading
                                        ?
                                        <>
                                            <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                            Importing...
                                        </>
                                        :
                                        'Import'
                                    }
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );

}

export default NewSync;