/**
 * Method to create an object of errors
 * @param {*} array array of objects, each object needs to have a key named attribute, and a key named msg
 * @returns object of errors [{ [name]: value }]
 */
const decodeErrors = (array = []) => {
    if(!array || !array.length) return {};
    return array.reduce((obj, {attribute, msg}) => {
        obj[attribute] = msg;
        return obj;
    }, {});
}

export default decodeErrors;