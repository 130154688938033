import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
} from 'reactstrap';
import { FiPlus, FiDownload } from 'react-icons/fi';
import { format, parseISO } from 'date-fns';
import TableContainer from 'Components/Table/Table';
import { statusColor } from 'pages/Jobdiva/helpers';
import { useGetJobdivaSyncsQuery } from 'services/jobdiva';
import { useDownloadJobdivaSyncFileMutation } from 'services/downloads';
import PageHeader from 'Components/PageHeader/PageHeader';

const SyncsList = () => {
    const { data, isFetching, isLoading } = useGetJobdivaSyncsQuery();
    const [downloadFile] = useDownloadJobdivaSyncFileMutation();

    const syncs = useMemo(() => {
        if (data) return [...data];
        return [];
    }, [data]);

    const columns = [
        {
            Header: '#',
            accessor: 'id',
            Cell: ({ row: { original } }) => (
                <Link
                    to={`/jobdiva/syncs/${original.status?.toLowerCase()}/${original.id}`}
                >
                    <b>{original.id}</b>
                </Link>
            ),
        },
        {
            Header: 'File',
            accessor: 'file',
            Cell: ({ row: { original } }) => (
                <Button
                    color='primary'
                    size='sm'
                    onClick={(e) => downloadFile(original)}
                >
                    <FiDownload />
                </Button>
            ),
            disableSortBy: true,
        },
        {
            Header: 'Imported by',
            accessor: 'imported_by',
            Cell: ({ row: { original } }) => (original.user?.name ?? '-'),
        },
        {
            Header: 'Date',
            accessor: 'created_at',
            Cell: ({ row: { original } }) => (format(parseISO(original.created_at), 'MMM dd, yyyy')),
        },
        {
            Header: 'Process',
            accessor: 'process',
            Cell: ({ row: { original } }) => (
                <p className={`text-capitalize ${statusColor(original.process)}`}>
                    {original.process}
                </p>
            ),
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row: { original } }) => (
                <p className={`text-capitalize ${statusColor(original.status)}`}>
                    {original.status}
                </p>
            ),
        },
    ];

    return (
        <div>
            <PageHeader title='JobDiva Syncs' className='pb-3'>
                <div>
                    <Button
                        className='me-2'
                        color='secondary'
                        size='sm'
                        tag={Link}
                        to='/jobdiva'
                    >
                        Back to jobdiva
                    </Button>
                    <Button
                        color='jobdiva-import'
                        size='sm'
                        tag={Link}
                        to='/jobdiva/syncs/new'
                    >
                        <FiPlus className='me-2' />New
                    </Button>
                </div>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Row>
                    <Col
                        md={12}
                    >
                        <Card>
                            <CardHeader>
                                <h5>JobDiva syncs list</h5>
                            </CardHeader>
                            <CardBody>
                                <TableContainer
                                    data={syncs}
                                    columns={columns}
                                    isLoading={isFetching || isLoading}
                                    notFoundText='No syncs available.'
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default SyncsList;