import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardBody,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import TimeOffRulesForm from 'pages/TimeOffRules/TimeOffRulesForm/TimeOffRulesForm';
import { useCreateTimeOffRuleMutation } from 'services/timeOff';
import { decodeErrors } from 'helpers';

const initialState = {
    start_year: 0,
    end_year: 0,
    days: 0,
    type: null,
    legal_entity_id: null,
};

const CreateTimeOffRule = () => {
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [createMutation, { isLoading }] = useCreateTimeOffRuleMutation();

    const onTimeOffFormChange = ({ name, value }) => setState(prevState => ({ ...prevState, [name]: value, }));

    const onCreateTimeOffRule = async (e) => {
        e.preventDefault();
        const body = { ...state };
        const { data, error } = await createMutation(body);
        if (error) {
            setErrors(error?.data?.errors ?? decodeErrors(error?.data?.data ?? []));
            return;
        }
        if (!data) return;
        navigate('/time-off-rules');
    }

    return (
        <div className="py-3">
            <PageHeader title='Create Time Off Rule' className='pb-3'>
                <Button color='primary' size='sm' tag={Link} to='/time-off-rules'>Back to list</Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardBody>
                        <TimeOffRulesForm
                            data={state}
                            errors={errors}
                            handleChange={onTimeOffFormChange}
                        />
                        <div className="text-end">
                            <Button color='success' onClick={onCreateTimeOffRule} disabled={isLoading}>
                                {isLoading ?
                                    <>
                                        <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                        Saving...
                                    </>
                                    :
                                    'Save'
                                }
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default CreateTimeOffRule;