import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store from "./store";
import { logEvents } from "./constants/firebase";

import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing(),new Sentry.Replay()],
    environment: process.env.NODE_ENV,
    replaysSessionSampleRate: process.env.SENTRY_TIME ?? 0.1,
    replaysOnErrorSampleRate: process.env.SENTRY_TIME_ERROR??1.0,    
    /* Set tracesSampleRate to 1.0 to capture 100%
     of transactions for performance monitoring.
     We recommend adjusting this value in production*/
    tracesSampleRate: process.env.SENTRY_TRACES?? 1.0,
  }); 
}



const root = ReactDOM.createRoot(document.getElementById("root"));
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

logEvents('homepage_access');
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </Provider>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
