import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'system-roles';

export const systemRolesApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getSystemRoles: builder.query({
            query: () => BASE_ENDPOINT,
            transformResponse: response => response.roles,
            providesTags: ['SystemRoles', { type: 'SystemRoles', id: 'LIST' }],
        }),
        getSystemRoleById: builder.query({
            query: (roleId) => `${BASE_ENDPOINT}/${roleId}`,
            providesTags: ['SystemRoles'],
        }),
        getPermissions: builder.query({
            query: () => `permissions`,
            transformResponse: response => response.permissions,
        }),
        updateSystemRole: builder.mutation({
            query: ({ roleId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${roleId}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['SystemRoles'],
        }),
        deleteSystemRole: builder.mutation({
            query: (roleId) => ({
                url: `${BASE_ENDPOINT}/${roleId}`,
                method: 'DELETE',
                headers: { 'Accept': 'application/json' },
            }),
            invalidatesTags: [{ type: 'SystemRoles', id: 'LIST' }],
        }),
    }),
});

export const {
    useGetSystemRolesQuery,
    useGetSystemRoleByIdQuery,
    useGetPermissionsQuery,
    useUpdateSystemRoleMutation,
    useDeleteSystemRoleMutation,
} = systemRolesApi;