import React from 'react';
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from 'react-loader-spinner';
import 'Widgets/LoadingIndicator/LoadingIndicator.css';

const LoadingIndicator = ({ isLoading = false }) => {
  const { promiseInProgress } = usePromiseTracker();
   return (
     (promiseInProgress || isLoading) &&
     <div style={{width: "100%",height: "100",display: "flex",justifyContent: "center",alignItems: "center"}}>
       <ThreeDots color="#806FDF" height="100" width="100" wrapperClass='custom-loader-wrapper' />
     </div>
  );
}

export default LoadingIndicator;
