import React from 'react';
import {
    Row,
    Col,
    FormGroup,
    FormFeedback,
    Label,
    Input,
} from 'reactstrap';
import SelectWithQuery from 'Components/SelectWithQuery/SelectWithQuery';
import { useGetLegalEntitiesQuery } from 'services/legalEntities';
import { useGetTimeOffTypesQuery } from 'services/timeOff';
import { customSelectStyles, selectErrorStyles } from 'constants/common';

const LEGALENTITIES_OPTS = { label: 'name', value: 'id' };
const TYPES_OPTS = { label: 'label', value: 'name' };

const TimeOffRulesForm = ({
    data = {},
    errors = {},
    handleChange,
}) => {
    const onInputChange = ({ target: { name, value } }) => handleChange({ name, value });
    const onSelectChange = (opt, { name }) => handleChange({ name, value: opt?.value });

    return (
        <Row>
            <Col md={6}>
                <FormGroup>
                    <Label>Time Off Type*</Label>
                    <SelectWithQuery
                        query={useGetTimeOffTypesQuery}
                        selected={data?.type}
                        dataOpts={TYPES_OPTS}
                        name='type'
                        placeholder='Select time off type'
                        styles={!!errors?.type ? selectErrorStyles : customSelectStyles}
                        handleChange={onSelectChange}
                    />
                    {!!errors?.type && <FormFeedback className='d-block'>{errors.type}</FormFeedback>}
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label>Legal Entity*</Label>
                    <SelectWithQuery
                        query={useGetLegalEntitiesQuery}
                        selected={data?.legal_entity_id}
                        dataOpts={LEGALENTITIES_OPTS}
                        name='legal_entity_id'
                        placeholder='Select legal entity'
                        styles={!!errors?.legal_entity_id ? selectErrorStyles : customSelectStyles}
                        handleChange={onSelectChange}
                    />
                    {!!errors?.legal_entity_id && <FormFeedback className='d-block'>{errors.legal_entity_id}</FormFeedback>}
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label>Days*</Label>
                    <Input
                        type='number'
                        value={data?.days}
                        name='days'
                        step={1}
                        onChange={onInputChange}
                        invalid={!!errors?.days}
                    />
                    {!!errors?.days && <FormFeedback className='d-block'>{errors.days}</FormFeedback>}
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label>Start Year</Label>
                    <Input
                        type='number'
                        value={data?.start_year}
                        name='start_year'
                        step={1}
                        onChange={onInputChange}
                        invalid={!!errors?.start_year}
                    />
                    {!!errors?.start_year && <FormFeedback className='d-block'>{errors.start_year}</FormFeedback>}
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label>End Year</Label>
                    <Input
                        type='number'
                        value={data?.end_year}
                        name='end_year'
                        step={1}
                        onChange={onInputChange}
                        invalid={!!errors?.end_year}
                    />
                    {!!errors?.end_year && <FormFeedback className='d-block'>{errors.end_year}</FormFeedback>}
                </FormGroup>
            </Col>
        </Row>
    );
};

export default TimeOffRulesForm;