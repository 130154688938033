import {
  format,
  parseISO,
  addDays,
  getDay,
  intervalToDuration,
} from "date-fns";

const SUNDAY = 0;
const SATURDAY = 6;

export const formatToAbbreviation = (
  dateString,
  dateFormat = "MMM dd, yyyy"
) => {
  if (!dateString) return null;
  const [year, month, day] = dateString.split("-");
  const [daySplitted] = day?.split(" ");
  return format(new Date(year, month - 1, daySplitted), dateFormat);
};

export const formatStringToDate = (date) =>
  typeof date === "string" ? parseISO(date) : date;

export const isWeekend = (startDate, days) => {
  const day = getDay(addDays(formatStringToDate(startDate), days));
  return day === SUNDAY || day === SATURDAY;
};

export const getIntervalForHours = (hours) => {
  hours *= 3600; // convert to hours
  return intervalToDuration({ start: 0, end: hours * 1000 });
};

/**
 * Method to format an interval
 * @param {*} hours string of interval, it must be a float number, eg '40.5'
 * @returns string formatted in HH:mm
 */

export const formatInterval = (hours = "", splitBy = ".") => {
  if (!hours) return;
  const [hour] = hours.split(splitBy);
  const parsedHour = parseFloat(hours);
  const { minutes } = getIntervalForHours(parsedHour);
  return `${hour}:${!!minutes ? minutes : "00"}`;
};

export const formatIntervalToHours = (hours = "") => {
  const parsedHour = new Date(hours * 1000).toISOString().slice(14, 19);
  return parsedHour;
};

export const getCurrentYearDates = () => {
  const now = new Date();
  const year = now.getFullYear();
  const firstDayOfYear = new Date(year, 0, 1);
  const firstDayOfNextYear = new Date(year + 1, 0, 1);
  const starPeriod = formatYearDate(firstDayOfYear);
  const endPeriod = formatYearDate(firstDayOfNextYear);
  return { starPeriod, endPeriod };
};

export const formatYearDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getCurrentWeekPeriod = () => {
  const now = new Date();
  const currentDay = now.getDay();
  const firstDayOfWeek = new Date(
    now.getTime() - currentDay * 24 * 60 * 60 * 1000
  );
  const lastDayOfWeek = new Date(
    firstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000
  );
  const year = firstDayOfWeek.getFullYear();
  const month = String(firstDayOfWeek.getMonth() + 1).padStart(2, "0");
  const firstDayOfMonth = String(firstDayOfWeek.getDate()).padStart(2, "0");
  const lastDayOfMonth = String(lastDayOfWeek.getDate()).padStart(2, "0");
  const starPeriod = `${year}-${month}-${firstDayOfMonth}`;
  const endPeriod = `${year}-${month}-${lastDayOfMonth}`;
  return { starPeriod, endPeriod };
};

export const getCurrentMonthDates = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // months are 0-indexed, so add 1 to get the current month
  const firstDayOfMonth = new Date(year, month - 1, 1);
  const firstDayOfNextMonth = new Date(year, month, 1);
  const starPeriod = formatDate(firstDayOfMonth);
  const endPeriod = formatDate(firstDayOfNextMonth);
  return { starPeriod, endPeriod };
};

export const formatDate = (date) => {

  if(!date)
    return '';

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getCurrentQuarter = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  let quarter;

  if (month >= 0 && month <= 2) {
    quarter = "Q1";
  } else if (month >= 3 && month <= 5) {
    quarter = "Q2";
  } else if (month >= 6 && month <= 8) {
    quarter = "Q3";
  } else {
    quarter = "Q4";
  }

  const startDate = new Date(`${year}-${(quarter.charAt(1) - 1) * 3 + 1}-01`);
  const endDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth() + 3,
    0
  );

  const starPeriod = formatYearDate(startDate);
  const endPeriod = formatYearDate(endDate);

  return { starPeriod, endPeriod };
};
