import { createToast } from 'helpers';
import React, { useState } from 'react';
import {
    Link,
    useNavigate,
} from 'react-router-dom';
import {
    Row,
    Col,
    Alert,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import Notifications from 'Widgets/Notifications/Notifications';
import { useCreateDepartmentMutation } from 'services/departments';
import { useGetAvailableNotificationsQuery } from 'services/notifications';

const initialState = {
    name: '',
    selectedNotifications: [],
    errors: {},
    errorMessage: '',
};

const CreateDepartment = () => {
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const { data: availableNotifications = [], isLoading: isLoadingNotifications } = useGetAvailableNotificationsQuery();
    const [createDepartment, { isLoading }] = useCreateDepartmentMutation();

    const handleChange = ({ target: { name, value } }) => setState(state => ({ ...state, [name]: value }));

    const setNotifications = (selectedNotifications = []) => setState(state => ({ ...state, selectedNotifications, }));

    const save = async (e) => {
        e.preventDefault();
        const { name, selectedNotifications: notifications } = state;
        const body = { name, notifications };
        const { data, error } = await createDepartment({ body });

        if (error) {
            const { status, data = {} } = error;
            if (status !== 422) {
                createToast({
                    type: 'error',
                    text: `Error saving department.\nError code: ${status}`,
                });
                return;
            }
            const errors = {};
            if (data?.data) {
                data.data.forEach(e => {
                    errors[e.attribute] = e.msg;
                });
            }

            setState(state => ({
                ...state,
                errorMessage: data?.status?.msg ?? '',
                errors: errors,
            }));
            return;
        }
        if(!data) return;

        navigate('/departments');
    }

    const {
        name,
        errorMessage,
        errors,
        selectedNotifications,
    } = state;

    return (
        <>
            <PageHeader title='Create Department' className='pb-3' containerClass='container'>
                <Button tag={Link} to='/departments'>Back to departments list</Button>
            </PageHeader>
            <div className="container pb-3">
                <Form onSubmit={save}>
                    <Row className="justify-content-center">
                        <Col md={12} centered='true'>
                            <Alert color="danger" isOpen={!!errorMessage}>
                                <h4 className="alert-heading">Error</h4>
                                <p className="mb-0">{errorMessage}</p>
                            </Alert>
                            <Card>
                                <CardHeader><h4>General Information</h4></CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={8}>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <Input
                                                    type='text'
                                                    value={name}
                                                    name='name'
                                                    onChange={handleChange}
                                                    invalid={!!errors.name}
                                                />
                                                <FormFeedback
                                                    className={errors.name ? 'd-block' : 'd-none'}
                                                >
                                                    {errors.name ?? ''}
                                                </FormFeedback>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='pt-3'>
                        <Col md={12}>
                            <Card>
                                <CardHeader>
                                    <h4>Notifications</h4>
                                </CardHeader>
                                <CardBody>
                                    <Notifications
                                        availableNotifications={availableNotifications}
                                        selectedNotifications={selectedNotifications}
                                        isDataLoading={isLoadingNotifications}
                                        setNotifications={setNotifications}
                                        isEditNotificationsOpen
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="text-end">
                                <Button
                                    className='mt-4 mb-4'
                                    color='primary'
                                    type='submit'
                                    disabled={isLoading}
                                >
                                    {isLoading ?
                                        <>
                                            <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                            Saving...
                                        </> :
                                        'Save'
                                    }
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    );
}

export default CreateDepartment;