import { zigattaApi } from "./zigattaApi";

export const rolesApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query({
            query: () => 'roles',
            transformResponse: response => response.roles,
            providesTags: ['Roles', { type: 'Roles', id: 'LIST' }]
        }),
        getRoleById: builder.query({
            query: (id) => `roles/${id}`,
            transformResponse: response => response.role,
            providesTags: ['Role']
        }),
        creatRole: builder.mutation({
            query: (payload) => ({
                url: 'roles',
                method: 'POST',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            }),
            transformResponse: response => response.role,
            invalidatesTags: ['Roles']
        }),
        updateRole: builder.mutation({
            query: ({ id, payload }) => ({
                url: `roles/${id}`,
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' }
            }),
            transformResponse: response => response.role,
            invalidatesTags: ['Roles', 'Role']
        }),
        deleteRole: builder.mutation({
            query: (id) => ({
                url: `roles/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Roles', id: 'LIST' }]
        }),
    }),
});

export const {
    useGetRolesQuery,
    useLazyGetRolesQuery,
    useGetRoleByIdQuery,
    useLazyGetRoleByIdQuery,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useCreatRoleMutation
} = rolesApi;