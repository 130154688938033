import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from 'store/slices/authSlice';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        const handleLogout = async () => {
            await dispatch(logout());
            return navigate('/login');
        };
        handleLogout()
    }, []);
};

export default Logout;