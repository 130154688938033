import React, { useEffect, useState, useRef, } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Button,
} from 'reactstrap';
import Notifications from 'Widgets/Notifications/Notifications';
import { createToast } from 'helpers';
import { useGetAvailableNotificationsQuery } from 'services/notifications';
import { useUpdateDepartmentMutation } from 'services/departments';

/****
 * DepartmentNotifications component is receiving props from Outlet Context,
 * props used must be and array of numbers named selectedNotifications,
 * a string named departmentName, and a function named setNotificationsArray
****/

const DepartmentNotifications = () => {
    const [isEditNotificationsActive, setIsEditNotificationsActive] = useState(false);
    const copyOfOriginalNotifications = useRef(null);
    const { notificationsProps: {
        selectedNotifications = [],
        departmentName = '',
        setNotificationsArray,
    } } = useOutletContext();
    const { id } = useParams();
    const { data: availableNotifications = [], isLoading: isLoadingNotifications } = useGetAvailableNotificationsQuery();
    const [updateDepartment, { isLoading }] = useUpdateDepartmentMutation();

    useEffect(() => {
        return () => {
            // reset original notifications to parent component on unmount
            if (isEditNotificationsActive && copyOfOriginalNotifications.current) {
                setNotificationsArray(copyOfOriginalNotifications.current);
            }
        };
    }, [isEditNotificationsActive]);

    //** Handlers **//

    const handleEditOrUpdateClick = () => {
        if (!isEditNotificationsActive) {
            copyOfOriginalNotifications.current = [...selectedNotifications];
            setIsEditNotificationsActive(true);
            return;
        }

        save();
    }

    const save = async () => {
        const body = { name: departmentName, notifications: selectedNotifications };
        const { data, error } = await updateDepartment({ body, departmentId: id });
        if (error) return console.error('Error updating department notifications: ', error);
        const newNotifications = data.notifications.map(({ id }) => id);
        setNotificationsArray(newNotifications);
        copyOfOriginalNotifications.current = null;
        createToast({
            type: 'success',
            text: 'Notifications updated successfully.'
        });
        setIsEditNotificationsActive(false);
    }

    return (
        <div>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardBody>
                            <div className="text-end pb-2">
                                <Button
                                    color={isEditNotificationsActive ? 'primary' : 'warning'}
                                    onClick={handleEditOrUpdateClick}
                                    disabled={isLoading}
                                >
                                    {isEditNotificationsActive ?
                                        isLoading ?
                                            <>
                                                <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                                                Updating...
                                            </> :
                                            'Update'
                                        :
                                        'Edit'
                                    } notifications
                                </Button>
                            </div>
                            <Notifications
                                availableNotifications={availableNotifications}
                                isDataLoading={isLoadingNotifications}
                                isEditNotificationsOpen={isEditNotificationsActive}
                                selectedNotifications={selectedNotifications}
                                setNotifications={setNotificationsArray}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default DepartmentNotifications;