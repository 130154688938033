export const permissions = {
    LIST_SYSTEMROLES: 'list-systemRoles',
    CREATE_SYSTEMROLES: 'create-systemRoles',
    UPDATE_SYSTEMROLES: 'update-systemRoles',
    DELETE_SYSTEMROLES: 'delete-systemRoles',
    LIST_USERS: 'list-users',
    CREATE_USERS: 'create-users',
    UPDATE_USERS: 'update-users',
    DELETE_USERS: 'delete-users',
    LIST_ROLES: 'list-roles',
    CREATE_ROLES: 'create-roles',
    UPDATE_ROLES: 'update-roles',
    DELETE_ROLES: 'delete-roles',
    LIST_RATECARDS: 'list-rateCards',
    CREATE_RATECARDS: 'create-rateCards',
    UPDATE_RATECARDS: 'update-rateCards',
    DELETE_RATECARDS: 'delete-rateCards',
    ALERT_RATECARDS: 'alert-rateCards',
    LIST_TAGS: 'list-tags',
    CREATE_TAGS: 'create-tags',
    UPDATE_TAGS: 'update-tags',
    DELETE_TAGS: 'delete-tags',
    LIST_DEPARTMENTS: 'list-departments',
    CREATE_DEPARTMENTS: 'create-departments',
    UPDATE_DEPARTMENTS: 'update-departments',
    DELETE_DEPARTMENTS: 'delete-departments',
    LIST_LEGALENTITIES: 'list-legalEntities',
    CREATE_LEGALENTITIES: 'create-legalEntities',
    UPDATE_LEGALENTITIES: 'update-legalEntities',
    DELETE_LEGALENTITIES: 'delete-legalEntities',
    CREATE_SALELEVELS: 'create-saleLevels',
    UPDATE_SALELEVELS: 'update-saleLevels',
    DELETE_SALELEVELS: 'delete-saleLevels',
    LIST_SALELEVELS: 'list-saleLevels',
    LIST_PROJECTS: 'list-projects',
    CREATE_PROJECTS: 'create-projects',
    UPDATE_PROJECTS: 'update-projects',
    DELETE_PROJECTS: 'delete-projects',
    ARCHIVE_PROJECTS: 'archive-projects',
    EFFORT_PROJECTS: 'effort-projects',
    ECONOMY_PROJECTS: 'economy-projects',
    UPDATE_ALL_PROJECTS: 'update_all-projects',
    CLONE_PROJECTS: 'clone-projects',
    LIST_PROJECTSTATUS: 'list-projectStatus',
    CREATE_PROJECTSTATUS: 'create-projectStatus',
    UPDATE_PROJECTSTATUS: 'update-projectStatus',
    DELETE_PROJECTSTATUS: 'delete-projectStatus',
    LIST_ALLOCATIONS: 'list-allocations',
    LIST_ALL_ALLOCATIONS: 'list_all-allocations',
    CREATE_ALLOCATIONS: 'create-allocations',
    UPDATE_ALLOCATIONS: 'update-allocations',
    DELETE_ALLOCATIONS: 'delete-allocations',
    ALERT_ALLOCATIONS: 'alert-allocations',
    LIST_TIMELOGS: 'list-timelogs',
    CREATE_TIMELOGS: 'create-timelogs',
    UPDATE_TIMELOGS: 'update-timelogs',
    DELETE_TIMELOGS: 'delete-timelogs',
    APPROVE_TIMELOGS: 'approve-timelogs',
    TEAM_TIMELOGS: 'team-timelogs',
    PAY_TIMELOGS: 'pay-timelogs',
    LIST_USERS_TIMELOGS: 'list_users-timelogs',
    CREATE_USERS_TIMELOGS: 'create_users-timelogs',
    UPDATE_USERS_TIMELOGS: 'update_users-timelogs',
    DELETE_USERS_TIMELOGS: 'delete_users-timelogs',
    RESET_TIMELOGS: 'reset-timelogs',
    LIST_EXPENSES: 'list-expenses',
    LIST_ALL_EXPENSES: 'list_all-expenses',
    CREATE_EXPENSES: 'create-expenses',
    UPDATE_EXPENSES: 'update-expenses',
    DELETE_EXPENSES: 'delete-expenses',
    LIST_TEAMS: 'list-teams',
    CREATE_TEAMS: 'create-teams',
    UPDATE_TEAMS: 'update-teams',
    DELETE_TEAMS: 'delete-teams',
    LIST_TIMEOFF: 'list-timeOff',
    CREATE_TIMEOFF: 'create-timeOff',
    UPDATE_TIMEOFF: 'update-timeOff',
    DELETE_TIMEOFF: 'delete-timeOff',
    ACCEPT_TIMEOFF: 'accept-timeOff',
    REJECT_TIMEOFF: 'reject-timeOff',
    LIST_REPORTS: 'list-reports',
    EXPENSES_BY_CATEGORY_REPORTS: 'expenses_by_category-reports',
    REIMBURSEMENTS_REPORTS: 'reimbursements-reports',
    EXPENSES_PACKAGING_REPORTS: 'expense_packaging-reports',
    UTILIZATION_REPORTS: 'utilization-reports',
    UNPAID_HOURS_REPORTS: 'unpaid_hours-reports',
    MILESTONES_REPORTS: 'milestones-reports',
    REVENUE_REPORTS: 'revenue-reports',
    INVOICING_REPORTS: 'invoicing-reports',
    PAYMENTS_REPORTS: 'payments-reports',
    TIME_OFF_REPORTS: 'time_off-reports',
    LIST_BENCHAVAILABILITY: 'bench_availability-reports',
    EMAIL_BENCHAVAILABILITY: 'bench_availability_email-reports',
    LIST_REMINDER_TIMELOGS: 'timelogs_reminder-reports',
    SEND_REMINDER_TIMELOGS: 'timelogs_reminder_email-reports',
    LIST_UTILIZATION_CHART_REPORTS: 'utilization-reports',
    PROJECT_STATUS_REPORTS: 'project_status-reports',
    INTEGRATION_JOBDIVA: 'integration-jobdiva',
    INTEGRATION_TEMPUS: 'integration-tempus',
    LIST_SETTINGS: 'list-settings',
    UPDATE_SETTINGS: 'update-settings',
    CREATE_VERSIONS: 'create-versions',
    UPDATE_VERSIONS: 'update-versions',
    DELETE_VERSIONS: 'delete-versions',
    LIST_REIMBURSEMENTREQUEST: 'list-reimbursementRequest',
    LIST_ALL_REIMBURSEMENTREQUEST: 'list_all-reimbursementRequest',
    CREATE_REIMBURSEMENTREQUEST: 'create-reimbursementRequest',
    UPDATE_REIMBURSEMENTREQUEST: 'update-reimbursementRequest',
    DELETE_REIMBURSEMENTREQUEST: 'delete-reimbursementRequest',
    ACCEPT_REIMBURSEMENTREQUEST: 'accept-reimbursementRequest',
    REJECT_REIMBURSEMENTREQUEST: 'reject-reimbursementRequest',
    LIST_BILLS: 'list-bills',
    CREATE_BILLS: 'create-bills',
    UPDATE_BILLS: 'update-bills',
    DELETE_BILLS: 'delete-bills',
    LIST_EXPENSESCATEGORIES: 'list-expensesCategories',
    CREATE_EXPENSESCATEGORIES: 'create-expensesCategories',
    UPDATE_EXPENSESCATEGORIES: 'update-expensesCategories',
    DELETE_EXPENSESCATEGORIES: 'delete-expensesCategories',
    LIST_ALL_REIMBURSEMENTCATEGORIES: 'list_all-reimbursementCategories',
    LIST_REIMBURSEMENTCATEGORIES: 'list-reimbursementCategories',
    CREATE_REIMBURSEMENTCATEGORIES: 'create-reimbursementCategories',
    UPDATE_REIMBURSEMENTCATEGORIES: 'update-reimbursementCategories',
    DELETE_REIMBURSEMENTCATEGORIES: 'delete-reimbursementCategories',
    BROWSER_GENERAL: 'browser-general',
    LIST_SENIORITYTITLES: 'list-seniorityTitles',
    CREATE_SENIORITYTITLES: 'create-seniorityTitles',
    UPDATE_SENIORITYTITLES: 'update-seniorityTitles',
    DELETE_SENIORITYTITLES: 'delete-seniorityTitles',
    LIST_HOLIDAYS: 'list-holidays',
    CREATE_HOLIDAYS: 'create-holidays',
    UPDATE_HOLIDAYS: 'update-holidays',
    DELETE_HOLIDAYS: 'delete-holidays',
    LIST_TIMEOFFRULES: 'list-timeOffRules',
    CREATE_TIMEOFFRULES: 'create-timeOffRules',
    UPDATE_TIMEOFFRULES: 'update-timeOffRules',
    DELETE_TIMEOFFRULES: 'delete-timeOffRules',
};