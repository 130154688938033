import React from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Label,
} from 'reactstrap';
import { useOutletContext } from 'react-router-dom';

const GeneralInformation = () => {
    const { sales } = useOutletContext();       
    return (
        <div>
            <Row>
                <Col md="12">
                    <Card> 
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Name</Label>
                                        <p>{sales?.name ?? "-"}</p>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>          
        </div>
    );
};

export default GeneralInformation;