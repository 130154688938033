import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query';
import { zigattaApi } from "services/zigattaApi";
import authSlice from "store/slices/authSlice";
import userSlice from "store/slices/userSlice";

const store = configureStore({
  reducer: {
    [zigattaApi.reducerPath]: zigattaApi.reducer,  
    auth: authSlice,
    app: userSlice
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(zigattaApi.middleware),
});

setupListeners(store.dispatch);

export default store;
