import { formatToAbbreviation } from 'helpers';

export function sortStartsByDate(arrayToSort = [], sortBy = '') {
    let sortedArray = [];

    switch(sortBy) {
        case 'ASC':
            sortedArray = arrayToSort.sort((a, b) => formatToAbbreviation(a.start_date_formatted, 'yyyyMMdd') - formatToAbbreviation(b.start_date_formatted, 'yyyyMMdd'));
            break;
        
        case 'DESC':
            sortedArray = arrayToSort.sort((a, b) => formatToAbbreviation(b.start_date_formatted, 'yyyyMMdd') - formatToAbbreviation(a.start_date_formatted, 'yyyyMMdd'));
            break;

        default:
            sortedArray = arrayToSort;
            break;
    }

    return sortedArray;
}

export function statusColor(status = ''){
    const lowerCaseStatus = status.toLocaleLowerCase();
    const colors = {
        pending: 'warning',
        confirmed: 'success',
        done: 'success',
        new: 'success',
        cancelled: 'danger',
        update: 'info',
    };
    return `text-${colors[lowerCaseStatus] ?? 'black'}`;
}