import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    Button,
    Nav,
    NavItem,
    NavLink,
} from 'reactstrap';
import { FaPlus } from 'react-icons/fa';
import Table from 'Components/Table/Table';
import PageHeader from 'Components/PageHeader/PageHeader';
import { useGetLegalEntitiesQuery } from 'services/legalEntities';
import { useGetHolidaysQuery } from 'services/holidays';
import { canCreateHolidays } from 'constants/common';

const initialFilters = {
    legal_entity_id: 1,
    display_all: false,
};

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Date',
        accessor: 'formatted_date',
    }
];

const ListHolidays = () => {
    const [filters, setFilters] = useState(initialFilters);
    const [holidays, setHolidays] = useState([]);
    const navigate = useNavigate();
    const { data: dataHolidays, isFetching } = useGetHolidaysQuery(filters);
    const { data: legalEntities = [] } = useGetLegalEntitiesQuery();

    useEffect(() => {
        if (!dataHolidays) return;
        setHolidays(dataHolidays);
    }, [dataHolidays]);

    const changeLegalEntity = ({ target: { dataset } }) =>
        setFilters(prevFilters => ({ ...prevFilters, legal_entity_id: parseInt(dataset?.id) }));

    const handleRowClick = ({ row: { original } }) => navigate(`${original.id}`);

    const onCheckboxChange = ({ target: { checked } }) =>
        setFilters(prevFilters => ({ ...prevFilters, display_all: checked, }));

    return (
        <div className="py-3">
            <PageHeader title='Holidays' className='pb-3'>
                <Button color='primary' size='sm' tag={Link} to='create' disabled={!canCreateHolidays}><FaPlus className='me-1' />New</Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardBody>
                        <Nav tabs>
                            {
                                legalEntities.map(legalEntity => {
                                    return (
                                        <NavItem key={`entity-${legalEntity.id}`}>
                                            <NavLink
                                                className='cursor-pointer'
                                                active={filters.legal_entity_id === legalEntity.id}
                                                onClick={changeLegalEntity}
                                                data-id={legalEntity.id}
                                            >
                                                {legalEntity.name}
                                            </NavLink>
                                        </NavItem>
                                    );
                                })
                            }
                        </Nav>
                        <Row className='py-2'>
                            <Col md={6}>
                                <FormGroup check inline>
                                    <Label check>Show all history</Label>
                                    <Input type='checkbox' checked={filters.display_all} onChange={onCheckboxChange} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Table
                            className='clickable-rows'
                            data={holidays}
                            columns={columns}
                            isLoading={isFetching}
                            notFoundText='No holidays found.'
                            rowClick={handleRowClick}
                        />
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default ListHolidays;