import React from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import NavTab from 'Components/NavTab/NavTab';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import { useGetSeniorityTitleByIdQuery } from 'services/seniorities';
import { canUpdateSeniorityTitles } from 'constants/common';

const ShowSeniorityTitle = () => {
    const { id } = useParams();
    const { data: seniorityTitle = {}, isFetching } = useGetSeniorityTitleByIdQuery(id);

    const generalInformationProps = {
        name: seniorityTitle?.name || '',
    };
    
    const usersProps = {
        users: seniorityTitle?.users ?? [],
    };

    const links = [
        {
            name: 'General Information',
            path: `/seniority-titles/${id}`,
            isVisible: true
        },
        {
            name: 'Users',
            path: `/seniority-titles/${id}/users`,
            isVisible: true
        }
    ];

    return (
        <div>
            <PageHeader title={`Seniority Title: ${seniorityTitle?.name ?? '-'}`} className='pb-3'>
                <div>
                    <Button className="me-2" color='warning' tag={Link} to={`/seniority-titles/${id}/edit`} disabled={!canUpdateSeniorityTitles}>
                        Edit
                    </Button>
                    <Button tag={Link} to='/seniority-titles'>Back to list</Button>
                </div>
            </PageHeader>
            <LoadingIndicator isLoading={isFetching} />
            <div className="container pb-3">
                <NavTab links={links} />
                <Outlet context={{ generalInformationProps, usersProps, }} />
            </div>
        </div>
    );
}

export default ShowSeniorityTitle;