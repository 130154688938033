import { zigattaApi } from "./zigattaApi";
import { generateDirectDownload } from "helpers";

export const downloadsApi = zigattaApi.injectEndpoints({
  endpoints: (builder) => ({
    downloadRateCardsTemplate: builder.mutation({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: "roles-rate-cards/template",
          responseType: "blob",
          headers: {
            "Content-Disposition":
              "attachment;filename=RoleRateCardTemplate.csv",
            "Content-Type": "text/csv",
          },
          responseHandler: (response) => response.blob(),
        });
        generateDirectDownload({
          response: result.data,
          fileName: "RoleRateCardTemplate.csv",
        });
        return { data: null };
      },
    }),
    downloadJobdivaSyncFile: builder.mutation({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `jobdiva/files/${args.id}/download`,
          responseType: "blob",
          headers: {
            "Content-Disposition": "attachment;",
            "Content-Type": "text/csv",
          },
          responseHandler: (response) => response.blob(),
        });
        generateDirectDownload({
          response: result.data,
          fileName: `${args.file}_${Date.now()}.csv`,
        });
        return { data: null };
      },
    }),
    downloadMilestoneDocument: builder.mutation({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const result = await baseQuery({
          url: `completion-documents/${args.milestoneId}/download`,
          responseType: "blob",
          headers: {
            "Content-Disposition": "attachment;",
            "Content-Type": "application/pdf",
          },
          responseHandler: (response) => response.blob(),
        });
        generateDirectDownload({
          response: result.data,
          fileName: `CompletionDocument_${args.name}.pdf`,
        });
        return { data: null };
      },
    }),
    downloadReimbursementFile: builder.mutation({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const extension = args?.reimbursement[args?.fileType]
          ?.split(".")
          .at(-1);
        const getContentType = {
          file: `application/${extension}`,
          xml: `application/${extension};text/${extension};`,
          image: `image/${extension}`,
        };
        const contentType = getContentType[args?.fileType];
        const result = await baseQuery({
          url: `reimbursement-requests/${args?.reimbursement?.id}/download`,
          method: "GET",
          responseType: "blob",
          headers: {
            "Content-Disposition": "attachment;",
            "Content-Type": contentType,
          },
          params: {
            type: args?.fileType,
          },
          responseHandler: (response) => response.blob(),
        });
        generateDirectDownload({
          response: result.data,
          fileName: `receipt_${
            args?.reimbursement?.user?.name ?? args?.reimbursement?.category
          }_${Date.now()}.${extension}`,
        });
        return { data: null };
      },
    }),

    downloadInvoiceFile: builder.mutation({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const extension = args?.invoice[args?.fileType]?.split(".").at(-1);
        const getContentType = {
          file: `application/${extension}`,
          xml: `application/${extension};text/${extension};`,
          image: `image/${extension}`,
        };
        const contentType = getContentType[args?.fileType];
        const result = await baseQuery({
          url: `expenses/${args?.invoice?.expense_id}/invoices/${args?.invoice?.id}`,
          method: "GET",
          responseType: "blob",
          headers: {
            "Content-Disposition": "attachment;",
            "Content-Type": contentType,
          },
          params: {
            type: args?.fileType,
          },
          responseHandler: (response) => response.blob(),
        });
        generateDirectDownload({
          response: result.data,
          fileName: `receipt_${
            args?.invoice?.file ?? args?.invoice?.category
          }_${Date.now()}.${extension}`,
        });
        return { data: null };
      },
    }),

    downloadReimbursementFileURL: builder.mutation({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const extension = args?.reimbursement[args?.fileType]
          ?.split(".")
          .at(-1);
        const getContentType = {
          file: `application/${extension}`,
          xml: `application/${extension};text/${extension};`,
          image: `image/${extension}`,
        };
        const contentType = getContentType[args?.fileType];
        const result = await baseQuery({
          url: `reimbursement-requests/${args?.reimbursement?.id}/display`,
          method: "GET",
          params: {
            type: args?.fileType,
          },
        });
        window.open(result?.data?.url, "_blank");
        return { response: null };
      },
    }),
  }),
});

export const {
  useDownloadRateCardsTemplateMutation,
  useDownloadJobdivaSyncFileMutation,
  useDownloadMilestoneDocumentMutation,
  useDownloadReimbursementFileMutation,
  useDownloadInvoiceFileMutation,
  useDownloadReimbursementFileURLMutation,
} = downloadsApi;
