/* TODO
@MOVE: This file should be moved to a common folder
 */

import ContentLoader from "react-content-loader";
import { ThreeDots,MutatingDots } from 'react-loader-spinner';
import React from "react";
import Skeleton from '../../Widgets/Skeleton/Skeleton';
export function ExpensesByCategoryLoader() {
  return <ContentLoader viewBox="0 0 300 40">
    <rect x="10" y="5" rx="4" ry="4" width="290" height="10" />
    <rect x="10" y="28" rx="3" ry="3" width="290" height="10" />
  </ContentLoader>
}

export function TableLoader() {
  return (
    <div style={{width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <ThreeDots color="#102F5A" height="100" width="100" />
    </div>
 );
}

export function LazyLoader() {
  return (
    <div style={{width: "100%", height: "100", display: "flex", justifyContent: "center", alignItems: "center"}}>
      <MutatingDots
                    height="100"
                    width="100"
                    color="#5F49D9"
                    secondaryColor="#4040A7"
                    radius="12.5"
                    ariaLabel="mutating-dots-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
    </div>
 );
  }
 export function SkeletonLoader() {
   return (
    <Skeleton></Skeleton>);
}