export const ACTIONS = {
    SET_SYNC: 'set-sync',
    ONCHECKED_DETAIL: 'onchecked-detail',
    TOGGLE_CONFIRMSYNCMODAL: 'toggle-confirmsyncmodal',
    TOGGLE_CANCELSYNCMODAL: 'toggle-cancelsyncmodal',
    TOGGLE_EDITUSERMODAL: 'toggle-editusermodal',
    ONCLICK_EDITUSER: 'onclick-edituser',
    ONCLOSED_EDITUSERMODAL: 'onclosed-editusermodal',
    UPDATE_USERDATAMODAL: 'update-userdatamodal',
    TOGGLE_EDITALLOCATIONMODAL: 'toggle-editallocationmodal',
    ONCLICK_EDITALLOCATION: 'onclick-editallocation',
    ONCLOSED_EDITALLOCATIONMODAL: 'onclosed-editallocationmodal',
    UPDATE_ALLOCATIONDATA: 'update-allocationdata',
    UPDATE_ALLOCATIONRATECARDDATA: 'update-allocationratecarddata',
};

export const syncPendingDetailReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_SYNC: {
            const { sync } = action;
            return { ...state, sync };
        }
        case ACTIONS.ONCHECKED_DETAIL: {
            const { userIndex, allocationIndex, checked } = action.payload;
            const copyOfSyncDetails = [...state.sync.details];
            copyOfSyncDetails[userIndex].data.allocation[allocationIndex].isChecked = checked;
            return {
                ...state,
                sync: {
                    ...state.sync,
                    details: [...copyOfSyncDetails],
                },
            }
        }
        case ACTIONS.TOGGLE_CONFIRMSYNCMODAL: {
            return { ...state, isConfirmModalOpen: !state.isConfirmModalOpen, };
        }
        case ACTIONS.TOGGLE_CANCELSYNCMODAL: {
            return { ...state, isCancelModalOpen: !state.isCancelModalOpen, };
        }
        case ACTIONS.TOGGLE_EDITUSERMODAL: {
            return { ...state, isEditUserModalOpen: !state.isEditUserModalOpen, };
        }
        case ACTIONS.ONCLICK_EDITUSER: {
            const { userIndexEditing } = action;
            const user = state.sync.details[userIndexEditing];
            const userDataForModal = {
                name: user.name,
                email: user.email,
                hire_type: user.hire_type,
                hired_at: user.hired_at,
                role_id: user.role_id,
                department_id: user.department_id,
                week_hours: user.week_hours,
                seniority: user.seniority,
                reporting_manager_id: user.reporting_manager_id,
                recruiter_id: user.recruiter_id,
                employer_id: user.employer_id,
                jobdiva_candidate_id: user.jobdiva_candidate_id,
                jobdiva_email: user.jobdiva_email,
                resume: user.resume,
            };
            return { ...state, userDataForModal, userIndexEditing, isEditUserModalOpen: true, };
        }
        case ACTIONS.ONCLOSED_EDITUSERMODAL: {
            const { userDataForModal } = action;
            return { ...state, userDataForModal, userIndexEditing: null, };
        }
        case ACTIONS.UPDATE_USERDATAMODAL: {
            const { name, value } = action;
            const copyOfSyncDetails = [...state.sync.details];
            let user = copyOfSyncDetails[state.userIndexEditing];
            user[name] = value;
            return {
                ...state,
                sync: {
                    ...state.sync,
                    details: [...copyOfSyncDetails],
                },
                userDataForModal: {
                    ...state.userDataForModal,
                    [name]: value,
                },
            };
        }
        case ACTIONS.TOGGLE_EDITALLOCATIONMODAL: {
            return { ...state, isEditAllocationModalOpen: !state.isEditAllocationModalOpen, };
        }
        case ACTIONS.ONCLICK_EDITALLOCATION: {
            const { userIndexEditing, allocationIndexEditing } = action;
            const allocation = {...state.sync.details[userIndexEditing].data.allocation[allocationIndexEditing]};
            const allocationDataForModal = {
                project_id: allocation?.project_id,
                daily_hours: allocation?.daily_hours,
                jobdiva_end_date: allocation?.jobdiva_end_date,
                jobdiva_start_date: allocation?.jobdiva_start_date,
                role_id: allocation?.role_id,
                rate_card: {
                    jobdiva_bill_rate: allocation?.rate_card?.jobdiva_bill_rate,
                    jobdiva_pay_rate: allocation?.rate_card?.jobdiva_pay_rate,
                    project_id: allocation?.rate_card?.project_id,
                },
            };
            return {
                ...state,
                userIndexEditing,
                allocationIndexEditing,
                allocationDataForModal,
                isEditAllocationModalOpen: true,
            };
        }
        case ACTIONS.ONCLOSED_EDITALLOCATIONMODAL: {
            const { allocationDataForModal } = action;
            return { ...state, allocationDataForModal, userIndexEditing: null, allocationIndexEditing: null, };
        }
        case ACTIONS.UPDATE_ALLOCATIONDATA: {
            const { name, value } = action;
            const copyOfSyncDetails = [...state.sync.details];
            let user = copyOfSyncDetails[state.userIndexEditing];
            let allocation = user.data.allocation[state.allocationIndexEditing];
            allocation[name] = value;
            return {
                ...state,
                sync: {
                    ...state.sync,
                    details: [...copyOfSyncDetails],
                },
                allocationDataForModal: {
                    ...state.allocationDataForModal,
                    [name]: value,
                },
            };
        }
        case ACTIONS.UPDATE_ALLOCATIONRATECARDDATA: {
            const { name, value } = action;
            let copyOfSyncDetails = [...state.sync.details];
            let user = copyOfSyncDetails[state.userIndexEditing];
            let allocation = user.data.allocation[state.allocationIndexEditing];
            allocation.rate_card = {...allocation.rate_card, [name]: value};
            return {
                ...state,
                sync: {
                    ...state.sync,
                    details: [...copyOfSyncDetails],
                },
                allocationDataForModal: {
                    ...state.allocationDataForModal,
                    rate_card: {
                        ...state.allocationDataForModal.rate_card,
                        [name]: value,
                    },
                },
            };
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}