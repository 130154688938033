import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Label,
    Input,
    ListGroup,
    ListGroupItem,
    Alert,
    Button,
    Badge,
} from 'reactstrap';
import { FaUserTag } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import PageHeader from 'Components/PageHeader/PageHeader';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import { useGetSeniorityTitlesQuery } from 'services/seniorities';
import { canCreateSeniorityTitles } from 'constants/common';

const ListSeniorityTitles = () => {
    const [filter, setFilter] = useState('');
    const { data: seniorityTitles = [], isFetching } = useGetSeniorityTitlesQuery();

    const filteredSeniorities = useMemo(() => {
        if (!filter) return seniorityTitles;
        return seniorityTitles.filter(({ name }) => name?.toLowerCase().includes(filter?.toLowerCase()))
    }, [filter, seniorityTitles]);

    const onFiltersInputChange = ({ target: { value } }) => setFilter(value);
    const clearFilters = () => setFilter('');

    return (
        <div>
            <PageHeader title='Seniority Titles' containerClass='container' className='pb-3'>
                <Button color='primary' tag={Link} to='create' disabled={!canCreateSeniorityTitles}>Add new</Button>
            </PageHeader>
            <div className="container pb-3">
                <Row>
                    <Col md="8">
                        <LoadingIndicator isLoading={isFetching} />
                        {
                            !!seniorityTitles.length ?
                                <ListGroup>
                                    {filteredSeniorities.map(seniority =>
                                        <ListGroupItem key={`seniority-${seniority.id}`} tag={Link} to={`/seniority-titles/${seniority.id}`}>
                                            <div className="d-flex justify-content-between">
                                                <h6 className="mb-0 link-primary">{seniority.name}</h6>
                                                <Badge
                                                    className='me-2'
                                                    color='primary'
                                                    title='Total users count'
                                                    pill
                                                >
                                                    <FaUserTag /> {seniority.total_users}
                                                </Badge>
                                            </div>
                                        </ListGroupItem>
                                    )}
                                </ListGroup>
                                :
                                <Alert color="warning" className="mb-0">
                                    <small>No seniority titles have been created.</small>
                                </Alert>
                        }
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardHeader className='d-flex justify-content-between align-items-center'>
                                <h4><FiSearch className='me-2' /> Filter</h4>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type='text' value={filter} name='name' onChange={onFiltersInputChange} />
                                </FormGroup>
                                <Button block color='secondary' type='button' disabled={!filter} onClick={clearFilters}>Reset</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        </div>
    );
}

export default ListSeniorityTitles;