import React from 'react';
import UserInviteForm from 'Components/UserInviteForm/UserInviteForm';
import CustomModal from 'Components/Modal/Modal';

const EditUserModal = ({
    data = {
        name: '',
        email: '',
        hire_type: 'full-time',
        hired_at: '',
        role_id: '',
        department_id: '',
        week_hours: 40,
        seniority: 'trainee',
        reporting_manager_id: '',
        recruiter_id: '',
        employer_id: '',
        jobdiva_candidate_id: '',
        jobdiva_email: '',
        resume: '',
    },
    isOpen,
    onClosed,
    handleChange,
    toggle,
}) => {
    return(
        <>
        <CustomModal
            header='Edit user'
            size='lg'
            isOpen={isOpen}
            onClosed={onClosed}
            handleToggle={toggle}
            centered
        >
            <UserInviteForm 
                data={ { ...data, errors: {} } }
                handleChange={handleChange}
                hideDateOfBirth
                hideSendInvite
                hidePasswordAuth
                hideSystemRole
                hideProfilePicture
            />
        </CustomModal>
        </>
    );
}
 
export default EditUserModal;