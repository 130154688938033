import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  Alert,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from 'reactstrap';
import CustomModal from 'Components/Modal/Modal';
import PageHeader from 'Components/PageHeader/PageHeader';
import { checkPermission } from 'helpers';
import { useDeleteSalesMutation, useLazyGetSalesByIdQuery, useUpdateSalesMutation } from 'services/sales';

const canDeleteSales = checkPermission('DELETE_SALELEVELS');

const initialState = {
  name: '',
  errors: {},
  errorMessage: '',
  isDeleteModalOpen: false,
};

const EditSales = () => {
  const [state, setState] = useState(initialState);
  const { id } = useParams();
  const navigate = useNavigate();
  const [getSale] = useLazyGetSalesByIdQuery();
  const [updateSale, { isLoading }] = useUpdateSalesMutation();
  const [deleteSale] = useDeleteSalesMutation();

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await getSale(id);
      if (error) return;
      const { name } = data;
      setState(state => ({
        ...state,
        name,
      }));
    }
    if (id) getData();
  }, [id]);

  const handleChange = ({ target: { name, value } }) => setState(state => ({ ...state, [name]: value, }));

  const onUpdateEvent = async (e) => {
    e.preventDefault();
    const { name } = state;
    const body = { name };
    
    if (!id) return;
    const { error } = await updateSale({ body, saleId: id });
    if (error) {
      const { status, data = {} } = error;
      if (status !== 422) return;
      const errors = data.data?.reduce((prev, current) => {
        prev = { ...prev, [current.attribute]: current.msg };
        return prev;
      }, {});
      setState(state => ({
        ...state,
        errorMessage: data.status?.msg ?? '',
        errors,
      }));
      return;
    }
    return navigate(`/sale-levels/${id}`);
  }

  const onDeleteEvent = async (e) => {
    e.preventDefault();
    if (!id) return;
    const { error } = await deleteSale(id);
    if (error) {
      const { data = {}, status } = error;
      if (status !== 422) return;
      setState(state => ({
        isDeleteModalOpen: false,
        errorMessage: data?.message ?? '',
      }));
      return;
    }
    return navigate('/sale-levels');
  }

  const toggleDeleteModal = () => setState(prevState => ({ ...prevState, isDeleteModalOpen: !prevState.isDeleteModalOpen }));

  const deleteModalFooter = (
    <>
      <Button onClick={toggleDeleteModal}>Cancel</Button>
      <Button color='danger' onClick={onDeleteEvent}>Delete sale</Button>
    </>
  );

  const { isDeleteModalOpen, errors, errorMessage, name } = state;

  return (
    <div>
      <CustomModal
        isOpen={isDeleteModalOpen}
        handleToggle={toggleDeleteModal}
        header='Delete Sale'
        footer={deleteModalFooter}
        centered
      >
        <p className="m-0">If you delete this sale, it will be permanently removed.</p>
      </CustomModal>

      <PageHeader className='pb-3' title='Edit Sale' containerClass='container'>
        <div>
          <Button className="me-2" color='danger' onClick={toggleDeleteModal} disabled={!canDeleteSales}>Delete</Button>
          <Button tag={Link} to={`/sale-levels/${id}`}>Back to detail</Button>
        </div>
      </PageHeader> 
      <div className="container pb-3">
        <Form onSubmit={onUpdateEvent}>
          <Row className="justify-content-center">
            <Col md='8' centered='true'>
              <Alert color="danger" isOpen={!!errorMessage}>
                <h4 className="alert-heading">Error</h4>
                <p className="mb-0">{errorMessage}</p>
              </Alert>
              <Card>
                <CardHeader><h4>General Information</h4></CardHeader>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label>Name</Label>
                        <Input type='text' onChange={handleChange} value={name} name='name' invalid={!!errors.name} />
                        {!!errors.name && <FormFeedback>{errors.name}</FormFeedback>}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <div className="text-end">
                <Button disabled={isLoading} className='mt-4 mb-4' color='primary' type='submit'>
                  {isLoading ?
                    <>
                      <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
                      Saving...
                    </> :
                    'Save'}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default EditSales;