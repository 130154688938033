// this helper will work only with an array of objects
// and this objects must have name and id as keys
const createSelectOptions = (array = [], name = '') => array.map(({ name: label, id: value }) => ({ label, value, name, }));

/**
 * Method to create the array of options for Select components
 * @param {*} array array of objects
 * @param {*} object you can pass the property that you want for value and label even an extra property if it is the case 
 * @returns array of options [{ label, value }]
 */
const defaultProperties = { value: 'id', label: 'name', extra: null };
export const getSelectOptions = (array = [], paramProperties = {}) => {
    const properties = { ...defaultProperties, ...paramProperties };
    const { value, label, extra } = properties;
    let options = [];
    if (extra) {
        options = array.map(item => ({
            value: item[value],
            label: item[label],
            [extra]: item[extra]
        }));
    }
    else {
        options = array.map(item => ({
            value: item[value],
            label: item[label],
        }));
    }
    return options;
};


export const getOptionSelected = (objectSelect) => {
    const { id, name } = objectSelect;
    return { value: id, label: name };
};

export default createSelectOptions;