import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    FormGroup,
    Label,
    Button,
    Badge,
} from 'reactstrap';
import MultiSelect from '@khanacademy/react-multi-select';
import { FiChevronRight, FiChevronLeft, FiPlus } from 'react-icons/fi';
import TableWithCustomPagination from 'Components/TableWithCustomPagination/TableWithCustomPagination';
import { formatToAbbreviation, getSelectOptions } from 'helpers';
import { useGetRolesQuery } from 'services/roles';
import { useGetSenioritiesQuery } from 'services/seniorities';
import { useLazyGetRolesRateCardsQuery } from 'services/rolesRateCards';
import { useDownloadRateCardsTemplateMutation } from 'services/downloads';

const initialState = {
    rate_cards: [],
    version: {},
    total: 40,
    is_active: false,
    active_version_id: null,
    next_version_id: null,
    prev_version_id: null,
};

const initialFilters = {
    selectedRoles: [],
    selectedSeniorities: [],
    page: 1,
    pageSize: 40,
    version: null,
}

const RolesRateCardsList = () => {
    const [state, setState] = useState(initialState);
    const [filters, setFilters] = useState(initialFilters);
    const { data: dataRoles = [] } = useGetRolesQuery();
    const { data: dataSeniorities = [] } = useGetSenioritiesQuery();
    const [getRolesRateCards, { isFetching: isRateCardsLoading }] = useLazyGetRolesRateCardsQuery();
    const [downloadRateCardsTemplate, { isLoading: isDownloadingTemplate }] = useDownloadRateCardsTemplateMutation();
    const roles = getSelectOptions(dataRoles);
    const seniorities = getSelectOptions(dataSeniorities, { value: 'name', label: 'label', });

    useEffect(() => {
        const getData = async () => {
            const { selectedRoles: role, selectedSeniorities: seniority, page, version: id, pageSize: per_page } = filters;
            const params = { role, seniority, page, per_page };
            if (id) params.id = id;
            const { data } = await getRolesRateCards(params);
            if (!data) return;
            const {
                version,
                rate_cards,
                active_version_id,
                is_active,
                next_version_id,
                prev_version_id,
            } = data;
            setState(prevState => ({
                ...prevState,
                version,
                rate_cards: rate_cards?.data,
                total: rate_cards.total,
                active_version_id,
                is_active,
                next_version_id,
                prev_version_id
            }));
        }

        getData();

        // eslint-disable-next-line
    }, [filters]);

    const handleRateCardVersionChange = (id) => setFilters(prevFilters => ({ ...prevFilters, version: id, page: 1, }));

    const handleMultiSelectChange = (selected, name) => setFilters(prevFilters => ({ ...prevFilters, [name]: selected, page: 1, }));

    const onPageChange = (page) => setFilters(prevFilters => ({ ...prevFilters, page: page + 1 }));

    const onPagesizeChange = (pageSize) => setFilters(prevFilters => ({ ...prevFilters, page: 1, pageSize }));

    const resetFilters = () => setFilters(initialFilters);

    const columns = [
        {
            Header: () => (
                <>
                    Role
                    <MultiSelect
                        overrideStrings={{
                            selectSomeItems: "Select roles",
                            allItemsAreSelected: "All roles"
                        }}
                        options={roles}
                        selected={filters.selectedRoles}
                        onSelectedChanged={(selected) => handleMultiSelectChange(selected, 'selectedRoles')}
                    />
                </>
            ),
            accessor: 'role',
            Cell: ({ row: { original } }) => (original.role?.name),
            disableSortBy: true,
        },
        {
            Header: () => (
                <>
                    Label
                    <MultiSelect
                        overrideStrings={{
                            selectSomeItems: "Select seniorities",
                            allItemsAreSelected: "All seniorities"
                        }}
                        options={seniorities}
                        selected={filters.selectedSeniorities}
                        onSelectedChanged={(selected) => handleMultiSelectChange(selected, 'selectedSeniorities')}
                    />
                </>
            ),
            accessor: 'label',
            Cell: ({ row: { original } }) => (original.formatted_seniority),
            disableSortBy: true,
        },
        {
            Header: 'Bill Rate',
            accessor: 'bill_rate',
            Cell: ({ row: { original } }) => (`${original.bill_rate ?? 'Not set'} ${original.bill_rate_currency?.code || ''}`),
        },
        {
            Header: 'Pay Rate',
            accessor: 'pay_rate',
            Cell: ({ row: { original } }) => (`${original.pay_rate ?? 'Not set'} ${original.pay_rate_currency?.code || ''}`),
        },
        {
            Header: () => (
                <Button
                    color='secondary'
                    size='sm'
                    onClick={resetFilters}
                >
                    Reset filters
                </Button>
            ),
            accessor: 'reset',
            disableSortBy: true,
        }
    ];

    return (
        <div>
            <div className="header">
                <div className="container-fluid d-flex justify-content-between align-items-center">
                    <h1>Rate cards</h1>
                    <div>
                        {
                            state.active_version_id &&
                            state.version?.id !== state.active_version_id &&
                            <Button
                                className='me-2'
                                color='zigatta'
                                size='sm'
                                onClick={e => handleRateCardVersionChange(state.active_version_id)}
                            >
                                Current Rate Card
                            </Button>
                        }
                        <Button
                            className='me-2'
                            color='secondary'
                            size='sm'
                            onClick={e => handleRateCardVersionChange(state.prev_version_id)}
                            disabled={!state.prev_version_id}
                        >
                            <FiChevronLeft />
                        </Button>
                        <Button
                            className='me-2'
                            color='secondary'
                            size='sm'
                            onClick={e => handleRateCardVersionChange(state.next_version_id)}
                            disabled={!state.next_version_id}
                        >
                            <FiChevronRight />
                        </Button>
                        <Button
                            className='me-2'
                            color='info-secondary'
                            size='sm'
                            onClick={downloadRateCardsTemplate}
                            disabled={isDownloadingTemplate}
                        >
                            {isDownloadingTemplate ? <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" /> Downloading template</> : 'Download template'}
                        </Button>
                        <Button
                            color='primary'
                            size='sm'
                            tag={Link}
                            to='/roles-rate-cards/import'
                        >
                            <FiPlus className='me-2' />
                            New
                        </Button>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-3">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader>Summary</CardHeader>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col md={8}>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Updated by:</Label>
                                                        <p className='m-0'>
                                                            {state.version.user?.name}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Updated at:</Label>
                                                        <p className="m-0">
                                                            {formatToAbbreviation(state.version?.updated_at?.toString().split(' ')[0])}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Valid from:</Label>
                                                        <p className='m-0'>
                                                            {formatToAbbreviation(state.version?.valid_from?.toString())}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>Valid to:</Label>
                                                        <p className={`m-0 ${!state.version.valid_to && `text-muted`}`}>
                                                            {formatToAbbreviation(state.version?.valid_to) ?? 'Not set'}
                                                        </p>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='d-flex flex-column align-items-center justify-content-center' md={4}>
                                            <Badge color={state.is_active ? 'success' : 'info'} pill>
                                                {
                                                    state.is_active ? 'Active rate card' : 'Not active rate card'
                                                }
                                            </Badge>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className='pt-4'>
                    <Col md={12}>
                        <Card>
                            <CardHeader>Rate cards</CardHeader>
                            <CardBody>
                                <Row>
                                    <TableWithCustomPagination
                                        columns={columns}
                                        data={state.rate_cards}
                                        notFoundText='No rate cards found'
                                        totalCount={state.total}
                                        queryPageSize={filters.pageSize}
                                        queryPageIndex={filters.page - 1}
                                        handlePageChange={onPageChange}
                                        handlePageSizeChange={onPagesizeChange}
                                        isLoading={isRateCardsLoading}
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default RolesRateCardsList;