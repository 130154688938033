import { checkPermission } from "helpers";

export const arrayForLoader = [...new Array(4)].map((val, idx) => idx + 1);

export const shouldDisableCreateUsers = !checkPermission('CREATE_USERS');
export const shouldDisableUpdateUsers = !checkPermission('UPDATE_USERS');
export const shouldDisableBenchEmail = !checkPermission('EMAIL_BENCHAVAILABILITY');
export const shouldDisableReminderTimelogs = !checkPermission('SEND_REMINDER_TIMELOGS');
export const canOpenSearch = checkPermission('BROWSER_GENERAL');
export const canCreateProjects = checkPermission('CREATE_PROJECTS');
export const canUpdateProjects = checkPermission('UPDATE_PROJECTS');
export const canUpdateAllProjects = checkPermission('UPDATE_ALL_PROJECTS');
export const canDeleteProjects = checkPermission('DELETE_PROJECTS');
export const canArchiveProjects = checkPermission('ARCHIVE_PROJECTS');
export const canListAllocations = checkPermission('LIST_ALLOCATIONS');
export const canListAllAllocations = checkPermission('LIST_ALL_ALLOCATIONS');
export const canOpenEffort = checkPermission('EFFORT_PROJECTS');
export const canOpenEconomy = checkPermission('ECONOMY_PROJECTS');
export const canOpenProjectStatus = checkPermission('LIST_PROJECTSTATUS');
export const canCreateProjectStatus = checkPermission('CREATE_PROJECTSTATUS');
export const canUpdateProjectStatus = checkPermission('UPDATE_PROJECTSTATUS');
export const canDeleteProjectStatus = checkPermission('DELETE_PROJECTSTATUS');
export const canCreateRateCards = checkPermission('CREATE_RATECARDS');
export const canUpdateRateCards = checkPermission('UPDATE_RATECARDS');
export const canUpdateAlllocation = checkPermission('UPDATE_ALLOCATIONS')
export const canCreateAllocation = checkPermission('CREATE_ALLOCATIONS');
export const canDeleteAllocation = checkPermission('DELETE_ALLOCATIONS');
export const canCreateTimeOffs = checkPermission('CREATE_TIMEOFF');
export const canUpdateTimeOffs = checkPermission('UPDATE_TIMEOFF');
export const canAcceptTimeOffs = checkPermission('ACCEPT_TIMEOFF');
export const canRejectTimeOffs = checkPermission('REJECT_TIMEOFF');
export const canListReimbursementRequests = checkPermission('LIST_ALL_REIMBURSEMENTREQUEST');
export const canCreateReimbursementRequests = checkPermission('CREATE_REIMBURSEMENTREQUEST');
export const canAcceptReimbursementRequests = checkPermission('ACCEPT_REIMBURSEMENTREQUEST');
export const canRejectReimbursementRequests = checkPermission('REJECT_REIMBURSEMENTREQUEST');
export const canListReimbursementCategories = checkPermission('LIST_ALL_REIMBURSEMENTCATEGORIES');
export const canCreateReimbursementCategories = checkPermission('CREATE_REIMBURSEMENTCATEGORIES');
export const canUpdateReimbursementCategories = checkPermission('UPDATE_REIMBURSEMENTCATEGORIES');
export const canDeleteReimbursementCategories = checkPermission('DELETE_REIMBURSEMENTCATEGORIES');
export const canCreateExpenseCategories = checkPermission('CREATE_EXPENSESCATEGORIES');
export const canUpdateExpenseCategories = checkPermission('UPDATE_EXPENSESCATEGORIES');
export const canDeleteExpenseCategories = checkPermission('DELETE_EXPENSESCATEGORIES');
export const canCreateSystemVersions = checkPermission('CREATE_VERSIONS');
export const canUpdateSystemVersions = checkPermission('UPDATE_VERSIONS');
export const canDeleteSystemVersions = checkPermission('DELETE_VERSIONS');
export const canCreateTimelogs = checkPermission('CREATE_TIMELOGS');
export const canListUsersTimelogs = checkPermission('LIST_USERS_TIMELOGS');
export const canCreateUsersTimelogs = checkPermission('CREATE_USERS_TIMELOGS');
export const canApproveTimelogs = checkPermission('APPROVE_TIMELOGS');
export const canOpenTeamTimelogs = checkPermission('TEAM_TIMELOGS');
export const canUpdateTimelogs = checkPermission('UPDATE_TIMELOGS');
export const canUpdateUsersTimelogs = checkPermission('UPDATE_USERS_TIMELOGS');
export const canResetTimelogs = checkPermission('RESET_TIMELOGS');
export const canListAllExpenses = checkPermission('LIST_ALL_EXPENSES');
export const canCreateExpenses = checkPermission('CREATE_EXPENSES');
export const canCreateTeams = checkPermission('CREATE_TEAMS');
export const canUpdateTeams = checkPermission('UPDATE_TEAMS');
export const canListReports = checkPermission('LIST_REPORTS');
export const canListExpensesByCategoryReports = checkPermission('EXPENSES_BY_CATEGORY_REPORTS');
export const canListReimbursementsReports = checkPermission('REIMBURSEMENTS_REPORTS');
export const canListExpensesPackagingReport = checkPermission('EXPENSES_PACKAGING_REPORTS');
export const canListUtilizationReport = checkPermission('UTILIZATION_REPORTS');
export const canListUnpaidHoursReport = checkPermission('UNPAID_HOURS_REPORTS');
export const canListMilestonesReport = checkPermission('MILESTONES_REPORTS');
export const canListRevenueReport = checkPermission('REVENUE_REPORTS');
export const canListInvoicingReport = checkPermission('INVOICING_REPORTS');
export const canListPaymentsReport = checkPermission('PAYMENTS_REPORTS');
export const canListTimeOffReport = checkPermission('TIME_OFF_REPORTS');
export const canListBenchReport = checkPermission('LIST_BENCHAVAILABILITY');
export const canListMissingTimelogsReport = checkPermission('LIST_REMINDER_TIMELOGS');
export const canListUtilizationChartReport = checkPermission('LIST_UTILIZATION_CHART_REPORTS');
export const canListProjectStatusReport = checkPermission('PROJECT_STATUS_REPORTS');
export const canListBills = checkPermission('LIST_BILLS');
export const canCreateBills = checkPermission('CREATE_BILLS');
export const canCreateUsers = checkPermission('CREATE_USERS');
export const canUpdateUsers = checkPermission('UPDATE_USERS');
export const canCreateRoles = checkPermission('CREATE_ROLES');
export const canUpdateRoles = checkPermission('UPDATE_ROLES');
export const canDeleteRoles = checkPermission('DELETE_ROLES');
export const canCreateTags = checkPermission('CREATE_TAGS');
export const canUpdateTags = checkPermission('UPDATE_TAGS');
export const canDeleteTags = checkPermission('DELETE_TAGS');
export const canCreateDepartments = checkPermission('CREATE_DEPARTMENTS');
export const canUpdateDepartments = checkPermission('UPDATE_DEPARTMENTS');
export const canCreateSaleLevels = checkPermission('CREATE_SALELEVELS');
export const canUpdateSaleLevels = checkPermission('UPDATE_SALELEVELS');
export const canListSaleLevels = checkPermission('LIST_SALELEVELS');
export const canCreateLegalEntities = checkPermission('CREATE_LEGALENTITIES');
export const listSaleLevels = checkPermission('LIST_SALELEVELS');
export const updateSaleLevels = checkPermission('UPDATE_SALELEVELS');
export const deleteSaleLevels = checkPermission('DELETE_SALELEVELS');
export const canUpdateLegalEntities = checkPermission('UPDATE_LEGALENTITIES');
export const canListJobDiva = checkPermission('INTEGRATION_JOBDIVA');
export const canListGeneralSettings = checkPermission('LIST_SETTINGS');
export const canCreateSystemRoles = checkPermission('CREATE_SYSTEMROLES');
export const canUpdateSystemRoles = checkPermission('UPDATE_SYSTEMROLES');
export const canDeleteSystemRoles = checkPermission('DELETE_SYSTEMROLES');
export const canSyncTempus = checkPermission('INTEGRATION_TEMPUS');
export const canCreateSeniorityTitles = checkPermission('CREATE_SENIORITYTITLES');
export const canUpdateSeniorityTitles = checkPermission('UPDATE_SENIORITYTITLES');
export const canDeleteSeniorityTitles = checkPermission('DELETE_SENIORITYTITLES');
export const canCreateHolidays = checkPermission('CREATE_HOLIDAYS');
export const canUpdateHolidays = checkPermission('UPDATE_HOLIDAYS');
export const canDeleteHolidays = checkPermission('DELETE_HOLIDAYS');
export const canListTimeOffRules = checkPermission('LIST_TIMEOFFRULES');
export const canCreateTimeOffRules = checkPermission('CREATE_TIMEOFFRULES');
export const canUpdateTimeOffRules = checkPermission('UPDATE_TIMEOFFRULES');
export const canDeleteTimeOffRules = checkPermission('DELETE_TIMEOFFRULES');

export const rateTypes = [
    {
        value: 'bill_rate',
        label: 'Bill rate',
    },
    {
        value: 'pay_rate',
        label: 'Pay rate',
    },
];

export const expensesTypes = [
    {
        label: 'Cost',
        value: 1,
    },
    {
        label: 'Expense',
        value: 2,
    }
];


// this moddifiers for datepicker will show popper allways on bottom
export const popperPlacement = 'bottom';
export const datepickerPopperModifiers = {
    flip: {
        behavior: ["bottom"] // if bottom then don't allow it to flip to be above
    },
    preventOverflow: {
        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
    },
    hide: {
        enabled: false // turn off since needs preventOverflow to be enabled
    }
};

export const projectStages = [
    {
        name: 'Delivery',
        value: 'delivery',
        stageType: 'primary',
    },
    {
        name: 'Approval',
        value: 'approval',
        stageType: 'primary',
    },
    {
        name: 'Proposal',
        value: 'proposal',
        stageType: 'primary',
    },
    {
        name: 'Lead',
        value: 'lead',
        stageType: 'primary',
    },
    {
        name: 'Finalized',
        value: 'finalized',
        stageType: 'secondary',
    },
    {
        name: 'Lost',
        value: 'lost',
        stageType: 'secondary',
    },
    {
        name: 'Dropped',
        value: 'dropped',
        stageType: 'secondary',
    },
];

export const projectStagesOptions = [
    {
        label: 'Delivery',
        value: 'delivery'
    },
    {
        label: 'Approval',
        value: 'approval'
    },
    {
        label: 'Proposal',
        value: 'proposal'
    },
    {
        label: 'Lead',
        value: 'lead'
    }
];

export const customSelectStyles = {
    control: (provided, { isFocused, isSelected }) => ({
        ...provided,
        border: isFocused || isSelected ? "1px solid #6049DA" : "1px solid lightgrey",
        boxShadow: isFocused || isSelected ? "0px 0px 4px #6049DA" : "none",
    }),
    option: (styles, { isSelected, isFocused }) => ({
        ...styles,
        fontWeight: (isSelected) ? 700 : 400,
        backgroundColor: (isSelected || isFocused) ? '#E9EDFC' : 'white',
        color: (isSelected || isFocused) ? '#6049DA' : 'black',
    }),
};

export const selectErrorStyles = {
    control: (provided) => ({
        ...provided,
        borderColor: '#dc3545'
    }),
};

export const osKeyForCommands = navigator.platform.startsWith('Mac') ? `⌘` : 'Ctrl';