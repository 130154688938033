import { zigattaApi } from "./zigattaApi";

const BASE_ENDPOINT = 'jobdiva';

export const jobdivaApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getJobdivaStarts: builder.query({
            query: (body = {}) => ({
                url: `${BASE_ENDPOINT}/starts`,
                method: 'POST',
                body,
            }),
            transformResponse: response => response.data,
            providesTags: ['Jobdiva'],
        }),
        getProjectJobdivaJobs: builder.query({
            query: () => `project-jobdiva-jobs`,
            transformResponse: response => response.project_jobdiva_jobs,
            providesTags: ['JobdivaProjects'],
        }),
        createProjectJobdivaJob: builder.mutation({
            query: (body = {}) => ({
                url: `project-jobdiva-jobs`,
                method: 'POST',
                body,
            }),
            transformResponse: response => response.project_jobdiva_jobs,
            invalidatesTags: ['JobdivaProjects'],
        }),
        getJobdivaSyncs: builder.query({
            query: () => `${BASE_ENDPOINT}/files`,
            transformResponse: response => response.data,
            providesTags: ['JobdivaSyncs'],
        }),
        createJobdivaSync: builder.mutation({
            query: (body) => ({
                url: `${BASE_ENDPOINT}/sync`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.file,
            invalidatesTags: ['JobdivaSyncs'],
        }),
        getJobdivaSyncById: builder.query({
            query: (syncId) => `${BASE_ENDPOINT}/files/${syncId}/detail`,
            transformResponse: response => response.data,
            providesTags: ['JobdivaSyncs'],
        }),
        confirmJobdivaSync: builder.mutation({
            query: ({ syncId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/files/${syncId}/update`,
                method: 'PUT',
                body,
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['JobdivaSyncs'],
        }),
        cancelJobdivaSync: builder.mutation({
            query: (syncId) => ({
                url: `${BASE_ENDPOINT}/files/${syncId}/cancel`,
                method: 'PUT',
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['JobdivaSyncs'],
        }),
    }),
});

export const {
    useGetJobdivaStartsQuery,
    useLazyGetJobdivaStartsQuery,
    useGetProjectJobdivaJobsQuery,
    useLazyGetProjectJobdivaJobsQuery,
    useCreateProjectJobdivaJobMutation,
    useGetJobdivaSyncsQuery,
    useLazyGetJobdivaSyncsQuery,
    useCreateJobdivaSyncMutation,
    useLazyGetJobdivaSyncByIdQuery,
    useConfirmJobdivaSyncMutation,
    useCancelJobdivaSyncMutation,
} = jobdivaApi;