import React, { useState, useEffect, } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Table,
    Nav,
    NavItem,
    NavLink as RRNavLink,
    Button,
} from 'reactstrap';
import DetailRow from './DetailRow';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import { useLazyGetJobdivaSyncByIdQuery } from 'services/jobdiva';
import { statusColor } from 'pages/Jobdiva/helpers';
import styles from './SyncDetail.module.css';

const SyncDetail = () => {
    const [sync, setSync] = useState({});
    const [navSelected, setNavSelected] = useState('confirmed');
    const { id } = useParams();
    const [getSync, { isFetching }] = useLazyGetJobdivaSyncByIdQuery();

    useEffect(() => {
        const getData = async () => {
            const { data, error } = await getSync(id);
            if (error || !data) return;
            setSync(data);
            setNavSelected(data.status?.toLowerCase());
        }
        if (id) getData();
    }, [id]);

    const handleNavChange = (newNav = 'confirmed') => setNavSelected(newNav);

    const filter = (detail) => {
        if (navSelected === 'confirmed') {
            if (!Boolean(detail.applied)) return false;
        }

        if (navSelected === 'cancelled') {
            if (Boolean(detail.applied)) return false;
        }

        return true;
    }

    return (
        <div>
            <div className="header pb-3">
                <div
                    className="container-fluid d-flex align-items-center justify-content-between"
                >
                    <h1>JobDiva Syncs</h1>
                    <Button
                        color='secondary'
                        size='sm'
                        tag={Link}
                        to='/jobdiva/syncs'
                    >
                        Back to list
                    </Button>
                </div>
            </div>
            <div className="container-fluid pb-3">
                <Row>
                    <Col
                        md={12}
                    >
                        <Card>
                            <CardHeader>
                                JobDiva sync #{id} - <span className={`${statusColor(sync.status)} font-weight-bold`}>{sync.status}</span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {
                                        sync.status?.toLowerCase() === 'confirmed' &&
                                        <Col
                                            md={12}
                                        >
                                            <Nav tabs>
                                                <NavItem>
                                                    <RRNavLink
                                                        className='cursor-pointer'
                                                        active={navSelected === 'confirmed'}
                                                        onClick={e => handleNavChange()}
                                                    >
                                                        Applied changes
                                                    </RRNavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <RRNavLink
                                                        className='cursor-pointer'
                                                        active={navSelected === 'cancelled'}
                                                        onClick={e => handleNavChange('cancelled')}
                                                    >
                                                        Unapplied changes
                                                    </RRNavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    }
                                    <LoadingIndicator isLoading={isFetching} />
                                    <Table className={styles.table}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Type</th>
                                                <th>Concept</th>
                                                <th>Company</th>
                                                <th>Dates</th>
                                                <th>Pay Rate</th>
                                                <th>Bill Rate</th>
                                            </tr>
                                        </thead>
                                        {
                                            sync.details?.filter(filter).map(detail => {
                                                return (
                                                    <DetailRow
                                                        key={`detail-${detail.id}`}
                                                        detail={detail}
                                                        colSpan={7}
                                                    />
                                                );
                                            })
                                        }
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default SyncDetail;