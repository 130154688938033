import { generateDirectDownload } from "helpers";

const { zigattaApi } = require("./zigattaApi");


const BASE_ENDPOINT = 'projects';

export const projectsApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getProjects: builder.query({
            query: (params = {}) => ({
                url: BASE_ENDPOINT,
                params: { current_projects: true, ...params },
            }),
            transformResponse: response => response.projects,
            providesTags: ['Projects', { type: 'Projects', id: 'LIST' }],
        }),
        getProjectById: builder.query({
            query: (projectId) => `${BASE_ENDPOINT}/${projectId}`,
            providesTags: ['Projects'],
        }),
        createProject: builder.mutation({
            query: (body = {}) => ({
                url: BASE_ENDPOINT,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.project,
            invalidatesTags: ['Projects'],
        }),
        updateProject: builder.mutation({
            query: ({ projectId, body = {}, }) => ({
                url: `${BASE_ENDPOINT}/${projectId}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.project,
            invalidatesTags: ['Projects'],
        }),
        createRateCard: builder.mutation({
            query: ({ body = {}, projectId }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/rateCards`,
                method: 'POST',
                body,
            }),
            transformResponse: response => response.rate_card,
            invalidatesTags: ['ProjectRateCards'],
        }),
        cloneProject: builder.mutation({
            query: ({ projectId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/clone`,
                method: 'POST',
                body,
            }),
            transformResponse: response => response.project,
            invalidatesTags: ['Projects'],
        }),
        archiveOrUnarchiveProject: builder.mutation({
            query: ({ projectId, type = 'archive' }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/${type}`,
                method: 'PUT',
            }),
            transformResponse: response => response.project,
            invalidatesTags: ['Projects'],
        }),
        deleteProject: builder.mutation({
            query: (projectId) => ({
                url: `${BASE_ENDPOINT}/${projectId}`,
                method: 'DELETE',
            }),
            transformResponse: response => response.deleted,
            invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
        }),
        updateProjectStage: builder.mutation({
            query: ({ body = {}, projectId }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/stage`,
                method: 'PUT',
                body,
            }),
            transformResponse: response => response.project,
            invalidatesTags: ['Projects'],
        }),
        updateProjectHealthScope: builder.mutation({
            query: ({ body = {}, projectId }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/health`,
                method: 'PUT',
                body,
            }),
            transformResponse: response => response.project,
            invalidatesTags: ['Projects'],
        }),
        createMilestone: builder.mutation({
            query: ({ projectId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/milestones`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.milestone,
            invalidatesTags: ['Projects'],
        }),
        getMilestoneById: builder.query({
            query: ({ projectId, milestoneId }) => `${BASE_ENDPOINT}/${projectId}/milestones/${milestoneId}`,
            transformResponse: response => response.milestone,
            providesTags: ['Projects'],
        }),
        getMilestoneDocument: builder.query({
            query: (milestoneId) => `completion-documents/${milestoneId}`,
            providesTags: ['Projects'],
        }),
        getMilestoneEffort: builder.query({
            query: (milestoneId) => `milestones/${milestoneId}/effort-summary`,
            transformResponse: response => response.effort,
            providesTags: ['Projects'],
        }),
        updateMilestone: builder.mutation({
            query: ({ projectId, milestoneId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/milestones/${milestoneId}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.milestone,
            invalidatesTags: ['Projects'],
        }),
        saveMilestoneDocument: builder.mutation({
            query: (body = {}) => ({
                url: `completion-documents`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['Projects'],
        }),
        updateMilestoneDocumentStatus: builder.mutation({
            query: ({ milestoneId, body }) => ({
                url: `milestones/${milestoneId}/update-completion-document-status`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.milestone,
            invalidatesTags: ['Projects'],
        }),
        updateMilestoneInvoiceStatus: builder.mutation({
            query: ({ milestoneId, body }) => ({
                url: `milestones/${milestoneId}/update-invoice-status`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.milestone,
            invalidatesTags: ['Projects'],
        }),
        deleteMilestone: builder.mutation({
            query: ({ projectId, milestoneId }) => ({
                url: `projects/${projectId}/milestones/${milestoneId}`,
                method: 'DELETE',
                headers: { 'Accept': 'application/json' },
            }),
            transformResponse: response => response.milestone,
            invalidatesTags: ['Projects'],
        }),
        getProjectRateCards: builder.query({
            query: (projectId) => `${BASE_ENDPOINT}/${projectId}/rateCards`,
            transformResponse: response => response.rate_cards,
            providesTags: ['ProjectRateCards'],
        }),
        estimateFullRevenue: builder.query({
            query: (projectId) => `${BASE_ENDPOINT}/${projectId}/get-full-revenue`,
        }),
        getProjectConnections: builder.query({
            query: (projectId) => `${BASE_ENDPOINT}/${projectId}/connections`,
            providesTags: ['Projects'],
        }),
        getDriveFolders: builder.query({
            query: (params = {}) => ({
                url: `${BASE_ENDPOINT}/drive-folders`,
                method: 'GET',
                params,
            }),
            transformResponse: response => response.folders,
        }),
        createOrLinkDriveFolderToProject: builder.mutation({
            query: ({ type = 'create-client', body = {}, projectId }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/${type}-drive-folder`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Projects'],
        }),
        unlinkDriveFolderFromProject: builder.mutation({
            query: (projectId) => ({
                url: `${BASE_ENDPOINT}/${projectId}/remove-drive-folder`,
                method: 'PUT',
            }),
            invalidatesTags: ['Projects'],
        }),
        createAddOrUnlinkSlackChannel: builder.mutation({
            query: ({ type = 'create', body = {}, projectId, }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/${type}-slack-channel`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: ['Projects'],
        }),
        getProjectEconomy: builder.query({
            query: ({ projectId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/economy`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.period,
            providesTags: ['Projects'],
        }),
        getProjectEffort: builder.query({
            query: ({ projectId, body = {} }) => ({
                url: `${BASE_ENDPOINT}/${projectId}/effort`,
                method: 'POST',
                body,
            }),
        }),
        downloadEffortExcel: builder.mutation({
            queryFn: async (args, api, extraOptions, baseQuery) => {
                let body = args.body;

              const result = await baseQuery({
                url: `${BASE_ENDPOINT}/${args.id}/effort`,                
                body: JSON.stringify(body),
                method: 'POST',
                responseType: "blob",
                headers: {
                    "Content-Disposition":"attachment;",
                    "Content-Type": "application/json",
                },
                responseHandler: (response) => response.blob(),
              });
              generateDirectDownload({
                response: result.data,
                fileName: `projects_${args.id}_effort.xls`,
              });
              return { data: null };
            },
        }),
        getProjectAllocation: builder.query({
            query: (params = {}) => ({
                url: `${BASE_ENDPOINT}/${params.projectId}/allocation`,
                method: 'GET',
                params,
            })
        }),
        getProjectCost: builder.query({
            query: (params = {}) => ({
                url: `${BASE_ENDPOINT}/${params.id}/allocation-economy`,
                method: 'GET',
                params,
            })
        }),
        getProjectStatus: builder.query({
            query: ({ id, params = {} }) => ({
                url: `${BASE_ENDPOINT}/${id}/status-reports`,
                method: 'GET',
                params,
            }),
            providesTags: ['ProjectStatus', { type: 'ProjectStatus', id: 'LIST' }],
        }),
        getProjectStatusById: builder.query({
            query: ({ id, status }) => ({
                url: `${BASE_ENDPOINT}/${id}/status-reports/${status}`,
                method: 'GET',
            }),
            transformResponse: response => response.data,
            providesTags: ['ProjectStatus'],
        }),
        createProjectStatus: builder.mutation({
            query: ({ id, body = {} }) => ({
                url: `projects/${id}/status-reports`,
                method: 'POST',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['ProjectStatus'],
        }),
        updateProjectStatus: builder.mutation({
            query: ({ id, status, body = {} }) => ({
                url: `projects/${id}/status-reports/${status}`,
                method: 'PUT',
                headers: { 'Accept': 'application/json' },
                body,
            }),
            transformResponse: response => response.data,
            invalidatesTags: ['ProjectStatus'],
        }),
        deleteProjectStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `projects/${id}/status-reports/${status}`,
                method: 'DELETE',
                headers: { 'Accept': 'application/json' },
            }),
            invalidatesTags: [{ type: 'ProjectStatus', id: 'LIST' }],
        }),
    })
});

export const {
    useGetProjectsQuery,
    useGetProjectByIdQuery,
    useLazyGetProjectByIdQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useLazyGetProjectsQuery,
    useCreateRateCardMutation,
    useCloneProjectMutation,
    useArchiveOrUnarchiveProjectMutation,
    useDeleteProjectMutation,
    useUpdateProjectStageMutation,
    useUpdateProjectHealthScopeMutation,
    useCreateMilestoneMutation,
    useGetMilestoneByIdQuery,
    useGetMilestoneDocumentQuery,
    useGetMilestoneEffortQuery,
    useUpdateMilestoneMutation,
    useSaveMilestoneDocumentMutation,
    useUpdateMilestoneDocumentStatusMutation,
    useUpdateMilestoneInvoiceStatusMutation,
    useDeleteMilestoneMutation,
    useGetProjectRateCardsQuery,
    useLazyEstimateFullRevenueQuery,
    useGetProjectConnectionsQuery,
    useLazyGetDriveFoldersQuery,
    useCreateOrLinkDriveFolderToProjectMutation,
    useUnlinkDriveFolderFromProjectMutation,
    useCreateAddOrUnlinkSlackChannelMutation,
    useLazyGetProjectEconomyQuery,
    useLazyGetProjectEffortQuery,
    useLazyGetProjectAllocationQuery,
    useGetProjectCostQuery,
    useGetProjectStatusQuery,
    useGetProjectStatusByIdQuery,
    useCreateProjectStatusMutation,
    useUpdateProjectStatusMutation,
    useDeleteProjectStatusMutation,
    useDownloadEffortExcelMutation,
} = projectsApi;