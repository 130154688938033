import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { NavItem, NavLink, Nav, Collapse } from "reactstrap";
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import classNames from "classnames";
import { selectSidebarCollapsed, selectSidebarExpanded, setSidebarCollapsed } from "store/slices/userSlice";

const Empty = () => <></>;
const dummyFnc = () => { };

const SidebarNavItems = ({ navItems, className, itemClass = '', sidebBarOpen = true, isSubMenu = false, ...rest }) => {
    const dispatch = useDispatch();
    const isSidebarExpanded = useSelector(selectSidebarExpanded);
    const collapsed = useSelector(selectSidebarCollapsed);
    const { pathname } = useLocation();
    const handleCollapse = (id) => dispatch(setSidebarCollapsed(id));


    return (
        <Nav className={className} {...rest} >
            {
                navItems.map(({ isVisible, name, icon, path, subMenu, hideIcon }, idx) => {
                    const IconImage = icon ?? Empty;
                    const to = path ?? (name === 'Dashboard' ? '/' : `/${name.toLowerCase()}`);
                    const isCollapsable = !!subMenu;
                    const tooltip = !sidebBarOpen ? { 'data-bs-toggle': "tooltip", title: name } : {};
                    const active = pathname === to;
                    const collapseFnc = () => (isCollapsable && sidebBarOpen) ? handleCollapse(idx) : dummyFnc();

                    return isVisible &&
                        <NavItem key={name} className={classNames({ active })} {...tooltip} >
                            <div className={`d-flex align-items-center justify-content-between ${itemClass}`} >
                                <NavLink onClick={collapseFnc} tag={Link} to={to} className={classNames('sidebar-nav-item', { active, 'submenu-item': isSubMenu })}>
                                    {!hideIcon && <IconImage className="me-2 sidebar-icon" />}{sidebBarOpen && name}
                                </NavLink>

                                {(isCollapsable && sidebBarOpen) && (collapsed === idx ?
                                    <BsChevronUp onClick={() => handleCollapse(idx)} className="me-3 collapse-icon" />
                                    :
                                    <BsChevronDown onClick={() => handleCollapse(idx)} className="me-3 collapse-icon" />
                                )}
                            </div>
                            {
                                isCollapsable &&
                                <Collapse isOpen={collapsed === idx && isSidebarExpanded}>
                                    <SidebarNavItems navItems={subMenu} vertical className="nav-submenu" sidebBarOpen={sidebBarOpen} isSubMenu />
                                </Collapse>
                            }
                        </NavItem>
                })
            }
        </Nav>
    );
};

export default SidebarNavItems;