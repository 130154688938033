import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardHeader,
    CardBody,
    Badge,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import TableContainer from 'Components/Table/Table';
import { useGetReimbursementCategoriesQuery } from 'services/reimbursements';
import { canCreateReimbursementCategories } from 'constants/common';

const columns = [
    {
        Header: '#',
        accessor: 'id',
        Cell: ({ row: { original: { id } } }) => {
            return (
                <Link className='text-decoration-none' to={`/reimbursement-categories/${id}`}>
                    {id}
                </Link>
            );
        },
    },
    {
        Header: 'Expense Category',
        accessor: 'category_name',
        Cell: ({ row: { original: { expense_category } } }) => {
            return expense_category?.name;
        }
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Status',
        accessor: 'display',
        Cell: ({ row: { original: { display } } }) => {
            return (
                <Badge color={display ? 'success' : 'danger'} pill>
                    {display ? 'Active' : 'Inactive'}
                </Badge>
            );
        },
    },
];

const ListReimbursementCategories = () => {
    const [list, setList] = useState([]);
    const navigate = useNavigate();
    const { data: dataCategories, isFetching } = useGetReimbursementCategoriesQuery();

    useEffect(() => {
        if (!dataCategories) return;
        setList(dataCategories);
    }, [dataCategories]);

    const onRowClick = ({ row }) => {
        const { id } = row.original;
        navigate(`/reimbursement-categories/${id}`);
    }

    return (
        <>
            <PageHeader title='Reimbursement Requests Categories' className='pb-3'>
                <Button
                    color='primary'
                    size='sm'
                    tag={Link}
                    to='/reimbursement-categories/create'
                    disabled={!canCreateReimbursementCategories}
                >
                    New
                </Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardHeader>List</CardHeader>
                    <CardBody>
                        <TableContainer
                            className='clickable-rows'
                            data={list}
                            columns={columns}
                            notFoundText='No reimbursement categories found.'
                            rowClick={onRowClick}
                            isLoading={isFetching}
                        />
                    </CardBody>
                </Card>
            </div>
        </>
    );
}

export default ListReimbursementCategories;