import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import { selectGlobalAlert, closeGlobalAlert } from 'store/slices/userSlice';

const GlobalAlert = () => {
    const globalAlert = useSelector(selectGlobalAlert);
    const dispatch = useDispatch();
    const { color, isOpen, message, status, } = globalAlert;

    return (
        <Alert
            color={color}
            isOpen={isOpen}
            className='mb-3 mx-0 mt-0'
            toggle={() => dispatch(closeGlobalAlert())}
        >
            <h4 className='alert-heading'>Error {status || ''}</h4>
            <span>{message ?? 'Oops! Something went wrong.'}</span>
        </Alert>
    );
};

export default GlobalAlert;