import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, } from 'reactstrap';
import Modal from 'Components/Modal/Modal';
import LoadingIndicatorText from 'Components/LoadingIndicatorText/LoadingIndicatorText';
import { useDeleteTimeOffRuleMutation } from 'services/timeOff';

const DeleteRuleModal = ({
    isOpen,
    handleToggle,
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deleteMutation, { isLoading }] = useDeleteTimeOffRuleMutation();
    const onDeleteRule = async (e) => {
        e.preventDefault();
        const { data } = await deleteMutation(id);
        if (!data) return;
        navigate('/time-off-rules');
    };

    const modalFooter = (
        <>
            <Button className='me-2' onClick={handleToggle}>Cancel</Button>
            <Button color='danger' onClick={onDeleteRule} disabled={isLoading}>
                <LoadingIndicatorText
                    isLoading={isLoading}
                    loadingPlaceholder='Deleting...'
                    notLoadingPlaceholder='Yes, delete'
                />
            </Button>
        </>
    );
    return (
        <Modal
            isOpen={isOpen}
            header='Delete time off rule'
            handleToggle={handleToggle}
            footer={modalFooter}
            centered
        >
            <p className="m-0">This operation cannot be undone. Do you wish to continue?</p>
        </Modal>
    );
};

export default DeleteRuleModal;