import React, { useState } from "react";
import {
  Row,
  Col,
  FormGroup,
  FormFeedback,
  Label,
  Input,
} from "reactstrap";
import DatePicker from "react-datepicker";
import SelectWithQuery from "Components/SelectWithQuery/SelectWithQuery";
import PasswordInput from 'Components/PasswordInput/PasswordInput';
import { useGetDeparmentsQuery } from "services/departments";
import { useGetRolesQuery } from "services/roles";
import {
  useGetUserResumesQuery,
  useGetUsersBriefListQuery,
} from "services/users";
import { useGetSystemRolesQuery } from "services/systemRoles";
import {
  useGetSenioritiesQuery,
  useGetSeniorityTitlesQuery,
} from "services/seniorities";
import { useGetLegalEntitiesQuery } from "services/legalEntities";
import { useGetSaleLevelsQuery } from "services/sale";
import { formatStringToDate } from "helpers/datesHelper";
import { selectErrorStyles } from "constants/common";

const UserInviteForm = ({
  data = {
    name: "",
    email: "",
    jobdiva_email: "",
    hire_type: "full-time",
    hired_at: "",
    date_of_birth: "",
    department_id: "",
    week_hours: 40,
    role_id: "",
    reporting_manager_id: "",
    seniority: "trainee",
    seniority_title_id: "",
    sale_level_id: "",
    employer_id: "",
    system_role: "",
    jobdiva_candidate_id: "",
    recruiter_id: "",
    resume: "",
    send_invite: false,
    require_password: false,
    avatar: null,
    errors: {},
    skill: "",
  },
  hideSendInvite = false,
  hidePasswordAuth = false,
  hideJobdivaCandidateID = false,
  hideDateOfBirth = false,
  hideSystemRole = false,
  hideProfilePicture = false,
  showPasswordFields,
  handleChange,
}) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const handleCustomChange = ({ target: { name, value } }) => {
    handleChange(name, value);
  };

  const handleSelectChange = (option, { name }) => {
    handleChange(name, option?.value);
  };

  const handleDateChange = (name, date = "") => {
    handleChange(name, date);
  };

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    handleChange(name, checked);
  };

  const handleAvatarChange = ({ target: { files } }) =>
    handleChange("avatar", files[0]);

  const handlePasswordAuth = ({ target: { name, checked } }) => {
    setPassword('');
    setPasswordConfirm('');
    handleChange(name, checked);
  }

  const handlePasswordChange = ({ target: { name, value } }) => {
    setPassword(value);
    handleChange(name, value);
  }

  const handlePasswordConfirmChange = ({ target: { name, value } }) => {
    setPasswordConfirm(value);
    handleChange(name, value);
  }

  return (
    <>
      <Row>
        <Col md={12}>
          <FormGroup>
            <Label>Name</Label>
            <Input
              type="text"
              onChange={handleCustomChange}
              name="name"
              value={data.name}
              invalid={data.errors.name}
            />
            {!!data.errors.name && (
              <FormFeedback>{data.errors.name}</FormFeedback>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>E-mail</Label>
            <Input
              type="text"
              onChange={handleCustomChange}
              name="email"
              value={data.email}
              invalid={data.errors.email}
            />
            {!!data.errors.email && (
              <FormFeedback>{data.errors.email}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>JobDiva E-mail (optional)</Label>
            <Input
              type="text"
              onChange={handleCustomChange}
              name="jobdiva_email"
              value={data.jobdiva_email}
              invalid={data.errors.jobdiva_email}
            />
            {!!data.errors.jobdiva_email && (
              <FormFeedback>{data.errors.jobdiva_email}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        {!hideDateOfBirth && (
          <Col md={6}>
            <FormGroup>
              <Label>Date of birth</Label>
              <DatePicker
                customInput={
                  <Input type="text" invalid={data.errors.date_of_birth} />
                }
                selected={
                  data.date_of_birth
                    ? formatStringToDate(data.date_of_birth)
                    : ""
                }
                name="date_of_birth"
                onChange={handleDateChange.bind(this, "date_of_birth")}
                dateFormat="dd-MM-yyyy"
              />
              {!!data.errors.date_of_birth && (
                <FormFeedback className="d-block">
                  {data.errors.date_of_birth}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        )}
        <Col md={6}>
          <FormGroup>
            <Label>Hire date</Label>
            <DatePicker
              customInput={
                <Input
                  type="text"
                  name="hired_at"
                  invalid={data.errors.hired_at}
                />
              }
              selected={data.hired_at ? formatStringToDate(data.hired_at) : ""}
              name="hired_at"
              onChange={handleDateChange.bind(this, "hired_at")}
              dateFormat="dd-MM-yyyy"
            />
            {!!data.errors.hired_at && (
              <FormFeedback className="d-block">
                {data.errors.hired_at}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Week hours</Label>
            <Input
              type="text"
              onChange={handleCustomChange}
              name="week_hours"
              value={data.week_hours}
              invalid={data.errors.week_hours}
            />
            {!!data.errors.week_hours && (
              <FormFeedback>{data.errors.week_hours}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Hire type</Label>
            <Input
              type="select"
              value={data.hire_type}
              id="hire_type"
              name="hire_type"
              onChange={handleCustomChange}
              invalid={data.errors.hire_type}
            >
              <option value="full-time">Full time</option>
              <option value="per-hour">Per-hour</option>
            </Input>
            {!!data.errors.hire_type && (
              <FormFeedback>{data.errors.hire_type}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Department</Label>
            <SelectWithQuery
              query={useGetDeparmentsQuery}
              selected={data.department_id}
              name="department_id"
              placeholder="Select department"
              styles={data?.errors?.department_id ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
            />
            {!!data?.errors?.department_id && (
              <FormFeedback style={{ display: "block" }}>
                {data?.errors?.department_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Role</Label>
            <SelectWithQuery
              query={useGetRolesQuery}
              selected={data.role_id}
              name="role_id"
              placeholder="Select role"
              styles={data?.errors?.role_id ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
            />
            {!!data.errors.role_id && (
              <FormFeedback style={{ display: "block" }}>
                {data.errors.role_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Seniority</Label>
            <SelectWithQuery
              query={useGetSenioritiesQuery}
              selected={data.seniority}
              name="seniority"
              placeholder="Select seniority"
              styles={data?.errors?.seniority ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
              dataOpts={{ label: "label", value: "name" }}
            />
            {!!data.errors.seniority && (
              <FormFeedback>{data.errors.seniority}</FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Sales Level</Label>
            <SelectWithQuery
              query={useGetSaleLevelsQuery}
              selected={data.sale_level_id}
              name="sale_level_id"
              placeholder="Select sales level"
              styles={data?.errors?.sale_level_id ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
            />
            {!!data?.errors?.sale_level_id && (
              <FormFeedback className="d-block">
                {data?.errors?.sale_level_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Seniority Title</Label>
            <SelectWithQuery
              query={useGetSeniorityTitlesQuery}
              selected={data.seniority_title_id}
              name="seniority_title_id"
              placeholder="Select seniority title"
              styles={data?.errors?.seniority_title_id ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
            />
            {!!data?.errors?.seniority_title_id && (
              <FormFeedback className="d-block">
                {data?.errors?.seniority_title_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Reporting Manager</Label>
            <SelectWithQuery
              query={useGetUsersBriefListQuery}
              selected={data.reporting_manager_id}
              name="reporting_manager_id"
              placeholder="Select a manager"
              styles={
                data?.errors?.reporting_manager_id ? selectErrorStyles : {}
              }
              handleChange={handleSelectChange}
            />
            {!!data.errors.reporting_manager_id && (
              <FormFeedback style={{ display: "block" }}>
                {data.errors.reporting_manager_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Recruiter</Label>
            <SelectWithQuery
              query={useGetUsersBriefListQuery}
              selected={data.recruiter_id}
              name="recruiter_id"
              placeholder="Select a recuriter"
              styles={data?.errors?.recruiter_id ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
            />
            {!!data.errors.recruiter_id && (
              <FormFeedback style={{ display: "block" }}>
                {data.errors.recruiter_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Employer</Label>
            <SelectWithQuery
              query={useGetLegalEntitiesQuery}
              selected={data.employer_id}
              name="employer_id"
              placeholder="Select an employer"
              styles={data?.errors?.employer_id ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
            />
            {!!data.errors.employer_id && (
              <FormFeedback style={{ display: "block" }}>
                {data.errors.employer_id}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        {!hideSystemRole && (
          <Col md={6}>
            <FormGroup>
              <Label>System Role</Label>
              <SelectWithQuery
                query={useGetSystemRolesQuery}
                selected={data.system_role}
                name="system_role"
                placeholder="Select a system role"
                styles={data?.errors?.system_role ? selectErrorStyles : {}}
                handleChange={handleSelectChange}
              />
              {!!data.errors.system_role && (
                <FormFeedback className="d-block">
                  {data.errors.system_role}
                </FormFeedback>
              )}
            </FormGroup>
          </Col>
        )}
        <Col md={6}>
          <FormGroup>
            <Label>Candidate's resume (optional)</Label>
            <SelectWithQuery
              query={useGetUserResumesQuery}
              selected={data.resume}
              name="resume"
              placeholder="Select resume"
              styles={data?.errors?.resume ? selectErrorStyles : {}}
              handleChange={handleSelectChange}
            />
            {!!data.errors.resume && (
              <FormFeedback className="d-block">
                {data.errors.resume}
              </FormFeedback>
            )}
          </FormGroup>
        </Col>
        {!hideJobdivaCandidateID && (
          <Col md={6}>
            <FormGroup>
              <Label>JobDiva Candidate ID</Label>
              <Input
                type="text"
                value={data.jobdiva_candidate_id ?? ""}
                name="jobdiva_candidate_id"
                onChange={handleCustomChange}
                invalid={data.errors.jobdiva_candidate_id}
              />
              {!!data.errors.jobdiva_candidate_id && (
                <FormFeedback>{data.errors.jobdiva_candidate_id}</FormFeedback>
              )}
            </FormGroup>
          </Col>
        )}
        {!hideProfilePicture && (
          <Col md={6}>
            <FormGroup>
              <Label>Avatar (optional)</Label>
              <Input
                type="file"
                id="avatar"
                name="avatar"
                accept="image/*"
                onChange={handleAvatarChange}
                invalid={data.errors.avatar}
              />
              {!!data.errors.avatar && (
                <FormFeedback>{data.errors.avatar}</FormFeedback>
              )}
            </FormGroup>
          </Col>
        )}
        {!hideSendInvite && (
          <Col md={12}>
            <FormGroup check inline>
              <Input
                type="checkbox"
                name="send_invite"
                id="sendInvite"
                checked={data.send_invite}
                onChange={handleCheckboxChange}
              />
              <Label check for="sendInvite">
                Send invitation via email
              </Label>
            </FormGroup>
          </Col>
        )}
        {!hidePasswordAuth && (
          <Col md={12}>
            <FormGroup check inline>
              <Input
                type="checkbox"
                name="require_password"
                id="requirePassword"
                checked={data.require_password}
                onChange={handlePasswordAuth}
              />
              <Label check for="requirePassword">
                Use password authentication
              </Label>
            </FormGroup>
          </Col>
        )}
        {!!showPasswordFields && (
          <>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label aria-required='*'>Password</Label>
                    <PasswordInput
                      value={password}
                      handleChange={handlePasswordChange}
                      hasError={!!data?.errors?.password}
                      disabled={!data?.require_password}
                      name='password'
                      placeholder='password'
                    />
                    {!!data?.errors?.password && (
                      <FormFeedback className="d-block">{data?.errors?.password}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label aria-required='*'>Confirm Password</Label>
                    <PasswordInput
                      value={passwordConfirm}
                      handleChange={handlePasswordConfirmChange}
                      hasError={!!data?.errors?.password_confirm}
                      disabled={!data?.require_password}
                      name='password_confirm'
                      placeholder='Confirm password'
                    />
                    {!!data?.errors?.password_confirm && (
                      <FormFeedback className="d-block">{data?.errors?.password_confirm}</FormFeedback>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </Col>

          </>
        )}
      </Row>
    </>
  );
};

export default UserInviteForm;
