import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, } from 'reactstrap';
import Modal from 'Components/Modal/Modal';
import { useDeleteHoldayMutation } from 'services/holidays';

const DeleteHolidayModal = ({
    isOpen,
    handleToggle,
}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [deleteMutation, { isLoading }] = useDeleteHoldayMutation();
    const onDeleteStatus = async (e) => {
        e.preventDefault();
        const { data } = await deleteMutation(id);
        if (!data) return;
        navigate('/holidays');
    };

    const modalFooter = (
        <>
            <Button className='me-2' onClick={handleToggle}>Cancel</Button>
            <Button color='danger' onClick={onDeleteStatus} disabled={isLoading}>
                {isLoading ?
                    <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" /> Deleting</> :
                    'Yes, delete'
                }
            </Button>
        </>
    );
    return (
        <Modal
            isOpen={isOpen}
            header='Delete holiday'
            handleToggle={handleToggle}
            footer={modalFooter}
            centered
        >
            <p className="m-0">This operation cannot be undone. Do you wish to continue?</p>
        </Modal>
    );
};

export default DeleteHolidayModal;