import React, { useMemo } from "react";
import Select from 'react-select';
import { getSelectOptions } from "helpers";
import { customSelectStyles } from 'constants/common';

const dummyQuery = () => ({});

const CustomSelect = ({
    selected, // must be an ID (number, text)
    dataOpts = {},
    placeholder = '',
    name = '',
    styles = {},
    query = dummyQuery,
    handleChange,
    arrayOpts,
    isClearable,
    isDisabled,
}) => {
    const { data } = query();
    

    const options = useMemo(() => {
        if(!arrayOpts && !data) return [];
        return arrayOpts ?? getSelectOptions(data, dataOpts);
        //eslint-disable-next-line
    }, [data, arrayOpts]);

    const optSelected = useMemo(() => {
        if(!options || !selected) return null;
        return options.find(({ value }) => value === selected);
    }, [options, selected]);

    const customStyles = {
        ...customSelectStyles,
        ...styles,
    }

    return (
        <Select
            value={optSelected}
            name={name}
            placeholder={placeholder}
            styles={customStyles}
            options={options}
            onChange={handleChange}
            isClearable={isClearable}
            isDisabled={isDisabled}
        />
    );
};

export default CustomSelect;