import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiZoomIn, FiEye } from "react-icons/fi";
import { Button, Card, CardBody } from "reactstrap";
import AcceptOrRejectReimbursementModal from "./AcceptOrRejectReimbursementModal";
import PageHeader from "Components/PageHeader/PageHeader";
import TableContainer from "Components/Table/Table";
import { useGetReimbursementsRequestsQuery } from "services/reimbursements";
import { status as rStatus } from "constants/reimbursements";
import {
  canAcceptReimbursementRequests,
  canRejectReimbursementRequests,
} from "constants/common";
import {
  useDownloadReimbursementFileMutation,
  useDownloadReimbursementFileURLMutation,
} from "services/downloads";

const ListReimbursementRequests = () => {
  const [requestsList, setRequestsList] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedRequestType, setSelectedRequestType] = useState("accept");
  const [isAcceptRejectModalOpen, setIsAcceptOrRejectModal] = useState(false);
  const { data: listData, isFetching } = useGetReimbursementsRequestsQuery();
  const [downloadReimbursementFile] = useDownloadReimbursementFileMutation();
  const [downloadReimbursementURLFile] =
    useDownloadReimbursementFileURLMutation();

  useEffect(() => {
    if (!listData) return;
    setRequestsList(listData);
  }, [listData]);

  const toggleAcceptRejectModal = () =>
    setIsAcceptOrRejectModal(!isAcceptRejectModalOpen);

  const handleAcceptReject = ({ target: { dataset } }) => {
    setSelectedRequest(dataset.id);
    setSelectedRequestType(dataset.type);
    setIsAcceptOrRejectModal(true);
  };

  const onAcceptRejectModalClosed = () => {
    setSelectedRequest(null);
    setSelectedRequestType("accept");
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original } }) => {
        return (
          <Link
            className="text-decoration-none"
            to={`/reimbursements/${original.id}`}
          >
            {original.user?.name}
          </Link>
        );
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row: { original } }) => {
        return (
          <p className="m-0">
            ${original.amount} {original.currency?.code}
          </p>
        );
      },
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: ({ row: { original } }) => {
        return original?.reimbursement_request_category?.name ?? "-";
      },
    },
    {
      Header: "Payment date",
      accessor: "date",
      Cell: ({ row: { original } }) => {
        return original.formatted_date ?? "-";
      },
    },
    {
      Header: "Files",
      accessor: "files",
      Cell: ({ row: { original } }) => {
        return (
          <div className="col">
            <Button
              className="m-1"
              color="primary"
              size="sm"
              onClick={(e) =>
                downloadReimbursementFile({
                  reimbursement: original,
                  fileType: "file",
                })
              }
            >
              PDF
            </Button>
            <Button
              className="m-1"
              color="info"
              size="sm"
              onClick={(e) => {
                downloadReimbursementURLFile({
                  reimbursement: original,
                  fileType: "file",
                });
              }}
            >
              {<FiZoomIn />}
            </Button>
            <Button
              className="m-1"
              color="primary"
              size="sm"
              onClick={(e) =>
                downloadReimbursementFile({
                  reimbursement: original,
                  fileType: "xml",
                })
              }
            >
              XML
            </Button>
            {original.image && (
              <>
                <Button
                  className="m-1"
                  color="primary"
                  size="sm"
                  onClick={(e) =>
                    downloadReimbursementFile({
                      reimbursement: original,
                      fileType: "image",
                    })
                  }
                >
                  IMG
                </Button>

                <Button
                  className="m-1"
                  color="info"
                  size="sm"
                  onClick={(e) =>
                    downloadReimbursementURLFile({
                      reimbursement: original,
                      fileType: "image",
                    })
                  }
                >
                  <FiEye />
                </Button>
              </>
            )}
          </div>
        );
      },
    },
    {
      Header: "Authorize",
      accessor: "authorize",
      Cell: ({ row: { original } }) => {
        const lowerCaseStatus = original.status?.toLowerCase();
        return lowerCaseStatus === "pending" ? (
          <>
            <Button
              className="me-2"
              color="success"
              size="sm"
              data-type="accept"
              data-id={original.id}
              onClick={handleAcceptReject}
              disabled={!canAcceptReimbursementRequests}
            >
              Yes
            </Button>
            <Button
              color="danger"
              size="sm"
              data-type="reject"
              data-id={original.id}
              onClick={handleAcceptReject}
              disabled={!canRejectReimbursementRequests}
            >
              No
            </Button>
          </>
        ) : (
          <div>
            <p
              className={`m-0 text-${
                rStatus[lowerCaseStatus]?.color ?? "dark"
              }`}
            >
              <b>{rStatus[lowerCaseStatus]?.label ?? "-"}</b>
            </p>
            {lowerCaseStatus !== "cancelled" && (
              <p className="m-0">By {original.status_updated_by?.name ?? ""}</p>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <PageHeader className="pb-3" title="Reimbursements" />
      <div className="container-fluid pb-3">
        <Card>
          <CardBody>
            <TableContainer
              data={requestsList}
              columns={columns}
              notFoundText="No reimbursement requests found."
              isLoading={isFetching}
            />
          </CardBody>
        </Card>
      </div>
      <AcceptOrRejectReimbursementModal
        id={selectedRequest}
        requestType={selectedRequestType}
        isOpen={isAcceptRejectModalOpen}
        handleClosed={onAcceptRejectModalClosed}
        toggle={toggleAcceptRejectModal}
      />
    </>
  );
};
export default ListReimbursementRequests;
