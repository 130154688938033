export const status = {
    pending: {
        label: 'Pending',
        color: 'warning',
    },
    cancelled: {
        label: 'Cancelled',
        color: 'secondary',
    },
    rejected: {
        label: 'Rejected',
        color: 'danger',
    },
    accepted: {
        label: 'Accepted',
        color: 'success',
    },
    paid: {
        label: 'Paid',
        color: 'info',
    },
};