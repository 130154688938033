import * as moment from 'moment';
import { add, addDays, getISODay, isBefore, isEqual, setISODay, startOfDay } from 'date-fns';
import { formatStringToDate } from './datesHelper';

// Checks if a date is between a range of two dates
export const diffDatesCalculation = (dayOfWeek, startDate, endDate) => {
  let diffStart = moment(startDate).startOf('day').diff(moment(dayOfWeek).startOf('day'), 'd');
  let diffEnd = moment(endDate).startOf('day').diff(moment(dayOfWeek).startOf('day'), 'd');

  return (diffStart <= 0 && diffEnd >= 0);
}

const daysToAdd = 7;
export const estimateTotalAllocationHours = ({ startDate, endDate, dailyHours }) => {
  // Estimate working days between
  const start = startOfDay(formatStringToDate(startDate));
  const end = startOfDay(formatStringToDate(endDate));
  //calculate only weekdays
  const dailyInfo = [false, true, true, true, true, true, false]
  let totalDays = 0;
  // Estimate working days between
  dailyInfo.forEach((info, index) => {
    if (info === true) {
      let current = start;
      if (getISODay(current) <= index) {
        current = setISODay(current, index);
      } else {
        current = setISODay(add(current, { weeks: 1 }), index);
      }
      while (isBefore(current, end) || isEqual(current, end)) {
        current = addDays(current, daysToAdd);
        totalDays += 1;
      }
    }
  });
  return totalDays * parseFloat(dailyHours);
}