import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { usePromiseTracker } from "react-promise-tracker";
import { InputGroup, InputGroupText, Input, ListGroup, ListGroupItem } from 'reactstrap';
import { BiCube, BiSearch } from 'react-icons/bi';
import { HiOutlineDocumentReport as ReportIcon } from 'react-icons/hi';
import { RiTeamLine } from 'react-icons/ri';
import AvatarIcon from 'Components/AvatarIcon/AvatarIcon';
import Modal from 'Components/Modal/Modal';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import useEventListener from 'Hooks/useEventListener';
import useDebounce from 'Hooks/useDebounce';
import { search } from 'Api/Api';
import { sortBy } from 'helpers';
import { osKeyForCommands, canOpenSearch } from 'constants/common';
import { openSearchModal, selectOpenSearchModal } from 'store/slices/userSlice';
import './SearchShortcut.css';



const getDetails = {
    reports: { to: '/reports/', icon: () => <ReportIcon /> },
    users: { to: '/users/', icon: (avatar_url) => <AvatarIcon avatarUrl={avatar_url} size={25} /> },
    projects: { to: '/projects/', icon: () => <BiCube /> },
    teams: { to: '/teams/', icon: () => <RiTeamLine /> }
};

const placeholderForInput = `Search projects, reports, users (${osKeyForCommands} + K)`

const SearchShortcut = () => {

    const [searchWord, setSearch] = useState(null);
    const [list, setList] = useState(null);
    const [selectedItem, setSelectedItem] = useState(0);
    const searchRef = useRef(null);
    const resultsRef = useRef(null);
    const ref = useRef();
    const debouncedSearch = useDebounce(searchWord, 500);
    const { promiseInProgress } = usePromiseTracker();
    const dispatch = useDispatch();
    const isOpen = useSelector(selectOpenSearchModal);

    const clearValues = () => {
        setList(null);
        ref.current = null;
    }

    const selectFirstElement = () => {
        ref.current = resultsRef.current.children[0];
        ref.current.children[0].focus();
        setSelectedItem(0);
    }

    useEffect(() => {

        const getData = async () => {
            if (debouncedSearch) {
                const results = await search(debouncedSearch);
                if (!results?.projects?.length && !results?.users?.length && !results?.reports?.length) return clearValues();
                const projects = sortBy(results?.projects, 'name');
                const users = sortBy(results?.users, 'name');
                const reports = sortBy(results?.reports, 'name');
                const teams = sortBy(results?.teams, 'name');
                setList({ projects, reports, users, teams });

                if (resultsRef.current && resultsRef.current?.children?.length) {
                    selectFirstElement();
                }
            } else if (debouncedSearch === '') {
                clearValues();
            }
        }
        getData();
    }, [debouncedSearch]);

    useEffect(() => {
        if (resultsRef.current && resultsRef.current?.children?.length && !promiseInProgress) {
            ref.current = resultsRef.current.children[0];
            ref.current.children[0].focus();
            setSelectedItem(0);
        }
        // eslint-disable-next-line
    }, [resultsRef.current, list, promiseInProgress]);

    const nextOrPrevElement = (selected) => {
        if (resultsRef?.current) {
            if (selected < 0) searchRef.current.focus();
            if (selected >= 0 && selected < resultsRef.current.children.length) {
                // if searchRef has focus then we set as selected the first element of the results
                if (document.activeElement === searchRef.current) {
                    selectFirstElement();
                    return;
                }
                ref.current = resultsRef.current.children[selected];
                ref.current.children[0].focus();

                setSelectedItem(selected);
            }
        }
    };

    const handleEnter = () => {
        const element = ref.current;
        if (element) {
            const link = element.children[0];
            if (link?.tagName === 'A') link.click();
        }
    };

    const keyPressHandler = async (event) => {
        if (!canOpenSearch) return;
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event.preventDefault();
            dispatch(openSearchModal(true));
        }
        let selected = selectedItem;

        if ((event.key) === 'ArrowDown' && resultsRef) {
            selected += 1;
            nextOrPrevElement(selected);
        }
        if ((event.key) === 'ArrowUp' && resultsRef) {
            selected -= 1;
            nextOrPrevElement(selected);
        }

        if (event.key === 'Enter') handleEnter();
    };

    const handleSearch = async (event) => {
        const { value } = event.target;
        setSearch(value);
    };

    useEventListener('keydown', keyPressHandler);

    const handleClear = () => {
        dispatch(openSearchModal(!isOpen));
        setList(null);
        setSearch(null);
    };

    const onModalOpened = () => {
        if (searchRef.current) searchRef.current.focus();
    }

    const onModalClosed = () => {
        searchRef.current = null;
        resultsRef.current = null;
    }

    const onScrollResults = (e) => {
        // if current element is equal to first element in list then we set the scrollTop of
        // resultsRef parentNode (which contains sroll) to 0
        if(resultsRef?.current?.firstChild === ref.current) {
            resultsRef.current.parentNode.scrollTop = 0;
        }
    } 

    const renderSections = (type) => {
        const lowercaseType = type.toLowerCase();
        const { to, icon } = getDetails[lowercaseType];
        const result = list[lowercaseType];
        return result?.length > 0 && (
            result.map((item, idx) => (
                <div key={`${lowercaseType}-${item.id}`} data-id={idx} >
                    <ListGroupItem
                        tag={NavLink}
                        to={`${to}${item.id}`}
                        className='d-flex align-items-center selected-item'
                        onClick={handleClear}
                        activeClassName='is-item-active'
                    >
                        <div className='icon-item d-flex'>{icon(item.avatar_url)}</div>
                        <label className='custom-label'>{item.name}</label>
                    </ListGroupItem>
                </div>
            ))
        )
    };

    return (
        <Modal
            isOpen={!!isOpen}
            onOpened={onModalOpened}
            onClosed={onModalClosed}
            handleToggle={handleClear}
            header='Search'
            className="search-modal"
            hideHeader
            hideFooter
        >
            <div>
                <InputGroup>
                    <InputGroupText className='custom-input-group-text'><BiSearch /></InputGroupText>
                    <Input innerRef={searchRef} className='custom-form-control' onChange={handleSearch} placeholder={placeholderForInput} />
                </InputGroup>
                {promiseInProgress ? <LoadingIndicator /> : (list &&
                    <ListGroup onScroll={onScrollResults} className='results mt-3'>
                        <div ref={resultsRef}>
                            {renderSections('Projects')}
                            {renderSections('Reports')}
                            {renderSections('Users')}
                            {renderSections('Teams')}
                        </div>
                    </ListGroup>
                )}
            </div>
        </Modal>
    );
};

export default SearchShortcut;