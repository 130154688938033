import React, { useMemo } from 'react';
import {
    Row,
    Col,
} from 'reactstrap';
import { useGetSystemVersionsQuery } from 'services/versions';
import VersionCard from './VersionCard/VersionCard';

const Versions = () => {
    const { data } = useGetSystemVersionsQuery();

    const versions = useMemo(() => {
        if (!data) return [];
        return data;
    }, [data]);

    return (
        <div>
            <div className="header">
                <div className="container d-flex justify-content-between align-items-center">
                    <h1>Version History</h1>
                </div>
            </div>
            <div className="container pt-4">
                <Row>
                    {versions.map(version => {
                        return (
                            <Col key={`version-${version.id}`} className='pb-3' md={12}>
                                <VersionCard version={version} />
                            </Col>
                        );
                    })}
                </Row>
            </div>
        </div>
    );
}

export default Versions;