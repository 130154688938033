import React from "react";
import { Link, useOutletContext } from "react-router-dom";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import Avatar from "Components/CustomAvatar/CustomAvatar";
import styles from "../GeneralInformation/GeneralInformation.module.css";
const SaleLevelsUsers = () => {
  const { usersInformation } = useOutletContext();
  return (
    <Row>
      <Col md={12}>
        <Row>
          {!usersInformation?.users?.length && (
            <Col md={12}>
              <Alert className="m-0" color="info">
                <p className="m-0">
                  No users found for this sale levels title.
                </p>
              </Alert>
            </Col>
          )}
          {usersInformation.users &&
            usersInformation.users.map((user) => (
              <Col key={`user-${user?.id}`} xl={3} className="my-4">
                <Avatar
                  user={user}
                  size={90}
                  tag={Link}
                  to={`/users/${user?.id}`}
                  cardClassName={styles["avatar-card"]}
                  showBadges
                />
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

export default SaleLevelsUsers;
