import React from 'react';
import { Button, } from 'reactstrap';
import Modal from 'Components/Modal/Modal';
import { createToast } from 'helpers';
import { useDeleteReimbursementCategoryMutation } from 'services/reimbursements';

const DeleteReimbursementCategoryModal = ({
    isOpen,
    toggle,
    onDeleteSuccess,
    categoryId,
}) => {
    const [deleteCategoryMutation, { isLoading }] = useDeleteReimbursementCategoryMutation();
    const onDelete = async () => {
        const { data, error } = await deleteCategoryMutation(categoryId);
        if (error) {
            const {
                status,
                data: {
                    msg = 'An error has ocurred.',
                },
            } = error;
            createToast({ type: 'error', text: `${msg}\nError code ${status}` });
            return;
        }
        if (!data) return;
        onDeleteSuccess();
    }
    const modalFooter = (
        <>
            <Button
                color='secondary'
                size='sm'
                onClick={toggle}
                disabled={isLoading}
            >
                Cancel
            </Button>
            <Button
                color='danger'
                size='sm'
                onClick={onDelete}
                disabled={isLoading}
            >
                Delete
            </Button>
        </>
    );
    return (
        <>
            <Modal
                isOpen={isOpen}
                handleToggle={toggle}
                footerJustify='end'
                headerClassName='bg-danger'
                header='Delete reimbursement request category'
                footer={modalFooter}
                centered
            >
                <p className="m-0">Are you sure you want to delete this reimbursement request category?</p>
            </Modal>
        </>
    );
};

export default DeleteReimbursementCategoryModal;