import { toast } from 'react-toastify';

const createToast = ({type, text} = {type: '', text: ''}) => {
    const metaData = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    
    switch(type) {
        case 'warn':
            toast.warn(text, metaData);
            break;
        
        case 'error':
            toast.error(text, metaData);
            break;

        case 'success':
            toast.success(text, metaData);
            break;

        case 'info':
            toast.info(text, metaData);
            break;
        
        default:
            toast(text, metaData);
            break;
    }
}

export default createToast;