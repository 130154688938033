import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import DeleteRuleModal from 'pages/TimeOffRules/ShowTimeOffRule/DeleteRuleModal';
import { useGetTimeOffRuleByIdQuery } from 'services/timeOff';
import { canDeleteTimeOffRules } from 'constants/common';

const COLUMNS = [
    {
        colSpan: 6,
        label: 'Type',
        attribute: 'formatted_type',
    },
    {
        colSpan: 6,
        label: 'Legal Entity',
        attribute: 'legal_entity_name',
    },
    {
        colSpan: 6,
        label: 'Days',
        attribute: 'days',
    },
    {
        colSpan: 6,
        label: 'Start year',
        attribute: 'start_year',
    },
    {
        colSpan: 6,
        label: 'End year',
        attribute: 'end_year',
    },
];

const ShowTimeOffRule = () => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { id } = useParams();
    const { data: rule = {}, isFetching } = useGetTimeOffRuleByIdQuery(id);

    const toggleDeleteRule = () => setIsDeleteModalOpen(!isDeleteModalOpen);

    return (
        <div className="py-3">
            <PageHeader className='pb-3' title='Time Off Rule Detail'>
                <div>
                    <Button
                        className='me-2'
                        color='danger'
                        size='sm'
                        onClick={toggleDeleteRule}
                        disabled={!canDeleteTimeOffRules}
                    >
                        Delete
                    </Button>
                    <Button
                        className='me-2'
                        color='warning'
                        size='sm'
                        tag={Link}
                        to='edit'
                    >
                        Edit
                    </Button>
                    <Button color='primary' size='sm' tag={Link} to='/time-off-rules'>Back to list</Button>
                </div>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardBody>
                        <LoadingIndicator isLoading={isFetching} />
                        <Row>
                            {COLUMNS.map(({ colSpan, label, attribute }, idx) => {
                                return (
                                    <Col key={`col-${idx}`} md={colSpan ?? 12}>
                                        <FormGroup>
                                            <Label>{label ?? '-'}</Label>
                                            <p className="m-0">{rule[attribute] ?? '-'}</p>
                                        </FormGroup>
                                    </Col>
                                );
                            })}
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <DeleteRuleModal
                isOpen={isDeleteModalOpen}
                handleToggle={toggleDeleteRule}
            />
        </div>
    );
}

export default ShowTimeOffRule;