import { endOfMonth, getDate, getMonth, getYear } from 'date-fns';

const TODAY = new Date();

const quarterDate = () => {
  const year = getYear(TODAY);
  const currenthMonth = getMonth(TODAY);
  let initialQMonth, finalQMonth, finalQDay = null;
  const createDate = (month, day) => new Date(year, month, day);

  const quarterBegginsIn = {
    0: 0,
    1: 0,
    2: 0,
    3: 3,
    4: 3,
    5: 3,
    6: 6,
    7: 6,
    8: 6,
    9: 9,
    10: 9,
    11: 9
  };

  initialQMonth = quarterBegginsIn[currenthMonth];

  finalQMonth = initialQMonth + 2;
  finalQDay = getDate(endOfMonth(createDate(finalQMonth, 1)));

  return { from_date: createDate(initialQMonth, 1), to_date: createDate(finalQMonth, finalQDay)};
}

export default quarterDate;