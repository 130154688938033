import { createSlice } from "@reduxjs/toolkit";
import { saveState, loadState } from "localStorage";
import { googleLogout } from '@react-oauth/google';

const initialState = {
    loggedIn: false,
    user: {},
    permissions: {},
    token: null,
    refreshToken: null,
};

// Config slice
export const authSlice = createSlice({
    name: "auth",
    initialState: loadState('user') || initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user, token, permission, refresh_token } = action.payload;
            state.loggedIn = true;
            state.user = user;
            state.token = token;
            state.refreshToken = refresh_token;
            state.permissions = permission ? permission.reduce((carry, perm) => {
                carry[perm] = true;
                return carry;
            }, {}) : {};
            saveState(state, 'user');
        },
        logout: (state) => {
            state.loggedIn = false;
            state.token = undefined;
            state.permissions = {};
            state.user = {};
            // When using google one tap login we must use googleLogout to prevent login loops
            googleLogout();
            saveState(state, 'user');
        },
        updateUser: (state, action) => {
            const { user } = action.payload;
            state.user = user;
            saveState(state, 'user');
        },
        updatePermissions: (state, action) => {
            const { permission } = action.payload;
            state.permissions = permission ? permission.reduce((carry, perm) => {
                carry[perm] = true;
                return carry;
            }, {}) : {};
            saveState(state, 'user');
        },
    }
});

// Export actions
export const { setCredentials, logout, updateUser, updatePermissions } = authSlice.actions;

// Select state user from slice
export const selectUser = state => state.auth.user;
export const selectLoggedIn = state => state.auth.loggedIn;
export const selectPermissions = state => state.auth.permissions;

// Export reducer
export default authSlice.reducer;
