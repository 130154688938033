import React, { useEffect, lazy, Suspense } from "react";
import useEventListener from "Hooks/useEventListener";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

import Login from "pages/Login/Login";
import Versions from "./pages/versions/Versions";
import PasswordReset, {
  ForgotPassword,
} from "./pages/PasswordReset/PasswordReset";
import Onboard from "pages/Onboard/Onboard";
import { logout, refreshToken, selectUser } from "./store/slices/authSlice";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import { updateMaintenance } from "store/slices/userSlice";

import { RoleRateCards, ImportRateCards } from "pages/RoleRateCards";
import {
  Departments,
  CreateDeparment,
  EditDepartment,
  ShowDepartment,
  GIDepartment,
  Notifications,
  DepartmentUsers,
} from "pages/Departments";
import {
  CreateSales,
  EditSales,
  GIDSales,
  ShowSales,
  SaleLevels,
  SaleLevelsUsers,
} from "pages/SalesLevels";
import {
  LegalEntities,
  CreateLegalEntity,
  EditLegalEntity,
  ShowLegalEntity,
  GIEntity,
  LegalEntityUsers,
} from "pages/LegalEntities";
import {
  Reimbursements,
  ReimbursementDetail,
} from "pages/ReimbursementRequests";

import {
  ListReimbursementCategories,
  CreateReimbursementCategory,
  ShowReimbursementCategory,
  EditReimbursementCategory,
} from "pages/ReimbursementCategories";
import {
  SyncsList,
  NewSync,
  SyncPendingDetail,
  SyncDetail,
} from "pages/Jobdiva";

import {
  ListSeniorityTitles,
  CreateSeniorityTitles,
  UpdateSeniorityTitle,
  ShowSeniorityTitle,
  SeniorityTitleGeneralInformation,
  SeniorityTitleUsers,
} from "pages/SeniorityTitles";

import {
  ListHolidays,
  CreateHoliday,
  ShowHoliday,
  EditHoliday,
} from "pages/Holidays";

import {
  ListTimeOffRules,
  ShowTimeOffRule,
  CreateTimeOffRule,
  EditTimeOffRule,
} from 'pages/TimeOffRules';

import ErrorBoundary from "Components/ErrorHandler/error-handling";
import { useLazyMaintenanceModeQuery } from "services/auth";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Logout } from "containers";
import { SkeletonLoader } from "pages/Reports/loaders";
import NotFound from "Errors/NotFound/NotFound";
import { ApiErrorProvider, ApiErrorComponent } from "../src/Errors/ServerError";

const App = () => {
  const dispatch = useDispatch();
  const [getMaintenanceMode] = useLazyMaintenanceModeQuery();
  const user = useSelector(selectUser);

  useEffect(() => {
    const getData = async () => {
      const { data } = await getMaintenanceMode();
      dispatch(updateMaintenance({ maintenance_mode: data }));
    };
    getData();
    // eslint-disable-next-line
  }, []);

  const onStorageEvent = (event) => {
    const newValue = JSON.parse(event.newValue);
    const oldValue = JSON.parse(event.oldValue);
    if (!newValue || !oldValue) return;
    if (
      newValue.hasOwnProperty("loggedIn") &&
      oldValue.hasOwnProperty("loggedIn")
    ) {
      if (!newValue.loggedIn && oldValue.loggedIn) {
        dispatch(logout());
      }
    }
  };

  useEventListener("storage", onStorageEvent);
  /* REPORTS 
@TODO: Move to a separate file and our outlet */
  const Reports = lazy(() => import("./pages/Reports/Reports"));
  const BenchAvailabilityReport = lazy(() =>
    import("pages/Reports/BenchAvailabilityReport/BenchAvailabilityReport")
  );
  const ExpensePackageList = lazy(() =>
    import("./pages/Reports/ExpensePackageList/ExpensePackageList")
  );
  const ExpensesByCategory = lazy(() =>
    import("./pages/Reports/Expenses/ExpensesByCategory")
  );
  const ReportsReimbursements = lazy(() =>
    import("./pages/Reports/Reimbursements")
  );
  const Utilization = lazy(() => import("pages/Reports/Utilization"));
  const UtilizationReports = lazy(() =>
    import("pages/Reports/UtilizationReports")
  );
  const UnpaidHours = lazy(() =>
    import("pages/Reports/UnpaidHours/UnpaidHours")
  );
  const MilestonesReport = lazy(() =>
    import("pages/Reports/Milestones/Milestones")
  );
  const Milestones = lazy(() =>
    import("pages/Projects/ShowProject/Milestones/Milestones")
  );
  const MilestoneDetail = lazy(() =>
    import("pages/Projects/ShowProject/MilestoneDetail/MilestoneDetail")
  );
  const Revenue = lazy(() => import("pages/Reports/Revenue/Revenue"));
  const Invoicing = lazy(() => import("pages/Reports/Invoicing"));
  const Payments = lazy(() => import("pages/Reports/Payments"));
  const TimeOffReport = lazy(() => import("pages/Reports/Timeoff/TimeOff"));
  const TimeOff = lazy(() => import("pages/Profile/timeoff/TimeOff"));
  const TimeOffDetail = lazy(() =>
    import("pages/Profile/timeoff/TimeOffDetail")
  );
  const MissingTimelogs = lazy(() =>
    import("pages/Reports/MissingTimelogs/MissingTimeLogReport")
  );
  const ProjectStatus = lazy(() =>
    import("pages/Reports/ProjectStatus/ProjectStatus")
  );

  const InvoicingTab = lazy(() => import("pages/Projects/Invoicing/Invoicing"));
  const RateCard = lazy(() =>
    import("pages/Projects/ShowProject/RateCard/RateCard")
  );
  const AppsAndServices = lazy(() =>
    import("pages/Profile/AppsAndServices/AppsAndServices")
  );
  const MySkills = lazy(() =>
    import("pages/Profile/MySkills/MySkills")
  );

  /* REPORTS */

  /* ALLOCATION */
  const AllocationProject = lazy(() =>
    import("pages/Projects/ShowProject/AllocationProject/AllocationProject")
  );
  const Allocation = lazy(() => import("pages/Allocation/Allocation"));
  const TeamForecast = lazy(() =>
    import("pages/Allocation/TeamForecast/TeamForecast")
  );
  const RolesForecast = lazy(() =>
    import("pages/Allocation/RolesForecast/RolesForecast")
  );

  /*END ALLOCATIONS  */

  /* TIME */
  const Time = lazy(() => import("pages/Time/Time"));
  const TimeSheet = lazy(() => import("pages/Time/TimeSheet"));

  /* END TIME */

  /* APPROVALS */
  const Approvals = lazy(() => import("pages/Time/Approvals"));
  /* END APPROVALS */

  /* EXPENSES */
  const ExpensesList = lazy(() => import("pages/Expenses/Expenses"));
  const EditExpense = lazy(() =>
    import("pages/Expenses/EditExpense/EditExpense")
  );
  const ShowExpense = lazy(() =>
    import("pages/Expenses/ShowExpense/ShowExpense")
  );
  const ListExpensesCategories = lazy(() =>
    import(
      "pages/ExpensesCategories/ListExpensesCategories/ListExpensesCategories"
    )
  );
  const CreateExpenseCategory = lazy(() =>
    import(
      "pages/ExpensesCategories/CreateExpenseCategory/CreateExpenseCategory"
    )
  );
  const ShowExpenseCategory = lazy(() =>
    import("pages/ExpensesCategories/ShowExpenseCategory/ShowExpenseCategory")
  );
  const EditExpenseCategory = lazy(() =>
    import("pages/ExpensesCategories/EditExpenseCategory/EditExpenseCategory")
  );
  /* END EXPENSES */

  /* PROJECTS */
  const ProjectList = lazy(() =>
    import("pages/Projects/ProjectList/ProjectList")
  );
  const CreateProject = lazy(() =>
    import("pages/Projects/CreateProject/CreateProject")
  );
  const EditProject = lazy(() =>
    import("pages/Projects/EditProject/EditProject")
  );
  const ShowProject = lazy(() =>
    import("pages/Projects/ShowProject/ShowProject")
  );
  const GeneralInformationTab = lazy(() =>
    import("pages/Projects/GeneralInformationTab/GeneralInformationTab")
  );
  const ListProjectStatus = lazy(() =>
    import("pages/Projects/ShowProject/Status/ListStatus/ListStatus")
  );
  const ShowProjectStatus = lazy(() =>
    import("pages/Projects/ShowProject/Status/ShowStatus/ShowStatus")
  );
  const CreateProjectStatus = lazy(() =>
    import("pages/Projects/ShowProject/Status/CreateStatus/CreateStatus")
  );
  const UpdateProjectStatus = lazy(() =>
    import("pages/Projects/ShowProject/Status/UpdateStatus/UpdateStatus")
  );

  /* END PROJECTS */

  /*TEAMS */
  const Teams = lazy(() => import("./pages/Teams/Teams"));
  const CreateTeam = lazy(() => import("./pages/Teams/CreateTeam/CreateTeam"));
  const EditTeam = lazy(() => import("./pages/Teams/EditTeam/EditTeam"));
  const ShowTeam = lazy(() => import("./pages/Teams/ShowTeam/ShowTeam"));
  const DetailTeam = lazy(() =>
    import("./pages/Teams/ShowTeam/DetailTeam/DetailTeam")
  );
  const DashboardTeam = lazy(() =>
    import("./pages/Teams/ShowTeam/DashboardTeam/DashboardTeam")
  );
  const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
  const EffortTeam = lazy(() =>
    import("./pages/Teams/ShowTeam/EffortTeam/EffortTeam")
  );
  const AllocationTeam = lazy(() =>
    import("pages/Teams/ShowTeam/AllocationTeam/AllocationTeam")
  );

  const AllocationUser = lazy(() => import("pages/Users/Show/AllocationUser"));

  /* END TEAMS */

  /* BILLING */
  const BillingList = lazy(() => import("pages/Billing/BillList/BillList"));
  const CreateBilling = lazy(() => import("pages/Billing/CreateBill"));
  const Billing = lazy(() => import("pages/Billing/Bill"));
  /* END BILLING */

  /* USERS */
  const UsersSystemRoles = lazy(() =>
    import("pages/SystemRoles/UsersSystemRoles/UsersSystemRoles")
  );
  const UserList = lazy(() => import("pages/Users/List/List"));
  const UserInvite = lazy(() => import("pages/Users/Invite/Invite"));
  const InactiveList = lazy(() =>
    import("pages/Users/InactiveList/InactiveList")
  );
  const User = lazy(() => import("pages/Users/Show/Show"));
  const UserGeneralInformation = lazy(() =>
    import("pages/Users/Show/GeneralInformation")
  );
  const UserNotifications = lazy(() =>
    import("pages/Users/Show/UserNotifications")
  );
  const UserSkills = lazy(() =>
    import("pages/Users/Show/UserSkills")
  );
  const EditUser = lazy(() => import("pages/Users/Edit/Edit"));

  /* END USERS */

  /* SETTINGS */
  const ProjectSettingsTab = lazy(() =>
    import("pages/Projects/ProjectSettings/ProjectSettingsTab")
  );
  /* END SETTINGS */

  /* MEMBEERS */
  const Members = lazy(() => import("pages/Time/Members"));
  /* END MEMBERS */

  /* EXPENSES */
  const CreateExpense = lazy(() =>
    import("pages/Expenses/CreateExpense/CreateExpense")
  );
  /* END EXPENSES */

  /* IMPORT */
  const Import = lazy(() =>
    import("pages/Expenses/ImportExpense/ImportExpense")
  );
  /* END IMPORT */

  /* DISTRIBUTION */
  const Distribute = lazy(() => import("pages/Expenses/Distribute/Distribute"));
  /* END DISTRIBUTION */

  /* ECONOMY */
  const Economy = lazy(() =>
    import("pages/Projects/ShowProject/Enonomy/Economy")
  );
  const EffortBreakdown = lazy(() =>
    import("pages/Projects/ShowProject/EffortBreakdown/EffortBreakdown")
  );
  /* END ECONOMY */

  /*PROFILES */
  const Profile = lazy(() => import("pages/Profile/Profile"));
  const ShowProfile = lazy(() =>
    import("pages/Profile/ShowProfile/ShowProfile")
  );
  const EditProfile = lazy(() =>
    import("pages/Profile/EditProfile/EditProfile")
  );
  /* END PROFILES */

  /* REIMBURSEMENTS */
  const MyReimbursements = lazy(() =>
    import("pages/Profile/MyReimbursements/Reimbursements")
  );
  const MyReimbursementDetail = lazy(() =>
    import("pages/Profile/MyReimbursements/ReimbursementDetail")
  );
  /* END REIMBURSEMENTS */

  /* JOBDIVA */
  const JobDivaStarts = lazy(() =>
    import("pages/Jobdiva/JobDivaStarts/JobDivaStarts")
  );
  /* END JOBDIVA */

  /* TAGS */
  const Tags = lazy(() => import("pages/Tags/Tags"));
  /* END TAGS */

  /* ROLES */
  const Roles = lazy(() => import("pages/Roles/Roles"));
  const CreateRole = lazy(() => import("pages/Roles/Create/Create"));
  const EditRole = lazy(() => import("pages/Roles/Edit/Edit"));
  const ShowRole = lazy(() => import("pages/Roles/Show/Show"));
  const RoleDetail = lazy(() => import("pages/Roles/Show/RoleDetail"));
  const RoleUsers = lazy(() => import("pages/Roles/Show/RoleUsers"));
  const ListSystemRoles = lazy(() =>
    import("pages/SystemRoles/ListSystemRoles/ListSystemRoles")
  );
  const ShowSystemRole = lazy(() =>
    import("pages/SystemRoles/ShowSystemRole/ShowSystemRole")
  );
  const PermissionsSystemRole = lazy(() =>
    import("pages/SystemRoles/PermissionsSystemRole/PermissionsSystemRole")
  );
  /* END ROLES */

  /* ANY PROBLEM */
  const AnyProblem = lazy(() => import("Components/AnyProblem/AnyProblem"));
  /* END ANY PROBLEM */

  /*SETTINGS */
  const Settings = lazy(() => import("pages/Settings/Settings"));
  const GeneralSettings = lazy(() =>
    import("pages/Settings/GeneralSettings/GeneralSettings")
  );
  const SystemVersions = lazy(() =>
    import("pages/Settings/SystemVersions/SystemVersions")
  );
  const CreateVersion = lazy(() =>
    import("pages/Settings/SystemVersions/Create")
  );
  const UpdateVersion = lazy(() =>
    import("pages/Settings/SystemVersions/Update")
  );
  /* END SETTINGS */

  /*Managment*/
  const Managment = lazy(() => import("pages/Managment/Managment"));
  /**/

  return (
    <ApiErrorProvider>
      <>
        <ApiErrorComponent />
        <Routes>
          <Route
            path="/login"
            element={
              <ErrorBoundary>
                <Login />
              </ErrorBoundary>
            }
          />
          <Route
            path="/logout"
            element={
              <ErrorBoundary>
                <Logout />
              </ErrorBoundary>
            }
          />
          <Route
            path="/password-reset"
            element={
              <ErrorBoundary>
                <ForgotPassword />
              </ErrorBoundary>
            }
          />
          <Route
            path="/password-reset/:token"
            element={
              <ErrorBoundary>
                <PasswordReset />
              </ErrorBoundary>
            }
          />
          <Route
            path="/invite-accept/:token"
            element={
              <ErrorBoundary>
                <Onboard />
              </ErrorBoundary>
            }
          />
          <Route
            path="/any-problem"
            element={
              <Suspense fallback={<SkeletonLoader />}>
                <AnyProblem />
              </Suspense>
            }
          />

          <Route
            exact
            path="/"
            element={
              <ErrorBoundary>
                <Layout />
              </ErrorBoundary>
            }
          >
            <Route
              exact
              path="/"
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Dashboard />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for Time */}
            <Route
              path="time"
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Time />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <PrivateRoute>
                    <Suspense fallback={<SkeletonLoader />}>
                      <TimeSheet user={user} />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="approvals"
                element={
                  <PrivateRoute permissions={["APPROVE_TIMELOGS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <Approvals />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="team"
                element={
                  <PrivateRoute permissions={["TEAM_TIMELOGS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <Members />
                    </Suspense>
                  </PrivateRoute>
                }
              />
            </Route>

            {/* Subroutes for Allocation */}
            <Route
              path="allocation"
              element={<PrivateRoute permissions={["LIST_ALLOCATIONS"]} />}
            >
              <Route
                path=""
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Allocation />
                    </ErrorBoundary>
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      {" "}
                      <TeamForecast user={user} />
                    </Suspense>
                  }
                />
                <Route
                  path="roles"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <RolesForecast />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            {/* Subroutes for Expenses */}
            <Route
              path="expenses"
              element={
                <PrivateRoute permissions={["LIST_ALL_EXPENSES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <ExpensesList />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="expenses/create"
              element={
                <PrivateRoute permissions={["CREATE_EXPENSES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <CreateExpense />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="expenses/import"
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Import />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="expenses/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_EXPENSES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <EditExpense />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="expenses/:id/distribute"
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Distribute />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="expenses/:id"
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <ShowExpense />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for Expenses Categories */}
            <Route
              path="expenses-categories"
              element={
                <PrivateRoute
                  permissions={[
                    "CREATE_EXPENSESCATEGORIES",
                    "UPDATE_EXPENSESCATEGORIES",
                  ]}
                />
              }
            >
              <Route
                path=""
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ListExpensesCategories />
                  </Suspense>
                }
              />
              <Route
                path="create"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <CreateExpenseCategory />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ShowExpenseCategory />
                  </Suspense>
                }
              />
              <Route
                path=":id/edit"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <EditExpenseCategory />
                  </Suspense>
                }
              />
            </Route>

            {/* Subroutes for Projects */}
            <Route
              path="projects"
              element={
                <PrivateRoute
                  permissions={[
                    "CREATE_PROJECTS",
                    "UPDATE_PROJECTS",
                    "UPDATE_ALL_PROJECTS",
                    "ECONOMY_PROJECTS",
                    "EFFORT_PROJECTS",
                  ]}
                >
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <ProjectList />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="projects/create"
              element={
                <PrivateRoute permissions={["CREATE_PROJECTS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <CreateProject />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="projects/:id/edit"
              element={
                <PrivateRoute
                  permissions={["UPDATE_PROJECTS", "UPDATE_ALL_PROJECTS"]}
                >
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <EditProject />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              exact
              path="projects/:id"
              element={
                <PrivateRoute
                  permissions={[
                    "UPDATE_PROJECTS",
                    "UPDATE_ALL_PROJECTS",
                    "ECONOMY_PROJECTS",
                    "EFFORT_PROJECTS",
                    "LIST_PROJECTSTATUS",
                  ]}
                >
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <ShowProject user={user} />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route
                path=""
                element={
                  <PrivateRoute
                    permissions={[
                      "CREATE_PROJECTS",
                      "UPDATE_PROJECTS",
                      "UPDATE_ALL_PROJECTS",
                    ]}
                  >
                    <Suspense fallback={<SkeletonLoader />}>
                      <GeneralInformationTab />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="economy"
                element={
                  <PrivateRoute permissions={["ECONOMY_PROJECTS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <Economy />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="effort"
                element={
                  <PrivateRoute permissions={["EFFORT_PROJECTS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <EffortBreakdown />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="status"
                element={
                  <PrivateRoute permissions={["LIST_PROJECTSTATUS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ListProjectStatus />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="status/:status"
                element={
                  <PrivateRoute permissions={["LIST_PROJECTSTATUS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ShowProjectStatus />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="status/:status/edit"
                element={
                  <PrivateRoute permissions={["UPDATE_PROJECTSTATUS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <UpdateProjectStatus />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="status/create"
                element={
                  <PrivateRoute permissions={["CREATE_PROJECTSTATUS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <CreateProjectStatus />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="milestones"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<SkeletonLoader />}>
                      <Milestones user={user} />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="milestones/:milestoneId"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<SkeletonLoader />}>
                      <MilestoneDetail user={user} />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="invoicing"
                element={
                  <PrivateRoute>
                    <Suspense fallback={<SkeletonLoader />}>
                      <InvoicingTab />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="allocation"
                element={
                  <PrivateRoute permissions={["LIST_ALLOCATIONS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <AllocationProject />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="rate-card"
                element={
                  <PrivateRoute
                    permissions={["CREATE_RATECARDS", "UPDATE_RATECARDS"]}
                  >
                    <Suspense fallback={<SkeletonLoader />}>
                      <RateCard user={user} />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="settings"
                element={
                  <PrivateRoute permission="UPDATE_PROJECTS">
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <ProjectSettingsTab user={user} />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
            </Route>

            {/* Subroutes for Profile */}
            <Route path="profile" element={<PrivateRoute />}>
              <Route
                path=""
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <Profile />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <ShowProfile />
                    </Suspense>
                  }
                />
                <Route
                  path="edit"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <EditProfile user={user} />
                    </Suspense>
                  }
                />
                <Route
                  path="my-time-off"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <TimeOff user={user} />
                    </Suspense>
                  }
                />
                <Route
                  path="time-off-request/:id"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <TimeOffDetail user={user} />
                    </Suspense>
                  }
                />
                <Route
                  path="apps-and-services"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <AppsAndServices />
                    </Suspense>
                  }
                />
                <Route
                  path="skills"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <MySkills />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="reimbursements"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <MyReimbursements />
                    </Suspense>
                  }
                />
                <Route
                  exact
                  path="reimbursements/:id"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <MyReimbursementDetail user={user} />
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            {/* Subroutes for Teams */}
            <Route
              path="teams"
              element={
                <PrivateRoute permissions={["UPDATE_TEAMS", "CREATE_TEAMS"]} />
              }
            >
              <Route
                path=""
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Teams />
                    </ErrorBoundary>
                  </Suspense>
                }
              />
              <Route
                path="create"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <CreateTeam />
                  </Suspense>
                }
              />
              <Route
                path=":id/edit"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <EditTeam />
                  </Suspense>
                }
              />
              <Route
                path=":id"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ShowTeam />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <DetailTeam />
                    </Suspense>
                  }
                />
                <Route
                  path="dashboard"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <DashboardTeam />
                    </Suspense>
                  }
                />
                <Route
                  path="effort"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <EffortTeam />
                    </Suspense>
                  }
                />
                <Route
                  path="allocation"
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <AllocationTeam />
                      </ErrorBoundary>
                    </Suspense>
                  }
                />
              </Route>
            </Route>

            {/* Subroutes for Reports */}
            <Route
              path="reports"
              element={<PrivateRoute permissions={[
                "EXPENSES_BY_CATEGORY_REPORTS",
                "REIMBURSEMENTS_REPORTS",
                "EXPENSES_PACKAGING_REPORTS",
                "UTILIZATION_REPORTS",
                "UNPAID_HOURS_REPORTS",
                "MILESTONES_REPORTS",
                "REVENUE_REPORTS",
                "INVOICING_REPORTS",
                "PAYMENTS_REPORTS",
                "TIME_OFF_REPORTS",
                "LIST_BENCHAVAILABILITY",
                "LIST_REMINDER_TIMELOGS",
                "LIST_UTILIZATION_CHART_REPORTS",
                "PROJECT_STATUS_REPORTS",
              ]} />}
            >
              <Route
                path=""
                element={
                  <ErrorBoundary>
                    <Suspense fallback={<SkeletonLoader />}>
                      <Reports />
                    </Suspense>
                  </ErrorBoundary>
                }
              />
              <Route
                path="expenses-by-category"
                element={
                  <PrivateRoute permissions={['EXPENSES_BY_CATEGORY_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <ExpensesByCategory />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="reimbursements"
                element={
                  <PrivateRoute permissions={['REIMBURSEMENTS_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <ReportsReimbursements />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="expense-packages"
                element={
                  <PrivateRoute permissions={['EXPENSES_PACKAGING_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <ExpensePackageList />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="team-utilization"
                element={
                  <PrivateRoute permissions={['UTILIZATION_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <Utilization />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="team-utilization-reports"
                element={
                  <PrivateRoute permissions={['LIST_UTILIZATION_CHART_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <UtilizationReports />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="unpaid-hours"
                element={
                  <PrivateRoute permissions={['UNPAID_HOURS_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <UnpaidHours />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="milestones"
                element={
                  <PrivateRoute permissions={['MILESTONES_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <MilestonesReport />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="revenue"
                element={
                  <PrivateRoute permissions={['REVENUE_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <Revenue />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="invoicing"
                element={
                  <PrivateRoute permissions={['INVOICING_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <Invoicing />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="payments"
                element={
                  <PrivateRoute permissions={['PAYMENTS_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <Payments />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path="time-off"
                element={
                  <PrivateRoute permissions={['TIME_OFF_REPORTS']}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <TimeOffReport />
                      </ErrorBoundary>
                    </Suspense>
                  </PrivateRoute>
                }
              />
            </Route>
            <Route
              path="reports/bench-availability-report"
              element={
                <PrivateRoute permissions={["LIST_BENCHAVAILABILITY"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <BenchAvailabilityReport />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="reports/missing-timelogs"
              element={
                <PrivateRoute permissions={["LIST_REMINDER_TIMELOGS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <MissingTimelogs />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="reports/project-status"
              element={
                <PrivateRoute permissions={["LIST_REMINDER_TIMELOGS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <ProjectStatus />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for Roles */}
            <Route
              path="roles"
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonLoader />}>
                    <Roles />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="roles/create"
              element={
                <PrivateRoute permissions={["CREATE_ROLES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <CreateRole />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path='roles/:id'
              element={
                <PrivateRoute permissions={["UPDATE_ROLES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ShowRole />
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route index element={<RoleDetail />} />
              <Route
                path='users'
                element={<RoleUsers />}
              />
            </Route>
            <Route
              path="roles/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_ROLES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <EditRole />
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for System Roles */}
            <Route
              path="system-roles"
              element={
                <PrivateRoute
                  permissions={["CREATE_SYSTEMROLES", "UPDATE_SYSTEMROLES"]}
                >
                  <Suspense fallback={<SkeletonLoader />}>
                    <ListSystemRoles />
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="system-roles/:id"
              element={
                <PrivateRoute permissions={["UPDATE_SYSTEMROLES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ShowSystemRole />
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route index element={<PermissionsSystemRole />} />
              <Route
                path="users"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <UsersSystemRoles />
                  </Suspense>
                }
              />
            </Route>

            {/* Subroutes for tags */}
            <Route
              path="/tags"
              element={
                <PrivateRoute permissions={["CREATE_TAGS", "UPDATE_TAGS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <Tags />
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for Billing */}
            <Route
              path="billing"
              element={
                <PrivateRoute permissions={["LIST_BILLS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <BillingList />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="billing/create"
              element={
                <PrivateRoute permissions={["CREATE_BILLS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <CreateBilling />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="billing/:id"
              element={
                <PrivateRoute permissions={["LIST_BILLS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Billing />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for Users */}
            <Route
              path="management"
              element={
                <PrivateRoute>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Managment />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="sale-levels"
              element={
                <PrivateRoute
                  permissions={["LIST_SALELEVELS", "CREATE_SALELEVELS"]}
                >
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <SaleLevels />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            <Route
              path="sale-levels/:id"
              element={
                <PrivateRoute permissions={["LIST_SALELEVELS"]}>
                  <ShowSales />
                </PrivateRoute>
              }
            >
              <Route index element={<GIDSales />} />
              <Route path="users" element={<SaleLevelsUsers />} />
            </Route>

            <Route
              path="sale-levels/create"
              element={
                <PrivateRoute permissions={["CREATE_SALELEVELS"]}>
                  <CreateSales />
                </PrivateRoute>
              }
            />

            <Route
              path="sale-levels/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_DEPARTMENTS"]}>
                  <EditSales />
                </PrivateRoute>
              }
            />

            <Route
              path="users"
              element={
                <PrivateRoute permissions={["CREATE_USERS", "UPDATE_USERS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <UserList />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="users/invite"
              element={
                <PrivateRoute permissions={["CREATE_USERS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <UserInvite />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="users/inactive"
              element={
                <PrivateRoute permissions={["CREATE_USERS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <InactiveList />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="users/:id"
              element={
                <PrivateRoute permissions={["UPDATE_USERS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <User />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route
                path="users/allocation"
                element={<PrivateRoute permissions={["LIST_ALLOCATIONS"]} />}
              >
                <Route
                  path=""
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <ErrorBoundary>
                        <AllocationUser user={user} />
                      </ErrorBoundary>
                    </Suspense>
                  }
                ></Route>
              </Route>
              <Route
                index
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <UserGeneralInformation />
                  </Suspense>
                }
              />
              <Route path="notifications" element={<UserNotifications />} />
              <Route path='skills' element={<UserSkills />} />
            </Route>
            <Route
              path="users/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_USERS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <EditUser />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for RoleRateCards */}
            <Route
              path="roles-rate-cards"
              element={
                <PrivateRoute
                  permissions={["CREATE_RATECARDS", "UPDATE_RATECARDS"]}
                />
              }
            >
              <Route index element={<RoleRateCards />} />
              <Route path="import" element={<ImportRateCards />} />
            </Route>

            {/* Subroutes for Departments */}
            <Route
              path="departments"
              element={
                <PrivateRoute
                  permissions={["CREATE_DEPARTMENTS", "UPDATE_DEPARTMENTS"]}
                >
                  <Departments />
                </PrivateRoute>
              }
            />
            <Route
              path="departments/create"
              element={
                <PrivateRoute permissions={["CREATE_DEPARTMENTS"]}>
                  <CreateDeparment />
                </PrivateRoute>
              }
            />
            <Route
              path="departments/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_DEPARTMENTS"]}>
                  <EditDepartment />
                </PrivateRoute>
              }
            />
            <Route
              path="departments/:id"
              element={
                <PrivateRoute permissions={["UPDATE_DEPARTMENTS"]}>
                  <ShowDepartment />
                </PrivateRoute>
              }
            >
              <Route index element={<GIDepartment />} />
              <Route path="notifications" element={<Notifications />} />
              <Route path="users" element={<DepartmentUsers />} />
            </Route>

            {/* Subroutes for Legal Entities */}
            <Route
              path="legal-entities"
              element={
                <PrivateRoute
                  permissions={["CREATE_LEGALENTITIES", "UPDATE_LEGALENTITIES"]}
                >
                  <LegalEntities />
                </PrivateRoute>
              }
            />

            <Route
              path="legal-entities/create"
              element={
                <PrivateRoute permissions={["CREATE_LEGALENTITIES"]}>
                  <CreateLegalEntity />
                </PrivateRoute>
              }
            />
            <Route
              path="legal-entities/:id"
              element={
                <PrivateRoute permissions={["UPDATE_LEGALENTITIES"]}>
                  <ShowLegalEntity />
                </PrivateRoute>
              }
            >
              <Route index element={<GIEntity />} />
              <Route path='users' element={<LegalEntityUsers />} />
            </Route>
            <Route
              path="legal-entities/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_LEGALENTITIES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <EditLegalEntity />
                  </Suspense>
                </PrivateRoute>
              }
            />

            {/* Subroutes for Reimbursement Requests */}
            <Route
              path="reimbursements"
              element={
                <PrivateRoute permissions={["LIST_ALL_REIMBURSEMENTREQUEST"]} />
              }
            >
              <Route index element={<Reimbursements />} />
              <Route path=":id" element={<ReimbursementDetail user={user} />} />
            </Route>

            {/* Subroutes for Reimbursement Categories */}
            <Route
              path="reimbursement-categories"
              element={
                <PrivateRoute
                  permissions={["LIST_ALL_REIMBURSEMENTCATEGORIES"]}
                >
                  <ListReimbursementCategories />
                </PrivateRoute>
              }
            />
            <Route
              path="reimbursement-categories/create"
              element={
                <PrivateRoute permissions={["CREATE_REIMBURSEMENTCATEGORIES"]}>
                  <CreateReimbursementCategory />
                </PrivateRoute>
              }
            />
            <Route
              path="reimbursement-categories/:id"
              element={
                <PrivateRoute
                  permissions={["LIST_ALL_REIMBURSEMENTCATEGORIES"]}
                >
                  <ShowReimbursementCategory />
                </PrivateRoute>
              }
            />
            <Route
              path="reimbursement-categories/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_REIMBURSEMENTCATEGORIES"]}>
                  <EditReimbursementCategory />
                </PrivateRoute>
              }
            />

            {/* Subroutes for Jobdiva */}
            <Route
              path="jobdiva"
              element={<PrivateRoute permissions={["INTEGRATION_JOBDIVA"]} />}
            >
              <Route
                index
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <JobDivaStarts />
                  </Suspense>
                }
              />
              <Route path="syncs" element={<SyncsList />} />
              <Route path="syncs/new" element={<NewSync />} />
              <Route path="syncs/pending/:id" element={<SyncPendingDetail />} />
              <Route path="syncs/:status/:id" element={<SyncDetail />} />
            </Route>

            <Route path="/versions" element={<PrivateRoute />}>
              <Route index element={<Versions />} />
            </Route>

            {/* Subroutes for Settings */}
            <Route
              path="settings"
              element={
                <PrivateRoute permissions={["LIST_SETTINGS"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ErrorBoundary>
                      <Settings />
                    </ErrorBoundary>
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <GeneralSettings />
                  </Suspense>
                }
              />
              <Route
                path="system-versions"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <SystemVersions />
                  </Suspense>
                }
              />
              <Route
                path="system-versions/create"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <CreateVersion />
                  </Suspense>
                }
              />
              <Route
                path="system-versions/:id"
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <UpdateVersion />
                  </Suspense>
                }
              />
            </Route>
            {/* Subroutes for Seniority Titles */}
            <Route
              path="seniority-titles"
              element={
                <PrivateRoute
                  permissions={[
                    "CREATE_SENIORITYTITLES",
                    "UPDATE_SENIORITYTITLES",
                  ]}
                >
                  <ListSeniorityTitles />
                </PrivateRoute>
              }
            />
            <Route
              path="seniority-titles/create"
              element={
                <PrivateRoute permissions={["CREATE_SENIORITYTITLES"]}>
                  <CreateSeniorityTitles />
                </PrivateRoute>
              }
            />
            <Route
              path="seniority-titles/:id"
              element={
                <PrivateRoute permissions={["UPDATE_SENIORITYTITLES"]}>
                  <Suspense fallback={<SkeletonLoader />}>
                    <ShowSeniorityTitle />
                  </Suspense>
                </PrivateRoute>
              }
            >
              <Route index element={<SeniorityTitleGeneralInformation />} />
              <Route path="users" element={<SeniorityTitleUsers />} />
            </Route>
            <Route
              path="seniority-titles/:id/edit"
              element={
                <PrivateRoute permissions={["UPDATE_SENIORITYTITLES"]}>
                  <UpdateSeniorityTitle />
                </PrivateRoute>
              }
            />
            {/* Subroutes for Holidays */}
            <Route path="holidays" element={<PrivateRoute permissions={["CREATE_HOLIDAYS", "UPDATE_HOLIDAYS"]} />}>
              <Route
                index
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ListHolidays />
                  </Suspense>
                }
              />
              <Route
                path="create"
                element={
                  <PrivateRoute permissions={["CREATE_HOLIDAYS"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <CreateHoliday />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path=":id"
                element={<PrivateRoute permissions={["UPDATE_HOLIDAYS"]} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <ShowHoliday />
                    </Suspense>
                  }
                />
                <Route
                  path='edit'
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <EditHoliday />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            {/* Subroutes for Time Off Rules */}
            <Route path="time-off-rules" element={<PrivateRoute permissions={["CREATE_TIMEOFFRULES", "UPDATE_TIMEOFFRULES"]} />}>
              <Route
                index
                element={
                  <Suspense fallback={<SkeletonLoader />}>
                    <ListTimeOffRules />
                  </Suspense>
                }
              />
              <Route
                path="create"
                element={
                  <PrivateRoute permissions={["CREATE_TIMEOFFRULES"]}>
                    <Suspense fallback={<SkeletonLoader />}>
                      <CreateTimeOffRule />
                    </Suspense>
                  </PrivateRoute>
                }
              />
              <Route
                path=":id"
                element={<PrivateRoute permissions={["UPDATE_TIMEOFFRULES"]} />}
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <ShowTimeOffRule />
                    </Suspense>
                  }
                />
                <Route
                  path='edit'
                  element={
                    <Suspense fallback={<SkeletonLoader />}>
                      <EditTimeOffRule />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </ApiErrorProvider>
  );
};

export default App;
