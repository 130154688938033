import React from 'react';
import { Input, } from 'reactstrap';
import {
    FiChevronsLeft,
    FiChevronLeft,
    FiChevronsRight,
    FiChevronRight,
} from 'react-icons/fi';
import classes from './TablePagination.module.css';

const PAGE_SIZE = [10, 20, 30, 40, 50];

const TablePagination = ({
    canNextPage,
    canPreviousPage,
    handlePreviousPage,
    handleFirstPage,
    handleNextPage,
    handleLastPage,
    handleChangeInput,
    handleChangeInSelect,
    pageIndex,
    pageOptions = [],
    pageSize = 10,
}) => {
    return (
        <div className={`${classes['table-pagination']}`}>
            <div className={`${classes['items-wrapper']}`}>
                <span color='primary' onClick={handleFirstPage} disabled={!canPreviousPage}>
                    <FiChevronsLeft />
                </span>
                <span color='primary' onClick={handlePreviousPage} disabled={!canPreviousPage}>
                    <FiChevronLeft />
                </span>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageOptions.length}
                </strong>
                <Input
                    type='number'
                    min={1}
                    style={{ width: 50 }}
                    max={pageOptions.length}
                    defaultValue={pageIndex + 1}
                    onChange={handleChangeInput}
                />
                <Input type='select' value={pageSize} onChange={handleChangeInSelect}>
                    {PAGE_SIZE.map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </Input>
                <span color='primary' onClick={handleNextPage} disabled={!canNextPage}>
                    <FiChevronRight />
                </span>
                <span color='primary' onClick={handleLastPage} disabled={!canNextPage}>
                    <FiChevronsRight />
                </span>
            </div>
        </div>
    );
};

export default TablePagination;