import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Alert, Card,
    CardBody,
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    CardHeader,
    Button,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';
import { FiSearch } from "react-icons/fi";
import { FaBuilding } from "react-icons/fa";
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import PageHeader from 'Components/PageHeader/PageHeader';
import checkPermission from 'helpers/checkPermission';
import { useGetLegalEntitiesQuery } from 'services/legalEntities';

const canCreateLegalEntities = checkPermission('CREATE_LEGALENTITIES');

const initialFilters = {
    name: '',
};

const LegalEntities = () => {
    const [filters, setFilters] = useState(initialFilters);
    const { data: legalEntities = [], isFetching: isLoadingEntities } = useGetLegalEntitiesQuery();
    const { name } = filters;

    const onFiltersInputChange = ({ target: { name, value } }) => setFilters(prevFilters => ({ ...prevFilters, [name]: value }));

    const filterLegalEntities = entity => {
        if (name.trim().length > 0) {
            if (!entity.name.toLowerCase().includes(name.toLowerCase())) {
                return false;
            }
        }
        return true;
    }

    const clearFilters = () => setFilters(initialFilters);


    return (
        <div>
            <PageHeader title='Legal Entities' containerClass='container' className='pb-3'>
                <Button color='primary' tag={Link} to='create' disabled={!canCreateLegalEntities}>Add new</Button>
            </PageHeader>
            <div className="container pb-3">
                <Row>
                    <Col md="8">
                        <LoadingIndicator isLoading={isLoadingEntities} />
                        {
                            !!legalEntities.length ?
                                <ListGroup>
                                    {legalEntities.filter(filterLegalEntities).map(entity =>
                                        <ListGroupItem key={`legalEntity-${entity.id}`} tag={Link} to={`/legal-entities/${entity.id}`}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6 className="mb-0 link-primary">{entity.name}</h6>
                                                    <small className="text-muted">Tax Id: {entity.tax_id}</small>
                                                </div>
                                                <div>
                                                    <FaBuilding className="ms-2" />
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                    )}
                                </ListGroup>
                                :
                                <Alert color="warning" className="mb-0">
                                    <small>No entities have been created.</small>
                                </Alert>
                        }
                    </Col>
                    <Col md="4">
                        <Card>
                            <CardHeader className='d-flex justify-content-between align-items-center'>
                                <h4><FiSearch className='me-2' /> Filter</h4>
                            </CardHeader>
                            <CardBody>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type='text' value={name} name='name' onChange={onFiltersInputChange} />
                                </FormGroup>
                                <Button block color='secondary' type='button' disabled={Object.keys(filters).reduce((carry, el) => carry && !filters[el], true)} onClick={clearFilters}>Reset</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </div>
        </div>
    );
}

export default LegalEntities;