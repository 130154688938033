import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useChannel from './useChannel';
import { selectUser, updatePermissions } from 'store/slices/authSlice';
import { updateMaintenance, openPageReloadModal } from 'store/slices/userSlice';

const useMaintenanceModeChannel = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const { response } = useChannel({
        channelName: `channel-maintenance-mode${user?.id ? `.${user.id}` : ''}`,
        eventName: 'zetta-changes-notification',
    });

    useEffect(() => {
        if (!response) return;
        const { maintenance_mode, is_super_admin, permission } = response;
        const maintenanceMode = {
            maintenance_mode,
            is_super_admin,
        };
        dispatch(updatePermissions({ permission }));
        dispatch(updateMaintenance({ maintenance_mode: maintenanceMode }));
        dispatch(openPageReloadModal(true));

    }, [response]);
}

export default useMaintenanceModeChannel;