import React from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { Button, NavLink as RRNavLink } from "reactstrap";
import { useGetSalesByIdQuery } from "services/sales";
import { checkPermission } from "helpers";
import PageHeader from "Components/PageHeader/PageHeader";
import NavTab from "Components/NavTab/NavTab";
import LoadingIndicator from "Widgets/LoadingIndicator/LoadingIndicator";
const canUpdateSales = checkPermission("UPDATE_SALELEVELS");

const ShowSales = () => {
  const { id } = useParams();
  const { data: sales = {}, isFetching } = useGetSalesByIdQuery(id);

  const usersProps = {
    users: sales?.users ?? [],
  };
 

  const links = [
    {
      name: "General Information",
      path: `/sale-levels/${id}`,
      isVisible: true,
    },
    {
      name: "Users",
      path: `/sale-levels/${id}/users`,
      isVisible: true,
    },
  ];

  return (
    <div>
      <PageHeader
        title={`Sales level: ${sales.name}`}
        containerClass="container"
        className="pb-3"
      >
        <div>
          <Button
            className="me-2"
            color="warning"
            tag={Link}
            to={`edit`}
            disabled={!canUpdateSales}
          >
            Edit
          </Button>
          <Button tag={Link} to="/sale-levels">
            Back to list
          </Button>
        </div>
      </PageHeader>
      <LoadingIndicator isLoading={isFetching} />
      <div className="container pb-2">
        <div className="pt-4">
          <NavTab links={links} />
          <Outlet context={{ sales: sales, usersInformation: usersProps }} />
        </div>
      </div>
    </div>
  );
};

export default ShowSales;
