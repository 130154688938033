import React from 'react';

const LoadingIndicatorText = ({
    isLoading,
    notLoadingPlaceholder = '',
    loadingPlaceholder = '',
}) => {
    return isLoading ? (
        <>
            <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" />
            {loadingPlaceholder ?? notLoadingPlaceholder}
        </>
    ) : (notLoadingPlaceholder);
};

export default LoadingIndicatorText;