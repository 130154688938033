import React from 'react';
import {
    Row,
    Col,
    FormGroup,
    FormFeedback,
    Label,
    Input,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import SelectWithQuery from 'Components/SelectWithQuery/SelectWithQuery';
import { useGetLegalEntitiesQuery } from 'services/legalEntities';
import { customSelectStyles, selectErrorStyles } from 'constants/common';

const legalEntitiesOpts = { label: 'name', value: 'id' };

const HolidaysForm = ({
    data = {},
    errors = {},
    handleChange,
}) => {
    const onInputChange = ({ target: { name, value } }) => handleChange({ name, value });
    const onSelectChange = (opt, { name }) => handleChange({ name, value: opt?.value });
    const onDateChange = (date = '', name) => handleChange({ name, value: date });

    return (
        <Row>
            <Col md={6}>
                <FormGroup>
                    <Label>Name</Label>
                    <Input
                        type='text'
                        value={data?.name}
                        name='name'
                        onChange={onInputChange}
                        invalid={!!errors?.name}
                    />
                    {!!errors?.name && <FormFeedback className='d-block'>{errors.name}</FormFeedback>}
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label>Legal Entity</Label>
                    <SelectWithQuery
                        query={useGetLegalEntitiesQuery}
                        selected={data?.legal_entity_id}
                        dataOpts={legalEntitiesOpts}
                        name='legal_entity_id'
                        placeholder='Select legal entity'
                        styles={!!errors?.legal_entity_id ? selectErrorStyles : customSelectStyles}
                        handleChange={onSelectChange}
                    />
                    {!!errors?.legal_entity_id && <FormFeedback className='d-block'>{errors.legal_entity_id}</FormFeedback>}
                </FormGroup>
            </Col>
            <Col md={6}>
                <FormGroup>
                    <Label>Date</Label>
                    <DatePicker
                        customInput={<Input type='text' invalid={!!errors?.date} />}
                        selected={data?.date}
                        onChange={date => onDateChange(date, 'date')}
                        dateFormat='dd-MM-yyyy'
                    />
                    {!!errors?.date && <FormFeedback className='d-block'>{errors.date}</FormFeedback>}
                </FormGroup>
            </Col>
        </Row>
    );
};

export default HolidaysForm;