import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import Modal from 'Components/Modal/Modal';
import { selectPageReloadModal, openPageReloadModal } from 'store/slices/userSlice';

const PageReloadModal = () => {
    const [countdown, setCountdown] = useState(10);
    const dispatch = useDispatch();
    const isModalOpen = useSelector(selectPageReloadModal);

    const closeModal = () => dispatch(openPageReloadModal(false));

    const onModalClosed = () => window.location.href = window.location.pathname;

    useEffect(() => {
        if (!isModalOpen) return;
        const timer = countdown > 0 && setTimeout(() => setCountdown(countdown - 1), 1000);
        if (!countdown) dispatch(openPageReloadModal(false));
        return () => clearTimeout(timer);
    }, [countdown, isModalOpen]);

    return (
        <Modal
            isOpen={isModalOpen}
            handleToggle={closeModal}
            onClosed={onModalClosed}
            centered
            hideHeader
            hideFooter
        >
            <div className="d-flex flex-column align-items-center">
                <p className="pt-2">
                    Heads up! We have changes for you, this page will automatically refresh after 10 seconds or upond closing this alert.
                </p>
                <Button className='mb-2' color='primary' size='sm' onClick={closeModal}>Refresh now</Button>
                <p className="m-0">Time left:</p>
                <h3>{countdown}</h3>
            </div>
        </Modal>
    );
}

export default PageReloadModal;