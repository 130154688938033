import React from 'react';
import UserList from 'Components/UsersList/UsersList';
import { useOutletContext } from 'react-router-dom';

const DepartmentUsers = () => {
    const { usersProps } = useOutletContext();
    const { users = [], isLoading } = usersProps;
    const componentProps = { users, isLoading, notFoundText: 'No users found for this department' };

    return (
        <UserList {...componentProps} />
    );
};

export default DepartmentUsers;