import React, { useState } from 'react';
import {
    InputGroup,
    InputGroupText,
    Input,
} from 'reactstrap';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import './PasswordInput.css';

const PasswordInput = ({
    value = '',
    name = '',
    placeholder = '',
    hasError = false,
    handleChange,
    disabled,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const type = isVisible ? 'text' : 'password';

    const toggleIsVisible = () => setIsVisible(!isVisible);

    return (
        <InputGroup>
            <Input
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                invalid={hasError}
                disabled={disabled}
            />
            <InputGroupText role='button' className='password-input' onClick={toggleIsVisible}>
                {isVisible ? <MdVisibilityOff /> : <MdVisibility />}
            </InputGroupText>
        </InputGroup>
    );
}

export default PasswordInput;