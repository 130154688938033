import React from 'react';
import { useSelector } from 'react-redux';
import { Media } from 'reactstrap';
import { selectDarkMode } from 'store/slices/userSlice';
import maintenanceLight from './maintenance-light.svg';
import maintenanceDark from './maintenance-dark.svg';
import './MaintenanceMode.css';

const MaintenanceMode = () => {
    const darkModeActive = useSelector(selectDarkMode);

    return (
        <>
            <div id='maintenance-container'>
                <div className="box">
                    <div className='image-container'>
                        <Media className='m-0' src={darkModeActive ? maintenanceDark : maintenanceLight} fluid />
                    </div>
                    <div className="text-container">
                        <h1 className='mb-0'>Website under maintenance</h1>
                        <p className='m-0'>
                            This website is currently down for maintenance.
                        </p>
                        <p className="m-0">
                            We apologize for any inconveniences cause.
                        </p>
                        <p className="m-0">
                            We've almost done. Come back soon!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MaintenanceMode;
