import { format } from "date-fns";

/**
 * Method to create a new form data from an object, this method process
 * all key values to only include data with a value
 * @param {*} object of data
 * @returns a new FormData
 */
const getFormData = (obj = {}) => {
    const formData = new FormData();

    for(let [k, v] of Object.entries(obj)) {
        if(!v) continue;
        if(v instanceof Date) v = format(v, 'yyyy-MM-dd');
        if(Array.isArray(v)) {
            v.forEach((val, idx) => {
                if(val instanceof Object && !Array.isArray(val)) {
                    for(let [kj, vj] of Object.entries(val)) {
                        formData.append(`${k}[${idx}][${kj}]`, vj);
                    }
                } else {
                    formData.append(`${k}[]`, val);
                }
            });
            continue;
        }
        formData.append(k, v);
    }

    return formData;
}

export default getFormData;