import createToast from "./createToast";

const handleUploadReimbursementFiles = (acceptedFiles = [], rejectedFiles = []) => {
    if(rejectedFiles.length) {
        throw createToast({type: 'error', text: 'You can only import files with PDF, XML or PNG/JPEG/JPG format.'});
    }

    if(acceptedFiles.length && acceptedFiles.length > 3) {
        throw createToast({ type: 'error', text: 'You can only import three files.' });
    }

    const data = {};

    for (let index = 0; index < acceptedFiles.length; index++) {
        const file = acceptedFiles[index];
        if(file.type.endsWith('pdf')) {
            data.file = file;
        }

        if(file.type.endsWith('xml')) {
            data.xml = file;
        }

        if(file.type.endsWith('png') || file.type.endsWith('jpeg')) {
            data.image = file;
        }
    }

    return data;
}

export default handleUploadReimbursementFiles;