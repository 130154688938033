import React from 'react';
import { Link, useParams, Outlet } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import NavTab from 'Components/NavTab/NavTab';
import { useGetLegalEntityByIdQuery } from 'services/legalEntities';
import { canUpdateLegalEntities } from 'constants/common';

const ShowLegalEntity = () => {
    const { id } = useParams();
    const { data: legalEntity = {}, isFetching } = useGetLegalEntityByIdQuery(id);

    const usersProps = {
        users: legalEntity?.users ?? [],
        isLoading: isFetching,
    };

    const navLinks = [
        {
            name: 'General Information',
            path: `/legal-entities/${id}`,
            isVisible: true,
        },
        {
            name: 'Users',
            path: `/legal-entities/${id}/users`,
            isVisible: true,
        },
    ];

    const outletContext = { usersProps, legalEntity };

    return (
        <div>
            <PageHeader title='Legal Entity Detail' containerClass='container' className='pb-3'>
                <div>
                    <Button className="me-2" color='warning' tag={Link} to={`/legal-entities/${id}/edit`} disabled={!canUpdateLegalEntities}>
                        Edit
                    </Button>
                    <Button tag={Link} to='/legal-entities'>Back to entities list</Button>
                </div>
            </PageHeader>
            <div className="container pb-3">
                <NavTab links={navLinks} />
                <Outlet context={outletContext} />
            </div>
        </div>
    );
};

export default ShowLegalEntity;