import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import ReimbursementCategoryForm from 'pages/ReimbursementCategories/ReimbursementCategoryForm/ReimbursementCategoryForm';
import { useCreateReimbursementCategoryMutation } from 'services/reimbursements';

const CreateReimbursementCategory = () => {
    const [category, setCategory] = useState({
        name: '',
        expense_category_id: 1,
        display: true,
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [createCategory, { isLoading }] = useCreateReimbursementCategoryMutation();
    const navigate = useNavigate();

    const handleCategoryChange = (name, value) => setCategory(prevCategory => ({ ...prevCategory, [name]: value, }));

    const onInputChange = ({ target: { name, value, type } }) => {
        let newValue = value;
        if (type === 'radio') newValue = parseInt(value);
        handleCategoryChange(name, newValue);
    }

    const onSelectChange = (option, { name }) => handleCategoryChange(name, option?.value);

    const onSwitchChange = (checked, e, id) => handleCategoryChange(id, checked);

    const onButtonClick = async () => {
        const { data, error } = await createCategory(category);
        if (error) {
            setErrorMessage(error.data?.message || '');
            setErrors(error.data?.errors ?? {});
            return;
        }
        if (!data) return;
        navigate('/reimbursement-categories');
    }

    return (
        <>
            <PageHeader title='Create Reimbursement Category' className='pb-3'>
                <Button
                    color='secondary'
                    size='sm'
                    tag={Link}
                    to='/reimbursement-categories'
                >
                    Return to list
                </Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <ReimbursementCategoryForm
                    category={category}
                    errors={errors}
                    errorMessage={errorMessage}
                    buttonText='Save'
                    loadingButtonText='Saving...'
                    headerText='Create new'
                    handleInput={onInputChange}
                    handleSelect={onSelectChange}
                    handleSwitch={onSwitchChange}
                    handleButtonClick={onButtonClick}
                    isLoading={isLoading}
                />
            </div>
        </>
    )
}

export default CreateReimbursementCategory;