import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { ToastContainer } from "react-toastify";
import { FormGroup, Label, Input, Button, Form } from "reactstrap";
import PasswordInput from "Components/PasswordInput/PasswordInput";
import LoginModal from 'Components/Modals/LoginModal/LoginModal';
import { useLoginMutation, useLazyMaintenanceModeQuery } from "services/auth";
import {
  selectUser,
  selectLoggedIn,
  setCredentials,
} from "store/slices/authSlice";
import { updateMaintenance } from "store/slices/userSlice";
import Utils from "Utils";
import { createToast } from "helpers";
import "./login.css";

const {
  REACT_APP_IS_PASSWORD_LOGIN_ALLOWED,
  REACT_APP_GOOGLE_FORM_XPH,
  REACT_APP_IS_GOOGLE_LOGIN_ALLOWED
} = process.env;

const Login = () => {
  const user = useSelector(selectUser);
  const loggedIn = useSelector(selectLoggedIn);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const isUserEmpty = Object.keys(user).length === 0;
  const [shouldSuggestPreviousUser, setShouldSuggestPreviousUser] = useState(user && !isUserEmpty);
  const previousUserEmail = user && !isUserEmpty ? user?.email : '';
  const previousUserName = user && !isUserEmpty ? user?.display_name : '';
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const [requestMaintenance] = useLazyMaintenanceModeQuery();

  const doLogin = async (credentials) => {
    const { data, error } = await login(credentials);
    if (error) {
      createToast({
        type: "warn",
        text: error.data?.error ?? "Something went wrong.",
      });
      return;
    }
    if (!data || !data.hasOwnProperty("token")) return;
    Utils.setBearerToken(data.token);
    dispatch(setCredentials(data));
    const { data: maintenanceMode } = await requestMaintenance();
    dispatch(updateMaintenance({ maintenance_mode: maintenanceMode }));
  };

  const handleLogin = (event) => {
    const data = {
      email: shouldSuggestPreviousUser ? previousUserEmail : email,
      password,
    };
    doLogin(data);
    event.preventDefault();
  };

  const handleGoogleLogin = (googleData) => {
    if (!googleData.credential) return;
    const { email } = jwt_decode(googleData.credential);
    setEmail(email);

    const data = {
      id_token: googleData.credential,
      email,
    };
    doLogin(data);
  };

  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const forgetPreviousUser = () => setShouldSuggestPreviousUser(false);

  return loggedIn ? (
    (window.location.href = "/")
  ) : (
    <div className="fullpage-login-container">
      <ToastContainer />
      <LoginModal header='Important Update: New Access Requirements for Zetta'>
        <div className='container align-items-center justify-content-vetween'>
          <div className='row align-items-center'>
            <div className='col-5'>
              <img src="./login-modal.svg" alt='modal-image' className="img-fluid" />
            </div>
            <div className='col-7'>
              <p className="text-justify">
                Dear Team,<br /><br />
                We would like to inform you that starting today, <b>access to Zetta will require a username and password. Your username will be your @improving.com email</b>, and you will need to set your own password.<br /><br />
                <b>To set your password, please log out of Zetta and check your Improving email inbox for a message from us titled "Zetta Invitation."</b> If you can't find it in your inbox, it may be in your "Other" folder.
                <b> In the email, you will find an "Accept Invite" button that will take you to a form page</b> where you can set your password and provide some additional personal information.<br /><br />
                <b>If you have any issues or questions, please reach out to <a className='mail-to' href="mailto:carlo.medina@improving.com">carlo.medina@improving.com</a></b>.<br /><br />
                Best regards.
              </p>
            </div>
          </div>
        </div>
      </LoginModal>
      <div className="image-column d-flex flex-column justify-content-center align-items-center">
        <img
          src="./z-login-white.svg"
          height="400"
          alt="z-Logolg"
          className="fpi-logo d-none d-sm-none d-md-none d-lg-block d-xl-block"
        />
        <img
          src="./images/logo-dark.svg"
          height="100"
          alt="z-Logomd"
          className="d-lg-none d-xl-none"
        />
      </div>
      <div className="control-column">
        <div className="text-start alt-logo">
          <img
            src="./images/logo.svg"
            height="80"
            alt="z-Logosm"
            className="mb-4"
          />
        </div>
        <h1 className="mb-0">
          Hello{shouldSuggestPreviousUser ? ` ${previousUserName}` : ""}
          !&#128075;
        </h1>
        {REACT_APP_IS_PASSWORD_LOGIN_ALLOWED === "true" && (
          <p className="mt-3">Sign in using your email and password.</p>
        )}
        <div className="text-start">
          <Form onSubmit={handleLogin}>
            {shouldSuggestPreviousUser && (
              <FormGroup>
                <Button
                  className="p-0 ps-1 link"
                  color="link"
                  onClick={forgetPreviousUser}
                >
                  Not {previousUserName}?
                </Button>
              </FormGroup>
            )}
            {REACT_APP_IS_PASSWORD_LOGIN_ALLOWED === "true" && (
              <>
                <FormGroup>
                  <Label>E-mail</Label>
                  <Input
                    type="email"
                    id="email-login"
                    placeholder="Enter your e-mail address here"
                    value={email}
                    onChange={handleEmailChange}
                    disabled={isLoading}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <PasswordInput
                    value={password}
                    placeholder="Enter your password here"
                    handleChange={handlePasswordChange}
                    disabled={isLoading}
                  />
                </FormGroup>
                <div className="text-end">
                  <Button type="submit" className="btn btn-lg btn-primary" disabled={isLoading}>
                    {isLoading ? <><span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true" /> Logging In...</> : 'Log In'}
                  </Button>
                </div>
              </>
            )}
            {REACT_APP_IS_GOOGLE_LOGIN_ALLOWED === 'true' && (
              <>
                {(REACT_APP_IS_PASSWORD_LOGIN_ALLOWED === 'true') && (
                  <div className="separator">Or</div>
                )}
                <p className="mt-3">Sign in using google authentication.</p>
                <FormGroup>
                  <GoogleLogin
                    onSuccess={handleGoogleLogin}
                    onError={handleGoogleLogin}
                    cookiePolicy={"single_host_origin"}
                    useOneTap
                    shape="pill"
                  />
                </FormGroup>
              </>
            )}
            <FormGroup>
              {REACT_APP_IS_PASSWORD_LOGIN_ALLOWED === "true" && (
                <>
                  <Button
                    tag={Link}
                    to={`/password-reset`}
                    className="link px-0"
                    color="link"
                  >
                    Forgot your password?
                  </Button>
                  <br />
                </>
              )}
              <a
                href={REACT_APP_GOOGLE_FORM_XPH}
                target="_blank"
                rel="noreferrer"
                className="link px-0"
              >
                Report any problems to the following link
              </a>
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
