import { zigattaApi } from "./zigattaApi";

export const expensesApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getExpenseCategories: builder.query({
            query: () => 'expenseCategories',
            transformResponse: response => response.data,
            providesTags: ['ExpenseCategories', {type: 'ExpenseCategories', id: 'LIST'}],
        }),
        getExpenseCategoryById: builder.query({
            query: (categoryId) => `expenseCategories/${categoryId}`,
            transformResponse: response => response.data,
            providesTags: ['ExpenseCategories'],
        }),
        getExpenseTypes: builder.query({
            query: () => `expenses/types`,
            transformResponse: response => response.types,
        }),
        getpaymentMethods: builder.query({
            query: () => 'paymentMethods',
            transformResponse: response => response.paymentMethods
        }),
        getCurrencies: builder.query({
            query: () => 'currencies',
            transformResponse: response => response.currencies
        }),
        getExpenses: builder.query({
            query: ({ pageNumber, params }) => `expenses?page=${pageNumber}&${params}`,
            transformResponse: response => response.expenses
        }),
        uploadCSV: builder.mutation({
            query: (formData) => ({
                url: 'expenses/csv',
                method: 'POST',
                body: formData,
                headers: { 'Content-Type': 'application/json' }
                , transformResponse: response => response.expenses
            })
        }),
        getExpenseById: builder.query({
            query: (id) => `expenses/${id}`,
            transformResponse: response => response.expense
        }),
        deleteInvoice: builder.mutation({
            query: ({ expenseId, invoiceId }) => ({
                url: `expenses/${expenseId}/invoices/${invoiceId}`,
                method: 'DELETE'
            }),
            transformResponse: response => response.expense
        }),
        uploadInvoices: builder.mutation({
            query: ({ id, formData }) => ({
                url: `expenses/${id}/invoices`,
                method: 'POST',
                body: formData
            }),
            transformResponse: response => response.expense
        }),
        deleteExpense: builder.mutation({
            query: (id) => ({
                url: `expenses/${id}`,
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' }
            }),
        }),
        updateExpense: builder.mutation({
            query: ({ id, payload }) => ({
                url: `expenses/${id}`,
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' }
            })
        }),
        distributeExpense: builder.mutation({
            query: ({ id, payload }) => ({
                url: `expenses/${id}/distribute`,
                method: 'PUT',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json' }
            })
        }),
        createExpense: builder.mutation({
            query: (payload) => ({
                url: 'expenses',
                method: 'POST',
                body: JSON.stringify(payload),
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
            })
        }),
        createExpenseCategory: builder.mutation({
            query: (body = {}) => ({
                url: `expenseCategories`,
                method: 'POST',
                header: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['ExpenseCategories'],
        }),
        updateExpenseCategory: builder.mutation({
            query: ({ categoryId, body = {} }) => ({
                url: `expenseCategories/${categoryId}`,
                method: 'PUT',
                header: { 'Accept': 'application/json' },
                body,
            }),
            invalidatesTags: ['ExpenseCategories'],
        }),
        deleteExpenseCategory: builder.mutation({
            query: (categoryId) => ({
                url: `expenseCategories/${categoryId}`,
                method: 'DELETE',
                header: { 'Accept': 'application/json' },
            }),
            invalidatesTags: [{type: 'ExpenseCategories', id: 'LIST'}],
        }),
    }),
});

export const {
    useGetExpenseCategoriesQuery,
    useGetExpenseCategoryByIdQuery,
    useGetExpenseTypesQuery,
    useGetpaymentMethodsQuery,
    useGetCurrenciesQuery,
    useLazyGetExpensesQuery,
    useUploadCSVMutation,
    useLazyGetExpenseByIdQuery,
    useGetExpenseByIdQuery,
    useDeleteInvoiceMutation,
    useUploadInvoicesMutation,
    useDeleteExpenseMutation,
    useUpdateExpenseMutation,
    useDistributeExpenseMutation,
    useCreateExpenseMutation,
    useCreateExpenseCategoryMutation,
    useUpdateExpenseCategoryMutation,
    useDeleteExpenseCategoryMutation,
} = expensesApi;