import { zigattaApi } from "./zigattaApi";

export const notificationsApi = zigattaApi.injectEndpoints({
    endpoints: (builder) => ({
        getAvailableNotifications: builder.query({
            query: () => 'available-notifications'
        }),
    }),
});

export const { useGetAvailableNotificationsQuery } = notificationsApi;