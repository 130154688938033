import { logEvent, getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

/** FIREBASE CONFIGURATION STARTS HERE 
  @todo: Move this to prod clause before deployment to master
  @hint 
  you can uncomment these once we are ready to deploy to master

  const firebaseConfig = {
  apiKey:process.env.FIREBASE_API_KEY,
  authDomain: :process.env.FIREBASE_AUTHORIZATION_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket:process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId:process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId:process.env.FIREBASE_APP_ID,
  measurementId:process.env.FIREBASE_MEASUREMENT_ID,
};
 */

const firebaseConfig = {
  apiKey: "AIzaSyCiwDq6dcMu_janZQ2Fl7WQagZSMI9jQcg",
  authDomain: "xph-project.firebaseapp.com",
  projectId: "xph-project",
  storageBucket: "xph-project.appspot.com",
  messagingSenderId: "465694339124",
  appId: "1:465694339124:web:fef4521712b9fe6680da61",
  measurementId: "G-729F1D92YK",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

/*
  FIREBASE CONFIGURATION ENDS HERE
  */

const logEvents = (eventName) => {
  logEvent(analytics, eventName);
};

export { logEvents };
