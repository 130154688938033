import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Input,
    Label,
    Button,
    Badge,
    Alert,
} from 'reactstrap';
import { FaSearch, FaUserAlt, } from 'react-icons/fa';
import CustomAvatar from 'Components/CustomAvatar/CustomAvatar';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';

const UsersList = ({
    users = [],
    notFoundText = '',
    isLoading,
}) => {
    const [name, setName] = useState('');

    const filteredUsers = useMemo(() => {
        const lowerCaseFilterName = name?.toLocaleLowerCase();
        return users.filter(user => {
            const lowerCaseUserName = user?.name?.toLocaleLowerCase();
            if (!!lowerCaseFilterName && !lowerCaseUserName.includes(lowerCaseFilterName)) return false;
            return true;
        });
    }, [users, name]);

    const onNameChange = ({ target: { value } }) => setName(value);

    const resetFilters = () => setName('');

    return (
        <div className='container'>
            <div className="text-end py-2">
                <Badge color='info'><FaUserAlt className='me-1' /> Total: {users?.length}</Badge>
            </div>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader>
                            <h4><FaSearch className='me-1' />Filter</h4>
                        </CardHeader>
                        <CardBody>
                            <div className="grid-columns">
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type='text' value={name} onChange={onNameChange} />
                                </FormGroup>
                                <Button color='secondary' onClick={resetFilters}>Undo</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row className='py-3'>
                <LoadingIndicator isLoading={isLoading} />
                <Col md={12}>
                    <Alert className='m-0 pb-0' isOpen={!filteredUsers.length && !isLoading} color='info'>
                        <p className="m-o">
                            {notFoundText ?? 'No users found.'}
                        </p>
                    </Alert>
                </Col>
                {filteredUsers.map(user => (
                    <Col key={user?.id} className='pb-3' md={3}>
                        <CustomAvatar
                            cardClassName='avatar-card-inherit'
                            user={user}
                            tag={Link}
                            to={`/users/${user?.id}`}
                            showBadges
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default UsersList;