const encodeArrayToRequest = (param = '', array = []) => {
    let finalQuery = '';

    array.forEach((element, idx) => {
        let value = `${param}[${idx}]=${element}`;
        if (idx !== array.length - 1) {
            value += '&';
        }
        finalQuery += value;
    });

    return finalQuery;
};

export const digestObjectToSerialize = (obj = {}) => {
    // return empy object if obj has no keys 
    if(!Object.keys(obj).length) return obj;

    const serializedObj = Object.keys(obj).reduce((prev, key) => {
        const value = obj[key];
        const keyValue = { [key]: value, };
        switch (typeof value) {
            case 'string':
                prev = { ...prev, ...keyValue, };
                break;
            case 'object':
                if(Array.isArray(value)) {
                    value.forEach((el, idx) => {
                        prev = { ...prev, [`${key}[${idx}]`]: el, };
                    });
                }
                break;
            default:
                prev = { ...prev, ...keyValue, };
        }
        return prev;
    }, {});

    return serializedObj;
};

export default encodeArrayToRequest;