import React, { useState } from 'react';
import {
    Button,
} from 'reactstrap';
import Modal from 'Components/Modal/Modal';

const { REACT_APP_SHOW_LOGIN_MODAL } = process.env;
const isActive = REACT_APP_SHOW_LOGIN_MODAL === 'true';

const LoginModal = ({
    header = '',
    children,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(isActive);

    const toggle = () => setIsOpen(!isOpen);

    const modalFooter = (
        <Button color="primary" onClick={toggle}>
            Accept
        </Button>
    );

    return (
        <Modal
            {...rest}
            isOpen={isOpen}
            handleToggle={toggle}
            size='lg'
            header={header}
            footer={modalFooter}
            centered
        >
            {children}
        </Modal>
    );
};

export default LoginModal;