import React from 'react';
import {
    Card,
    CardHeader,
    CardTitle,
    CardBody,
} from 'reactstrap';
import classes from './VersionCard.module.css';

const VersionCard = ({
    version = {
        version: '',
        description: '',
        user: {
            name: '',
        },
    },
}) => {
    return (
        <Card>
            <CardHeader className='d-flex justify-content-between align-items-center py-3'>
                <CardTitle className='mb-0'>
                    <b className={classes['version-title']}>Version {version.version}</b>
                </CardTitle>
                <p className="m-0">
                    Author: {version.user.name}
                </p>
            </CardHeader>
            <CardBody className='pt-3 pb-0'>
                <div className={classes['version-body']} dangerouslySetInnerHTML={{ __html: version.description }} />
            </CardBody>
        </Card>
    );
}

export default VersionCard;