// this function checks if all notifications for a section are checked
export const isAllSectionNotificationsChecked = (sectionNotifications = [], selectedNotifications = []) => {
  return sectionNotifications.every(({id}) => selectedNotifications.includes(id));
}

// this functions checks if a notification is checked
export const isNotificationChecked = (notificationId, selectedNotifications = []) => {
  return selectedNotifications.includes(notificationId);
}

// this function adds or removes an entire section of notifications to the selectedNotifications array
export const addOrRemoveSectionNotifications = (notifications = [], selectedNotifications = [], isChecked) => {
  // If new check value is false then removes entire section, otherwise adds entire section
  if(!isChecked) {
    return selectedNotifications.reduce((result, current) => {
      const found = notifications.find(({id}) => id === current);
      if(!found) result.push(current);
      return result;
    }, []);
  }

  notifications.forEach(({id}) => {
    if(!selectedNotifications.includes(id)) selectedNotifications.push(id);
  });

  return selectedNotifications;
}

// this function adds or removes a notification to the selectedNotifications array
export const addOrRemoveNotification = (notificationId, selectedNotifications = [], isChecked) => {
  // If new check value is false then removes notification, otherwise adds notification
  if(!isChecked) {
    return selectedNotifications.filter(notification => notification !== notificationId);
  }

  return [...selectedNotifications, notificationId];
}


export default isAllSectionNotificationsChecked;