import React from 'react';
import { useOutletContext } from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
} from 'reactstrap';

const GeneralInformation = () => {
    const { legalEntity } = useOutletContext();

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <p>{legalEntity?.name ?? "-"}</p>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Tax Id</Label>
                                    <p>{legalEntity?.tax_id ?? "-"}</p>
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>Administrative Load</Label>
                                    <p>{legalEntity?.administrative_load ? `${parseInt(legalEntity?.administrative_load)}%` : "-"}</p>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default GeneralInformation;