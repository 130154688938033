import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import { status as rStatus } from 'constants/reimbursements';
import { canAcceptReimbursementRequests, canRejectReimbursementRequests } from 'constants/common';
import { useDownloadReimbursementFileMutation } from 'services/downloads';

const ReimbursementDetailCard = ({
    user,
    reimbursement,
    showCancelButton,
    showAcceptRejectButtons,
    handleCancelRequest,
    handleAcceptReject,
}) => {
    const [downloadReimbursementFile] = useDownloadReimbursementFileMutation();
    const lowerCaseStatus = reimbursement?.status?.toLowerCase();

    const handleDownloadClick = (e) => {
        const { dataset } = e.target;
        downloadReimbursementFile({ reimbursement, fileType: dataset.value });
    }

    return (
        <>
            <Row
                className='pb-3'
            >
                <Col
                    className='pb-3'
                    md={12}
                >
                    <Card>
                        <CardHeader>
                            Reimbursement request detail
                        </CardHeader>
                        <CardBody>
                            <Row className='pb-2'>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Requester</b></Label>
                                        <p className="m-o">{reimbursement.user?.name ?? '-'}</p>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Status</b></Label>
                                        <p className={`m-0 text-${rStatus[lowerCaseStatus]?.color ?? 'dark'}`}>
                                            <b>{reimbursement.status ?? '-'}</b>
                                        </p>
                                        {
                                            reimbursement.status_updated_by &&
                                            <p className="m-0">
                                                By {reimbursement.status_updated_by.name ?? ''}
                                            </p>
                                        }
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Submitted at</b></Label>
                                        <p className="m-0">
                                            {reimbursement.created_at ?? '-'}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Payment date</b></Label>
                                        <p className="m-0">
                                            {reimbursement.formatted_date ?? '-'}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Amount</b></Label>
                                        <p className="m-0">
                                            ${reimbursement.amount ?? '-'} {reimbursement.currency?.code ?? ''}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Category</b></Label>
                                        <p className="m-0">
                                            {reimbursement?.reimbursement_request_category?.name ?? '-'}
                                        </p>
                                    </FormGroup>
                                </Col>
                                {
                                    reimbursement.accepted_at &&
                                    <Col
                                        md={4}
                                    >
                                        <FormGroup>
                                            <Label><b>Accepted at</b></Label>
                                            <p className="m-0">
                                                {reimbursement.accepted_at}
                                            </p>
                                        </FormGroup>
                                    </Col>
                                }
                                {
                                    reimbursement.payed_at &&
                                    <Col
                                        md={4}
                                    >
                                        <FormGroup>
                                            <Label><b>Payed at</b></Label>
                                            <p className="m-0">
                                                {reimbursement.payed_at}
                                            </p>
                                        </FormGroup>
                                    </Col>
                                }
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Estimated payment date</b></Label>
                                        <p className="m-0">
                                            {reimbursement.estimated_payment_date ?? '-'}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>Comments</b></Label>
                                        <p className="m-0">
                                            {reimbursement.comments ?? '-'}
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col
                                    md={4}
                                >
                                    <FormGroup>
                                        <Label><b>File</b></Label>
                                        <p className="m-0">
                                            <Button
                                                className='me-2'
                                                color='primary'
                                                size='sm'
                                                data-value='file'
                                                onClick={handleDownloadClick}
                                            >
                                                PDF
                                            </Button>
                                            <Button
                                                className='me-2'
                                                color='primary'
                                                size='sm'
                                                data-value='xml'
                                                onClick={handleDownloadClick}
                                            >
                                                XML
                                            </Button>
                                            {reimbursement.image
                                                &&
                                                <Button
                                                    className='me-2'
                                                    color='primary'
                                                    size='sm'
                                                    data-value='image'
                                                    onClick={handleDownloadClick}
                                                >
                                                    IMG
                                                </Button>
                                            }
                                        </p>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    className='d-flex align-items-center justify-content-end'
                                    md={12}
                                >
                                    {user.id === reimbursement.user?.id
                                        && lowerCaseStatus === 'pending'
                                        && showCancelButton
                                        &&
                                        <Button
                                            color='secondary'
                                            onClick={handleCancelRequest}
                                        >
                                            Cancel
                                        </Button>
                                    }
                                    {lowerCaseStatus === 'pending'
                                        && showAcceptRejectButtons
                                        &&
                                        <>
                                            <Button
                                                className='me-2'
                                                color='success'
                                                data-type='accept'
                                                onClick={handleAcceptReject}
                                                disabled={!canAcceptReimbursementRequests}
                                            >
                                                Accept
                                            </Button>
                                            <Button
                                                color='danger'
                                                data-type='reject'
                                                onClick={handleAcceptReject}
                                                disabled={!canRejectReimbursementRequests}
                                            >
                                                Reject
                                            </Button>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

ReimbursementDetailCard.defaultProps = {
    user: {},
    reimbursement: {},
    showCancelButton: false,
    showAcceptRejectButtons: false,
};

ReimbursementDetailCard.propTypes = {
    user: PropTypes.object,
    reimbursement: PropTypes.object,
    showCancelButton: PropTypes.bool,
    showAcceptRejectButtons: PropTypes.bool,
    handleCancelRequest: PropTypes.func,
    handleAcceptReject: PropTypes.func,
};

export default ReimbursementDetailCard;