import React, { useReducer } from 'react';
import {
    Link,
    useParams,
    useNavigate,
} from 'react-router-dom';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Table,
    Button,
} from 'reactstrap';
import { statusColor } from 'pages/Jobdiva/helpers';
import PendingDetailRow from './PendingDetailRow';
import styles from './SyncDetail.module.css';
import CancelSyncModal from './modals/CancelSyncModal';
import ConfirmSyncModal from './modals/ConfirmSyncModal';
import EditUserModal from './modals/EditUserModal';
import EditAllocationModal from './modals/EditAllocationModal';
import { ACTIONS, syncPendingDetailReducer } from './jobdivaSyncsReducers';
import { useEffect } from 'react';
import { useLazyGetJobdivaSyncByIdQuery } from 'services/jobdiva';

const initialUserDataForModal = {
    name: '',
    email: '',
    hire_type: 'full-time',
    hired_at: '',
    role_id: '',
    department_id: '',
    week_hours: 40,
    seniority: 'trainee',
    reporting_manager_id: '',
    recruiter_id: '',
    employer_id: '',
    jobdiva_candidate_id: '',
    jobdiva_email: '',
    resume: '',
};

const initialAllocationDataForModal = {
    project_id: '',
    daily_hours: '8.0',
    jobdiva_end_date: '',
    jobdiva_start_date: '',
    role_id: '',
    rate_card: {
        jobdiva_bill_rate: '',
        jobdiva_pay_rate: '',
        project_id: '',
    },
};

const initialState = {
    sync: {},
    isConfirmModalOpen: false,
    isCancelModalOpen: false,
    isEditUserModalOpen: false,
    isEditAllocationModalOpen: false,
    userDataForModal: initialUserDataForModal,
    userIndexEditing: null,
    allocationIndexEditing: null,
    allocationDataForModal: initialAllocationDataForModal,
};

const SyncPendingDetail = () => {
    const [state, dispatchState] = useReducer(syncPendingDetailReducer, initialState);
    const { id } = useParams();
    const navigate = useNavigate();
    const [getSync, { isFetching }] = useLazyGetJobdivaSyncByIdQuery();

    // types for every user/allocation/rate card are:
    // new, update, existing, updated and created //

    useEffect(() => {
        const getData = async () => {
            const { data, error } = await getSync(id);
            if (error || !data) return;
            const details = data.details?.map(detail => {
                const obj = {
                    ...detail,
                    data: {
                        allocation: detail.data?.allocation?.map(allocation => ({ ...allocation, isChecked: false, })),
                    },
                };
                if (detail.type?.toLowerCase() === 'new') {
                    obj.hire_type = 'full-time';
                    obj.hired_at = '';
                    obj.role_id = '';
                    obj.department_id = '';
                    obj.week_hours = 40;
                    obj.seniority = 'trainee';
                    obj.reporting_manager_id = '';
                    obj.recruiter_id = '';
                    obj.employer_id = '';
                    obj.jobdiva_candidate_id = '';
                    obj.jobdiva_email = obj.email;
                    obj.resume = '';
                }
                return obj;
            });
            const sync = {
                ...data,
                details,
            };
            dispatchState({ type: ACTIONS.SET_SYNC, sync });
        }
        if (id) getData();
    }, [id]);

    // handlers

    const handleCheck = (e, userIndex, allocationIndex) => {
        const { checked } = e.target;
        dispatchState({
            type: ACTIONS.ONCHECKED_DETAIL,
            payload: {
                checked,
                userIndex,
                allocationIndex,
            },
        });
    }

    // confirm

    const toggleConfirmModal = () => dispatchState({ type: ACTIONS.TOGGLE_CONFIRMSYNCMODAL });

    const handleSyncConfirmed = () => {
        toggleConfirmModal();
        navigate('/jobdiva/syncs');
    }

    // cancel

    const toggleCancelModal = () => dispatchState({ type: ACTIONS.TOGGLE_CANCELSYNCMODAL, });

    const handleSyncCancelled = () => {
        toggleCancelModal();
        navigate('/jobdiva/syncs');
    }

    // edit user

    const toggleEditUserModal = () => dispatchState({ type: ACTIONS.TOGGLE_EDITUSERMODAL, });

    const handleEditUserClick = (userIndexEditing) => dispatchState({ type: ACTIONS.ONCLICK_EDITUSER, userIndexEditing });

    const handleEditUserChange = (name, value) => dispatchState({ type: ACTIONS.UPDATE_USERDATAMODAL, name, value });

    const onClosedEditUserModal = () => dispatchState({ type: ACTIONS.ONCLOSED_EDITUSERMODAL, userDataForModal: initialUserDataForModal, });

    // allocation

    const toggleEditAllocationModal = () => dispatchState({ type: ACTIONS.TOGGLE_EDITALLOCATIONMODAL, });

    const handleAllocationEditClick = (userIndexEditing, allocationIndexEditing) => dispatchState({ type: ACTIONS.ONCLICK_EDITALLOCATION, userIndexEditing, allocationIndexEditing, });

    const onClosedEditAllocationModal = () => dispatchState({ type: ACTIONS.ONCLOSED_EDITALLOCATIONMODAL, allocationDataForModal: initialAllocationDataForModal, });

    const handleAllocationChange = (name, value) => dispatchState({ type: ACTIONS.UPDATE_ALLOCATIONDATA, name, value });

    const handleRateCardChange = (name, value) => dispatchState({ type: ACTIONS.UPDATE_ALLOCATIONRATECARDDATA, name, value });

    return (
        <div>
            <div className="header">
                <div
                    className="container-fluid d-flex align-items-center justify-content-between"
                >
                    <h1>JobDiva Syncs</h1>
                    <Button
                        color='secondary'
                        size='sm'
                        tag={Link}
                        to='/jobdiva/syncs'
                    >
                        Back to list
                    </Button>
                </div>
            </div>
            <div className="container-fluid pt-3 pb-3">
                <Row>
                    <Col
                        md={12}
                    >
                        <Card>
                            <CardHeader>
                                JobDiva sync #{id} - <span className={`${statusColor(state.sync.status)} font-weight-bold`}>{state.sync.status}</span>
                            </CardHeader>
                            <CardBody>
                                <Table className={styles.table} striped>
                                    <thead className={styles.stickythead}>
                                        <tr>
                                            <th>#</th>
                                            <th>Type</th>
                                            <th>Concept</th>
                                            <th>Company</th>
                                            <th>Dates</th>
                                            <th>Pay Rate</th>
                                            <th>Bill Rate</th>
                                            <th></th>
                                            <th>Confirm</th>
                                        </tr>
                                    </thead>
                                    {
                                        state.sync.details?.map((detail, index) =>
                                            <PendingDetailRow
                                                key={`detail-${detail.id}`}
                                                index={index}
                                                detail={detail}
                                                colSpan={7}
                                                syncStatus={state.sync.status}
                                                handleCheck={handleCheck}
                                                handleEditUserClick={handleEditUserClick}
                                                handleAllocationEditClick={handleAllocationEditClick}
                                            />
                                        )
                                    }
                                </Table>
                                <Row
                                    className='pt-3'
                                >
                                    <Col
                                        className='d-flex align-items-center justify-content-end'
                                        md={12}
                                    >
                                        <Button
                                            className='me-2'
                                            color='danger'
                                            size='sm'
                                            onClick={toggleCancelModal}
                                            disabled={!state.sync.details?.length || state.sync.status !== 'pending'}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color='success'
                                            size='sm'
                                            onClick={toggleConfirmModal}
                                            disabled={!state.sync.details?.length || state.sync.status !== 'pending'}
                                        >
                                            Confirm
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            <CancelSyncModal
                isOpen={state.isCancelModalOpen}
                toggle={toggleCancelModal}
                handleSyncCancelled={handleSyncCancelled}
            />
            <ConfirmSyncModal
                syncProp={state.sync}
                isOpen={state.isConfirmModalOpen}
                toggle={toggleConfirmModal}
                handleSuccess={handleSyncConfirmed}
            />
            <EditUserModal
                data={state.userDataForModal}
                isOpen={state.isEditUserModalOpen}
                onClosed={onClosedEditUserModal}
                toggle={toggleEditUserModal}
                handleChange={handleEditUserChange}
            />
            <EditAllocationModal
                data={state.allocationDataForModal}
                isOpen={state.isEditAllocationModalOpen}
                onClosed={onClosedEditAllocationModal}
                toggle={toggleEditAllocationModal}
                handleAllocationChange={handleAllocationChange}
                handleRateCardChange={handleRateCardChange}
            />
        </div>
    );

}

export default SyncPendingDetail;