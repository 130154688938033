import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, } from 'react-router-dom';
import {
    Row,
    Col,
    FormGroup,
    Label,
    Card,
    CardHeader,
    CardBody,
    Button,
    Badge,
} from 'reactstrap';
import PageHeader from 'Components/PageHeader/PageHeader';
import LoadingIndicator from 'Widgets/LoadingIndicator/LoadingIndicator';
import DeleteReimbursementCategoryModal from './DeleteReimbursementCategoryModal';
import { useGetReimbursementCategoryByIdQuery } from 'services/reimbursements';
import { canDeleteReimbursementCategories, canUpdateReimbursementCategories } from 'constants/common';

const ShowReimbursementCategory = () => {
    const [category, setCategory] = useState();
    const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const { data: dataCategory, isFetching } = useGetReimbursementCategoryByIdQuery(id);

    useEffect(() => {
        if (!dataCategory) return;
        setCategory(dataCategory);
    }, [dataCategory]);

    const toggleDeleteCategoryModal = () => setIsDeleteCategoryModalOpen(!isDeleteCategoryModalOpen);

    const onDeleteSuccess = () => {
        toggleDeleteCategoryModal();
        navigate('/reimbursement-categories');
    }

    return (
        <>
            <PageHeader
                title={`Reimbursement Category: ${dataCategory?.name ?? '-'}`}
                className='pb-3'
            >
                <Button
                    color='secondary'
                    size='sm'
                    tag={Link}
                    to='/reimbursement-categories'
                >
                    Return to list
                </Button>
            </PageHeader>
            <div className="container-fluid pb-3">
                <Card>
                    <CardHeader>Reimbursement request category detail</CardHeader>
                    <CardBody>
                        <LoadingIndicator isLoading={isFetching} />
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Expense category</Label>
                                    <p className="m-0">{category?.expense_category?.name ?? '-'}</p>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <p className="m-0">{category?.name ?? '-'}</p>
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label>Status</Label>
                                    <p className="m-0">
                                        <Badge
                                            color={!!category?.display ? 'success' : 'danger'}
                                            pill
                                        >
                                            {!!category?.display ? 'Active' : 'Inactive'}
                                        </Badge>
                                    </p>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='d-flex justify-content-end' md={12}>
                                <Button
                                    className='me-2'
                                    color='danger'
                                    size='sm'
                                    onClick={toggleDeleteCategoryModal}
                                    disabled={!canDeleteReimbursementCategories}
                                >
                                    Delete
                                </Button>
                                <Button
                                    color='primary'
                                    size='sm'
                                    tag={Link}
                                    to={`/reimbursement-categories/${id}/edit`}
                                    disabled={!canUpdateReimbursementCategories}
                                >
                                    Edit
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
            <DeleteReimbursementCategoryModal
                isOpen={isDeleteCategoryModalOpen}
                toggle={toggleDeleteCategoryModal}
                categoryId={id}
                onDeleteSuccess={onDeleteSuccess}
            />
        </>
    );
}

export default ShowReimbursementCategory;