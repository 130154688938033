import { Component } from "react";
import { useSelector } from "react-redux";
import { Media } from "reactstrap";
import { selectUser } from "store/slices/authSlice";
import { selectDarkMode } from "store/slices/userSlice";
import { logEvents } from "constants/firebase";
import './error-handling.css';

logEvents("error_handlder");
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };
  }

  static getDerivedStatefromError(error, errorInfo) {
    return {
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div id='error-handler-container'>
          <div className="box">
            <div className='image-container'>
              <Media className='m-0' src={`../../500error-${this?.props?.darkModeActive ? 'dark' : 'light'}.svg`} fluid />
            </div>
            <div className="text-container">
              <h1 className='mb-0'>Oops! Internal Server Error</h1>
              <p className='m-0'>
                An error occurred while executing the action. The zetta team will be notified and will work to address the problem as soon as possible, come back soon!
              </p>
            </div>
            {this?.props?.user?.roles?.some((role) => {
              return role.name === "super-admin";
            }) && (
                <details className="details-wrapper">
                  <summary>Error Details</summary>
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
              )}
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const Wrapper = (props) => {
  const user = useSelector(selectUser);
  const darkModeActive = useSelector(selectDarkMode);
  return <ErrorBoundary {...props} user={user} darkModeActive={darkModeActive} />
}; 

export default Wrapper;
